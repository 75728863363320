// Libs
import React, { BaseSyntheticEvent } from 'react';
import classNames from 'classnames';
import NumberFormat from 'react-number-format';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import _ from 'lodash';

// Components
import { Table, Input, Button, Select, DatePicker, Tooltip, Modal, Empty, TreeSelect, InputNumber } from 'antd';
import Dropdown, { Action as DropdownAction } from 'components/dropdown';

// Icons
import { QuestionCircleOutlined, PlusOutlined } from '@ant-design/icons';

// Services
import { getUserSetting } from 'services/settings';

// Interfaces
import { ITemplate, ITemplateColumn } from 'components/template-modal/TemplateModal.interface';
import { RecordFormEntity } from 'types/entities';

// Utils
import { isBlank } from 'utils/utils';
import { findFirst } from 'utils/utils';
const FormulaParser = require('hot-formula-parser').Parser;

const parser = new FormulaParser();
const { Option } = Select;

export enum Alignment {
  Center = 'center',
  Left = 'left',
  Right = 'right'
};

interface Coa {
  id: number;
  title: string;
  description: string | null;
  key: string;
  reference: string;
  compatible_template_ids: number[];
  children: Coa[];
};

interface FinancialYear {
  start_date: string;
  end_date: string;
};

interface Currency {
  id: number;
  title: string;
  code: string;
  symbol: string;
};

interface Props {
  record?: RecordFormEntity,
  coa?: Coa,
  template: ITemplate,
  contractPricingId?: number | null,
  financialYear?: FinancialYear | null,
  defaultCurrency?: Currency | null,
  height?: number,
  templates: ITemplate[],
  values: any[];
  errors: any;
  numberFormat: any;
  setValues(values: any): void;
  setErrors(errors: any): void;
};

interface State {
  activeTemplateId: number | null;
  deleteConfirmId: string | null;
  tableHeight: number;
  shouldUpdateRows: any[];
};

export const calculateFormula = (template: ITemplate, financialYear: FinancialYear | null | undefined, values: any, formula: string): any => {
  const references = formula.match(/\[(.*?)\]/g);

  if (references) {
    references.forEach((_reference: string) => {
      const reference = _reference.substring(1, _reference.length - 1);
      const templateColumn = template.columns.find((templateColumn: ITemplateColumn) => templateColumn.reference === reference);

      if (!!templateColumn) {
          let value: any = parseFloat(values[reference]) || 0;

        // Convert to excel date (2020,1,1)
        if (templateColumn.type === 'date') {
          if (!!values[reference]) {
            value = moment(values[reference], 'YYYY-MM-DD').format('YYYY,MM,DD');
          } else {
            value = '1960,1,1';
          }
        }

        formula = formula.replaceAll(_reference, value);
      }

      // Replace custom date strings
      if (financialYear && (reference === '*start_date*' || reference === '*end_date*')) {
        if (reference === '*start_date*') {
          formula = formula.replaceAll(_reference, moment(financialYear?.start_date, 'YYYY-MM-DD').format('YYYY,MM,DD'));
        } else {
          formula = formula.replaceAll(_reference, moment(financialYear?.end_date, 'YYYY-MM-DD').format('YYYY,MM,DD'));
        }
      }
    });
  }

  const { error, result } = parser.parse(formula);

  if (!error) {
    return result;
  }

  return 0;
};

class TemplateModal extends React.Component<Props, State> {

  component: any = React.createRef();

  state: State = {
    activeTemplateId: !_.isEmpty(this.props.templates) ? this.props.templates[0].id : null,
    deleteConfirmId: null,
    tableHeight: this.props.height || 0,
    shouldUpdateRows: [],
  };

  componentDidMount = () => {
    const { templates, values } = this.props;
    const { activeTemplateId } = this.state;

    if (!!activeTemplateId) {
      const template = templates.find((template: ITemplate) => template.id === activeTemplateId);
      if (!!template) {
        if (_.isEmpty(values)) {
          this.handleChange([], this.getBlankState(template));
        } else {
          // Recalculate all rows
          this.props.setValues(this.recalculateValues(values));
        }
      }
    }

    if (this.component && !this.props.height) {
      this.heightObserver();
    }
  };

  componentDidUpdate = (prevProps: Props) => {
    // Hack to figure out which rows to rerender
    if (!_.isEqual(this.props.values, prevProps.values)) {
      let rowsToUpdate: string[] | number[] = [];
      prevProps.values.forEach((row: any) => {
        const stateRow = this.props.values.find((value: any) => (!!value.id && value.id === row.id) || value.key === row.key);
        if (stateRow && !_.isEqual(row, stateRow)) {
          rowsToUpdate = _.union(rowsToUpdate, [(_.has(stateRow, 'id') && !!stateRow.id) ? stateRow.id : stateRow.key]);
        }
      });
      this.setState({
        shouldUpdateRows: _.union(this.state.shouldUpdateRows, rowsToUpdate)
      });
    }
  };

  heightObserver = () => {
    const height: number = document.getElementById('TemplateTable')?.offsetHeight || 0;

    if (this.state.tableHeight !== height) {
      this.setState({
        tableHeight: height - 50
      });
    }
  };

  validate = () => {
    this.props.setErrors(this.getErrors(this.props.values));
  };

  recalculateValues = (values: any[]) => {
    return values.map((row: any) => this.recalculate(row));
  };

  handleChange = (values: any[], row: any) => {

    let newValues = _.cloneDeep(values);

    row = this.recalculate(row);

    const rowIndex = newValues.findIndex((value: any) => (!!value.id && value.id === row.id) || (!!value.origin_id && value.origin_id === row.origin_id) || value.key === row.key);
    const isNew = rowIndex === -1;

    if (isNew) {
      newValues = newValues.concat([row]);
    } else {
      newValues = _.set(newValues, rowIndex, row);
    }

    this.props.setValues(newValues);
    this.props.setErrors(this.getErrors(newValues));
  };

  getErrors = (values: any[]) => {

    const errors: any = [];

    values.forEach((value: any) => {
      const template = this.props.templates.find((template: any) => template.id === value.template_id);
      const schema = template && template.columns.reduce((acc: any, column: any) => {
        if (column.config.required) {
          acc[column.reference] = (value: any) => {
            return !isBlank(value) && value !== '' && value !== null;
          };
        }
        return acc;
      }, {});

      const validate = (value: any, schema: any) => Object
        .keys(schema)
        .filter(key => !schema[key](value[key]))
        .map(key => key);

      if (!_.isEmpty(validate(value.values, schema))) {
        errors[value.key] = validate(value.values, schema);
      }
    });

    return errors;
  };

  hasError = (errors: any[], rowKey: any, columnKey: string) => {
    return !!errors[rowKey] && errors[rowKey].includes(columnKey);
  };

  modifyRow = (key: any, row: any, newValue: any) => {
    return {
      ..._.set(row, `values.${key}`, newValue)
    };
  };

  recalculate = (row: any) => {
    const template = this.props.templates.find((template: ITemplate) => template.id === row.template_id);

    return Object.keys(row.values).reduce((obj: any, key: any) => {
      const templateColumn: ITemplateColumn | undefined = template && template.columns.find((templateColumn: any) => templateColumn.reference === key);

      // Couldn't find column
      if (!templateColumn) return obj;

      // Set exchange rate
      if (templateColumn.reference === 'exchange_rate') {
        if (_.has(row, 'values.local_currency') && row.values.local_currency) {
          obj['values'][key] = templateColumn.config.rates[row.values.local_currency];
        }
      }

      // Calculate formula
      if (template && !!templateColumn.formula) {
        obj['values'][key] = calculateFormula(template, this.props.financialYear, row.values, templateColumn.formula);
      }

      return obj;
    }, row);
  };

  calculateTotals = (templates: ITemplate[], rows: any) => {
    return rows
      .reduce((_acc: any, row: any) => {

        const template = templates.find((template: ITemplate) => template.id === row.template_id);
        const totalColumn = template && template.columns.find((column: ITemplateColumn) => column.context === 'TOTAL');

        if (totalColumn && _.has(row, `values.${totalColumn.reference}`)) {
          return parseFloat(_acc) + parseFloat(row.values[totalColumn.reference]);
        }

        return parseFloat(_acc);
      }, 0);
  };

  calculateTemplateColumnTotals = (column: ITemplateColumn, rows: any) => {
    return rows.reduce((acc: any, row: any) => {
      const value: string = _.has(row, `values.${column.reference}`) && !!row.values[column.reference] ? row.values[column.reference] : 0;
      return parseFloat(acc) + parseFloat(value);
    }, 0);
  };

  calculateTemplateTotals = (template: ITemplate, rows: any) => {
    const totalColumn = template.columns.find((column: ITemplateColumn) => column.context === 'TOTAL');

    if (!totalColumn) return 0;

    return rows
      .filter((row: any) => row.template_id === template.id)
      .reduce((_acc: any, row: any) => {
        if (_.has(row, `values.${totalColumn.reference}`)) {
          return parseFloat(_acc) + parseFloat(row.values[totalColumn.reference]);
        }
        return _acc;
      }, 0);
  };

  getBlankState = (template: ITemplate) => {
    const state: any = {};

    template.columns.forEach((column: ITemplateColumn) => {

      // Defaults
      state[column.reference] = null;

      // Merge in coa_id
      if (column.type === 'coa' && this.props.coa) {
        state[column.reference] = this.props.coa.id;
      }

      // Merge in Contract Pricing Id
      if (column.type === 'contract_pricing' && this.props.contractPricingId) {
        state[column.reference] = this.props.contractPricingId;
      }

      // Set default currency
      if (column.type === 'currency' && this.props.defaultCurrency) {
        state[column.reference] = this.props.defaultCurrency.code;
      }
    });

    return {
      key: uuidv4(),
      id: null,
      template_id: template.id,
      values: state
    };
  };

  isDisabled = (row: any) => {
    return _.has(row, 'values.coa_id') && this.props.coa && row.values.coa_id !== this.props.coa.id;
  };

  renderDeleteDialog = (deleteConfirmId: string) => {
    const { values } = this.props;
    return (
      <Modal
        visible
        centered
        title={ 'Remove Row' }
        okButtonProps={{
          danger: true
        }}
        onOk={ () => {
          this.props.setValues(values.filter((value: any) => {
            if (_.has(value, 'key')) {
              return value.key !== deleteConfirmId;
            } else {
              return value.id !== deleteConfirmId;
            }
          }));
          this.validate();
          this.setState({ deleteConfirmId: null });
        } }
        onCancel={ () => this.setState({ deleteConfirmId: null }) }
      >
        <p>Are you sure you want to delete this row?</p>
      </Modal>
    );
  };

  renderTableSummaryCell = (index: number, column: ITemplateColumn, values: any) => {
    return (
      <Table.Summary.Cell key={ index } index={ index } colSpan={ 1 }>
        { column.type === 'number' && column.config.mode === 'decimal' &&
          <div className='ta-r fw-600'>
            <NumberFormat
              { ...this.props.numberFormat }
              fixedDecimalScale
              decimalScale={ 2 }
              value={ this.calculateTemplateColumnTotals(column, values) }
              displayType={ 'text' }
            />
          </div>
        }
      </Table.Summary.Cell>
    );
  };

  renderTableSummary = (template: ITemplate, values: any) => {
    return (
      <Table.Summary.Row>
        <>
          { template.columns.map((column: ITemplateColumn, index: number) => this.renderTableSummaryCell(index, column, values)) }
          <Table.Summary.Cell key={ 'actions' } index={ 9999 } colSpan={ 1 } />
        </>
      </Table.Summary.Row>
    );
  };

  renderTemplateField = (values: any[], column: ITemplateColumn, row: any, errors: any) => {
    const hasErrors = this.hasError(errors, row.key, column.reference);
    const isDisabled = this.isDisabled(row);

    if (!!column.formula) {

      let value = _.has(row, `values.${column.reference}`) && !!row.values[column.reference] ? row.values[column.reference] : 0;

      return (
        <div className='ta-r'>
          <NumberFormat
            { ...this.props.numberFormat }
            fixedDecimalScale={ column.config.mode === 'decimal' ? true : false }
            decimalScale={ 2 }
            value={ value }
            suffix={ _.has(column, 'config.mode') && column.config.mode === 'percentage' ? '%' : undefined }
            displayType={ 'text' }
          />
        </div>
      );
    }

    switch (column.type) {
      case 'contract_pricing':

        const suppliers: any[] = _.has(column, 'config.suppliers') ? column.config.suppliers : [];
        const contracts: any[] = _.has(column, 'config.contracts') ? column.config.contracts : [];
        const pricingRecords: any[] = _.has(column, 'config.pricing_records') ? column.config.pricing_records : [];

        let contract_pricing_id: number | null = _.has(row, `values.contract_pricing_id`) ? row.values.contract_pricing_id : null;
        let supplier_id: number | null = _.has(row, 'supplier_id') ? row.supplier_id : null;
        let contract_id: number | null = _.has(row, 'contract_id') ? row.contract_id : null;

        if (!!this.props.contractPricingId) {
          contract_pricing_id = this.props.contractPricingId;
        }

        if (contract_pricing_id) {
          const pricingRecord = pricingRecords.find((pricingRecord: any) => pricingRecord.id === contract_pricing_id);

          if (pricingRecord) {
            contract_id = pricingRecord.contract_id;
            const contract = contracts.find((contract: any) => contract.id === contract_id);

            if (contract) {
              supplier_id = contract.supplier_id;
            }
          }
        }

        return (
          <div className='d-f w-100p'>
            <Select
              className={ classNames('Select-Field d-if', {
                'Select-Field--has-error border-danger': hasErrors,
              }) }
              disabled={ isDisabled || !!this.props.contractPricingId }
              dropdownMatchSelectWidth={ false }
              style={{ width: '33%'}}
              placeholder={ 'Supplier' }
              value={ supplier_id }
              onChange={ (supplierId: number) => {
                row = _.set(row, 'supplier_id', supplierId);
                row = _.set(row, 'contract_id', null);
                row = this.modifyRow('contract_pricing_id', row, null);
                this.handleChange(values, row);
              } }
            >
              { suppliers.map((option: any) => (
                <Option key={ option.id } value={ option.id }>
                  { option.title }
                </Option>
              )) }
            </Select>
            <Select
              className={ classNames('Select-Field d-if pL-10', {
                'Select-Field--has-error border-danger': hasErrors,
              }) }
              disabled={ isDisabled || !!this.props.contractPricingId || !supplier_id }
              dropdownMatchSelectWidth={ false }
              style={{ width: '33%'}}
              placeholder={ 'Contract' }
              value={ contract_id }
              onSelect={ (contractId: number) => {
                row = _.set(row, 'contract_id', contractId);
                row = this.modifyRow('contract_pricing_id', row, null);
                this.handleChange(values, row);
              } }
            >
              { contracts
                  .filter((contract: any) => contract.supplier_id === supplier_id)
                  .map((contract: any) => (
                    <Option key={ contract.id } value={ contract.id }>
                      { contract.title }
                    </Option>
                  ))
              }
            </Select>
            <Select
              className={ classNames('Select-Field d-if pL-10', {
                'Select-Field--has-error border-danger': hasErrors,
              }) }
              notFoundContent={ <Empty image={ Empty.PRESENTED_IMAGE_SIMPLE } description={ <><div>{ 'The chosen contract is' }</div><div>{ 'not connected to any properties.' }</div></> } /> }
              dropdownMatchSelectWidth={ false }
              style={{ width: '33%'}}
              disabled={ isDisabled || !!this.props.contractPricingId || !contract_id }
              placeholder={ 'Property' }
              value={ contract_pricing_id }
              showSearch
              filterOption={(input: any, option: any) =>
                (option!.children as unknown as string).toLowerCase().includes(input.toLowerCase())
              }
              onSelect={ (pricingRecordId: number) => {
                this.handleChange(values, this.modifyRow('contract_pricing_id', row, pricingRecordId));
              } }
            >
              { pricingRecords
                  .filter((pricingRecord: any) => pricingRecord.contract_id === contract_id)
                  .map((pricingRecord: any) => (
                    <Option key={ pricingRecord.id } value={ pricingRecord.id }>
                      { pricingRecord.title }
                    </Option>
                  ))
              }
            </Select>
          </div>
        );
      case 'coa':
        const appendUniqueKey = (items: any) => {
          return items.map((item: any) => {
            return {
              'key': item.id,
              'value': item.id,
              ...item,
              'children': !_.isEmpty(item.children) ? appendUniqueKey(item.children) : null,
            };
          });
        };

        return (
          <TreeSelect
            style={{ width: '100%' }}
            className={ classNames('Select-Field', {
              'Select-Field--has-error border-danger': hasErrors,
            }) }
            disabled={ isDisabled || !!this.props.coa }
            showSearch
            allowClear={ !column.config.required }
            dropdownMatchSelectWidth={ false }
            onClear={ () => this.handleChange(values, this.modifyRow(column.reference, row, null)) }
            placeholder={ column.label }
            multiple={ false }
            treeData={ appendUniqueKey(_.has(column.config, 'options') ? column.config.options : []) }
            defaultValue={ _.has(row, `values.${column.reference}`) ? row.values[column.reference] : undefined }
            onChange={(id: number) => this.handleChange(values, this.modifyRow(column.reference, row, id)) }
            filterTreeNode={ (input: string, option: any) => {
              if (option) {
                const filteredInput = input.toLocaleLowerCase();
                const title = option.title && option.title.toLowerCase();

                if (title.includes(filteredInput)) {
                  return true;
                }
              }
              return false;
            } }
          />
        );
      case 'commercial_model':

        let options: any[] = [];

        if (_.has(column, 'config.options')) {
          options = column.config.options;

          if (_.has(row, 'values.coa_id') && !!row.values.coa_id && _.has(column, `config.coa_map.${row.values.coa_id}`)) {
            options = column.config.options.filter((option: any) => column.config.coa_map[row.values.coa_id].includes(option.id) );
          }
        }

        return (
          <Select
            style={{ width: '100%' }}
            className={ classNames('Select-Field', {
              'Select-Field--has-error border-danger': hasErrors,
            }) }
            disabled={ isDisabled }
            placeholder={ column.label }
            onClear={ () => this.handleChange(values, this.modifyRow(column.reference, row, null)) }
            onSelect={ (id: number) => this.handleChange(values, this.modifyRow(column.reference, row, id)) }
            defaultValue={ _.has(row, `values.${column.reference}`) ? row.values[column.reference] : undefined }
          >
            { options.map((option: any) => (
              <Option key={ option.id } value={ option.id }>
                { option.title }
              </Option>
            )) }
          </Select>
        );
      case 'currency':
        return (
          <Select
            style={{ width: '100%' }}
            className={ classNames('Select-Field', {
              'Select-Field--has-error border-danger': hasErrors,
            }) }
            allowClear={ !column.config.required }
            dropdownMatchSelectWidth={ false }
            disabled={ isDisabled || !!column.config.locked }
            placeholder={ column.label }
            onClear={ () => this.handleChange(values, this.modifyRow(column.reference, row, null)) }
            onSelect={ (id: number) => this.handleChange(values, this.modifyRow(column.reference, row, id)) }
            defaultValue={ _.has(row, `values.${column.reference}`) ? row.values[column.reference] : undefined }
          >
            { _.has(column.config, 'options') && !_.isEmpty(column.config.options) && column.config.options.map((option: any) => (
              <Option key={ option.id } value={ option.id }>
                { option.title }
              </Option>
            )) }
          </Select>
        );
      case 'date':
        return (
          <DatePicker
            allowClear={ false }
            className={ classNames('Field', {
              'Field--has-error border-danger': hasErrors
            }) }
            disabled={ isDisabled }
            format={ getUserSetting('date_format') }
            defaultValue={ _.has(row, `values.${column.reference}`) && row.values[column.reference] !== null ? moment(row.values[column.reference], 'YYYY-MM-DD') : undefined }
            onChange={ (date: moment.Moment | null) => this.handleChange(values, this.modifyRow(column.reference, row, date ? date.format('YYYY-MM-DD') : null)) }
          />
        );
      case 'currency_exchange':
        return (
          <div className='ta-r'>
              <span>{ _.has(row, `values.${column.reference}`) && row.values[column.reference] !== null ? row.values[column.reference] : '-' }</span>
          </div>
        );
      case 'text':
        return (
          <Input
            autoComplete="off"
            className={ classNames('Field', {
              'Field--has-error border-danger': hasErrors,
            }) }
            disabled={ isDisabled || !!column.config.locked }
            placeholder={ column.label }
            defaultValue={ _.has(row, `values.${column.reference}`) ? row.values[column.reference] : undefined }
            onBlur={ (event: BaseSyntheticEvent) => this.handleChange(values, this.modifyRow(column.reference, row, event.target.value)) }
          />
        );
      case 'number':
        if (column.config.mode === 'decimal') {
          return (
            <NumberFormat
              { ...this.props.numberFormat }
              className={ classNames('ta-r', {
                'Field--has-error border-danger': hasErrors,
              }) }
              fixedDecimalScale
              decimalScale={ 2 }
              customInput={ Input }
              placeholder={ column.label }
              required={ _.has(column, 'config.required') ? column.config.required : false }
              disabled={ (_.has(column, 'config.locked') && !!column.config.locked) || isDisabled ? true : false }
              defaultValue={ _.has(row, `values.${column.reference}`) ? row.values[column.reference] : undefined }
              onBlur={ (event: BaseSyntheticEvent) => {
                if (event.target.value === '') {
                  this.handleChange(values, this.modifyRow(column.reference, row, null));
                } else {
                  this.handleChange(values, this.modifyRow(column.reference, row, parseFloat(event.target.value.replaceAll(',', ''))));
                }
              } }
            />
          );
        }

        if (column.config.mode === 'percentage') {
          return (
            <InputNumber
              min={ 0 }
              max={ 100 }
              className={classNames('Field', {
                'Field--has-error border-danger': hasErrors,
              })}
              required={ _.has(column, 'config.required') ? column.config.required : false }
              disabled={ (_.has(column, 'config.locked') && !!column.config.locked) || isDisabled ? true : false }
              formatter={ (value: any) => `${value}%`}
              parser={ (value: any) => value!.replace('%', '') }
              defaultValue={ _.has(row, `values.${column.reference}`) && !!row.values[column.reference] ? row.values[column.reference] : 0 }
              onBlur={ (event: BaseSyntheticEvent) => {
                const value = event.target.value.replaceAll('%', '');
                if (value === '') {
                  this.handleChange(values, this.modifyRow(column.reference, row, null));
                } else {
                  this.handleChange(values, this.modifyRow(column.reference, row, Number(value > 100 ? 100 : value)));
                }
              } }
            />
          );
        }

        return (
          <InputNumber
            autoComplete="newpassword" // hack
            className={classNames('Field', {
              'Field--has-error border-danger': hasErrors,
            })}
            required={ _.has(column, 'config.required') ? column.config.required : false }
            disabled={ (_.has(column, 'config.locked') && !!column.config.locked) || isDisabled ? true : false }
            onBlur={ (event: BaseSyntheticEvent) => {
              if (event.target.value === '') {
                this.handleChange(values, this.modifyRow(column.reference, row, null));
              } else {
                this.handleChange(values, this.modifyRow(column.reference, row, Number(event.target.value)));
              }
            } }
            min={ 0 }
            placeholder={ column.label }
            defaultValue={ _.has(row, `values.${column.reference}`) ? row.values[column.reference] : undefined }
          />
        );
      case 'select':
        return (
          <Select
            style={{ width: '100%' }}
            className={ classNames('Select-Field', {
              'Select-Field--has-error border-danger': hasErrors,
            }) }
            allowClear={ !column.config.required }
            dropdownMatchSelectWidth={ false }
            disabled={ isDisabled || !!column.config.locked }
            placeholder={ column.label }
            value={ _.has(row, `values.${column.reference}`) ? row.values[column.reference] : undefined }
            onClear={ () => this.handleChange(values, this.modifyRow(column.reference, row, null)) }
            onSelect={ (id: number) => this.handleChange(values, this.modifyRow(column.reference, row, id)) }
          >
            { _.has(column.config, 'options') && !_.isEmpty(column.config.options) && column.config.options.map((option: any) => (
              <Option key={ option.id } value={ option.id }>
                { option.title }
              </Option>
            )) }
          </Select>
        );
      default:
        return <></>;
    }
  };

  render = () => {
    const { template, values, errors } = this.props;
    const { tableHeight } = this.state;

    const data = values
      .filter((entry: any) => entry.template_id === template.id)
      .map((entry: any) => {
        return {
          key: entry.key || entry.id || uuidv4(),
          ...entry,
        };
      }).sort((a: any, b: any) => {
        const coaOptions = template.columns.find((column: ITemplateColumn) => column.type === 'coa')?.config.options;

        if (coaOptions) {
          const coaA = coaOptions && findFirst({ children: coaOptions }, 'children', { id: a.values.coa_id });
          const coaB = coaOptions && findFirst({ children: coaOptions }, 'children', { id: b.values.coa_id });

          if (coaA && coaB) {
            const coaATitle = coaA.title.toUpperCase();
            const coaBTitle = coaB.title.toUpperCase();

            if (coaATitle < coaBTitle) {
              return -1;
            }

            if (coaATitle > coaBTitle) {
              return 1;
            }
          }
        }

        return 0;
      });

    const columns: any = template.columns.map((column: ITemplateColumn) => {
      return {
        key: column.reference,
        dataIndex: column.reference,
        shouldCellUpdate: (record: any, prevRecord: any) => {

          // Determine if we should force update the row
          if (this.state.shouldUpdateRows.includes(record.key)) {
            this.setState({
              shouldUpdateRows: this.state.shouldUpdateRows.filter((rowKey: string | number) => rowKey !== record.key)
            });
            return true;
          }

          return !_.isEqual(record, prevRecord);
        },
        title: (
          <div
            className={ classNames({
              'ta-r': column.type === 'number'
            }) }
          >
            <span>{ column.label }</span>
            <span className="text-required mL-2 fsz-md">{ column.config.required ? '*' : '' }</span>
            { _.has(column, 'description') && !!column.description &&
              <Tooltip
                className="mL-5"
                placement="top"
                title={ column.description }
              >
                <QuestionCircleOutlined className="fsz-def text-ant-default" />
              </Tooltip>
            }
          </div>
        ),
        width: _.has(column, 'config.width') ? column.config.width : 200,
        render: (__: any, row: any) => {
          return this.renderTemplateField(values, column, row, errors);
        }
      };
    });

    columns.push(
      {
        key: 'actions',
        fixed: 'right',
        title: (
          <Button
            disabled={ _.has(template, 'config.can_create_rows') && !template.config.can_create_rows }
            style={{
              padding: '4px 7px',
              width: '32px',
            }}
            onClick={ () => this.handleChange(values, this.getBlankState(template)) }
          >
            <PlusOutlined />
          </Button>
        ),
        dataIndex: '',
        align: Alignment.Center,
        width: 100,
        render: (row: any) => {

          const isDisabled = this.isDisabled(row);
          const actions: DropdownAction[] = [
            {
              node: '',
              onClick: () => {}
            }
          ];

          actions.push(
            {
              disabled: isDisabled,
              node: 'Delete',
              group: 'Actions',
              isDangerous: true,
              onClick: () => {
                this.setState({
                  deleteConfirmId: row?.id || row?.key
                });
              }
            }
          );

          return <Dropdown actions={ actions } />;
        },
      }
    );

    return (
      <div id="TemplateTable" className="h-100p">
        <Table
          size='small'
          sticky
          columns={ columns }
          dataSource={ _.cloneDeep(data) }
          pagination={ false }
          bordered
          scroll={{
            x: columns.length * 150,
            y: tableHeight || 500,
          }}
          summary={ () => this.renderTableSummary(template, data) }
        />
        { !!this.state.deleteConfirmId && this.renderDeleteDialog(this.state.deleteConfirmId) }
      </div>
    );
  };
};

export default TemplateModal;
