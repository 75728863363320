export interface IPreview {
  id: number;
  module_id: string;
  title: string;
  tool_id: number | null;
  columns: IColumn[];
  filters: IFilter[];
  sorters: ISorter[];
  data: IData[];
  limit: null;
  can_edit?: boolean;
  can_copy?: boolean;
  chart: IChart | null;
  chart_data: any;
  generated_at: string;
};

export interface IInsight {
  title: string | null;
  tool_id: number | null;
  module_id: string | null;
  module_joins: IModuleJoin[];
  columns: IColumn[];
  filters: IFilter[];
  sorters: ISorter[];
  roles: IRole[];
  limit: number | null;
  data?: any[];
  chart: IChart | null;
};

export interface IChart {
  type: 'NONE' | 'COLUMN' | 'BAR' | 'PIE';
  config: {
    x_field: string;
    x_label: string;
    y_field: string;
    y_label: string;
    group_field: string;
    group_mode?: string; // STACK, GROUP, PERCENT
    key_field?: string;
    value_field?: string;
    color_field?: string;
    show_legend?: boolean;
  };
};

export interface IData {
  [key: string]: any;
};

export enum ColumnType {
  Formula = 'FORMULA',
  Column = 'COLUMN'
};

export interface IColumn {
  id: string;
  module_id: string;
  module_title: string;
  column: string;
  column_type: ColumnType;
  title: string;
  sample: string;
  type: string;
  config: {
    length: number;
    decimal?: number;
    width?: number;
    mode?: string;
    is_downloadable?: boolean;
    filename_column_id?: string;
    file_id_column_id?: string;
    apply_number_format?: boolean;
  };
  settings: ISettings;
  description: string;
};

export interface IFormula {
  label: string | undefined;
  tooltip: string | undefined;
  width: number | undefined;
  formula: string | undefined;
};

export interface IFilter {
  id: string;
  module_id: string;
  module_title: string;
  column: string;
  title: string;
  type: string;
  config: {
    length: number;
    multiple: boolean;
    related_columns: string[]
  };
  settings: ISettings;
  description: string;
  values: any[];
  options?: IFilterOption[];
};

export interface IFilterOption {
  id: string;
  title: string;
  group?: string;
};

export interface ISorter {
  id: string;
  module_id: string;
  module_title: string;
  column: string;
  title: string;
  type: string;
  config: {
    length: number;
    related_columns: string[];
  };
  settings: ISettings;
  description: string;
  direction: string;
};

export interface IRole {
  id: string;
  module_id: string;
  column: string;
  title: string;
  type: string;
  config: {
    length: number;
  };
  settings: ISettings;
  description: string;
};

export interface ITool {
  id: string;
  title: string;
};

export interface IDefault {
  [key: string]: any;
}

export interface ISettings {
  width?: number | null;
  custom_title?: string | null;
  tooltip?: string | null;
  direction?: string | null;
  hidden?: boolean | null;
  show_total?: boolean | null;
  wrap_text?: boolean | null;
  group_by?: boolean | null;
  required?: boolean;
  date_mode?: string;
  range?: string;
  default_values?: IDefault[] | null;
};

export interface IModule {
  key?: string | number;
  id: string;
  title: string;
  description: string;
  joins: IModuleJoin[];
  tool_id: number;
};

export interface IModuleJoin {
  key?: string;
  id: string;
  source_column: string;
  source_module_id: string;
  source_module_title: string;
  target_column: string;
  target_module_id: string;
  target_module_title: string;
  children?: IModuleJoin[];
};

export type OnSaveCallback = (insightId?: IPreview['id'] | undefined) => void;
