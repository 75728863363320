// Libs
import React, { BaseSyntheticEvent } from 'react';
import _ from 'lodash';

// Components
import { Form, Input, Modal } from 'antd';

// Interfaces
import { KpiMetricRow } from 'components/kpi-library/KpiLibrary.interfaces';

interface Props {
  metric: KpiMetricRow;
  onClose: () => void;
  onCopy: (metricSetId: number, metricId: number, title: string, callback: () => void) => void;
};

interface State {
  isCopying: boolean;
  title: string;
};

class MetricCopyModal extends React.Component<Props, State> {
  mounted: boolean = false;

  state: State = {
    isCopying: false,
    title: `${this.props.metric.title} - Copy`
  };

  componentDidMount = () => {
    this.mounted = true;
  };

  componentWillUnmount = () => {
    this.mounted = false;
  };

  handleCopy = (title: string) => {
    this.props.onCopy(this.props.metric.metric_set_id, this.props.metric.id, title, () => {
      this.mounted && this.setState({
        isCopying: false
      });
    });
  };

  render = () => {
    const { isCopying, title } = this.state;
    return (
      <Modal
        visible
        centered
        maskClosable={ !isCopying }
        closable={ !isCopying }
        title={ 'Copy Metric' }
        okText={ 'Copy' }
        onOk={ () => this.setState({
            isCopying: true
          }, () => {
            this.handleCopy(title);
          }
        ) }
        onCancel={ () => this.props.onClose() }
        okButtonProps={ {
          loading: isCopying,
          disabled: _.isEmpty(title)
        } }
        cancelButtonProps={ {
          disabled: isCopying
        } }
      >
        <p>This will create a new copy of the metric.</p>
        <div className="mT-20">
          <Form layout="horizontal">
            <Form.Item
              label="Title"
              required
            >
              <Input
                autoComplete="off"
                value={ title }
                onChange={ (event: BaseSyntheticEvent) => {
                  this.setState({
                    title: event.target.value
                  });
                } }
              />
            </Form.Item>
          </Form>
        </div>
      </Modal>
    );
  };
};

export default MetricCopyModal;
