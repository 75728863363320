import { PhoneCountry } from 'components/form/field/phone';
import { Types as DateFieldTypes } from 'components/form/field/date-time';
import { Option } from 'components/form/field/list';

export interface ErrorTabState {
  groups: ErrorGroupState[];
  id: number;
  title: string;
};

export interface ErrorGroupState {
  elements: Array<ErrorElementState | null>;
  id: number;
  title: string;
};

export interface ErrorElementState {
  field?: Array<ErrorFieldState | undefined> | null;
  id: number;
};

export interface ErrorFieldState {
  [key: string]: FormFieldError;
};

export enum RuleType {
  MaxVal = 'maxVal',
  MinVal = 'minVal',
  Regex = 'regex',
  Required = 'required',
};

export interface FormRecord {
  id: number;
  title: string;
  config: {
    open: boolean;
    pre_render?: boolean;
  };
  protected: number;
  groups: FormGroup[];
};

export interface FormTabIndexed {
  index: number;
};

export interface FormGroup {
  config: {
    open: boolean;
  };
  elements: FormElement[];
  id: number;
  protected: number;
  title: string;
};

export interface FormElement {
  id: number;
  protected: number;
  type: string;
  config: {
    field?: string;
    column_span?: number;
    dependencies_mode?: string;
    orientation?: "left" | "right" | "center" | undefined;
    text?: string | undefined;
    description?: string | undefined;
    view_type?: string | undefined;
  };
  data: FormField;
  dependencies?: {
    field: string;
    value: {
      option_id?: number;
    };
  };
};

export interface Currency {
  id: number;
  code: string;
  title: string;
  symbol?: string;
};

export interface Category {
  id: number;
  parent_id: number;
  title: string;
};

export interface ExpenditureCategory {
  id: number;
  parent_id: number;
  title: string;
  type: string;
};

export interface AreaUnit {
  unit: string;
  title: string;
  conversion: number | string;
  symbol?: string;
};

export interface FormValues {
  [key: string]: string | number;
};

export interface FormCategoryValues {
  target_bundle: string;
  target_id: number;
  target_type: string;
  target_path?: string;
  target_title?: string;
}

export enum ListType {
  Checkbox = 'checkbox',
  Radio = 'radio',
  Select = 'select',
  Multi_select = 'multi-select',
};

export enum CategoryFormat {
  Picker = 'picker',
};

export enum FormItemType {
  address = 'address',
  area = 'area',
  slider = 'slider',
  area_unit = 'area_unit',
  service_scope = 'service_scope',
  currency = 'currency',
  currency_unit = 'currency_unit',
  finance_template = 'finance_template',
  datetime = 'datetime',
  dynamic = 'dynamic',
  email = 'email',
  geolocation = 'geolocation',
  group = 'group',
  link = 'link',
  number = 'number',
  property_relationship = 'property_relationship',
  opex_cost_summary = 'opex_cost_summary',
  capex_cost_summary = 'capex_cost_summary',
  capex_price_summary = 'capex_price_summary',
  opex_price_summary = 'opex_price_summary',
  phone = 'phone',
  relationship = 'relationship',
  role_table = 'role_table',
  select = 'select',
  kpi_priority = 'kpi_priority',
  space_relationship = 'space_relationship',
  specification_relationship = 'specification_relationship',
  text = 'text',
  textarea = 'textarea',
  time = 'time',
  category = 'category',
  category_parent = 'category_parent',
  contact = 'contact',
  resource_setup = 'resource_setup',
  country = 'country',
  editor = 'editor',
  space_definition = 'space_definition',
  calculated = 'calculated',
  file = 'file',
  dynamic_field_template = 'dynamic_field_template',
  clause = 'clause',
  activity_management = 'activity_management',
  activity_template = 'activity_template',
  role = 'role',
  maintenance_schedule = 'maintenance_schedule',
  asset_type_field = 'asset_type',
  cost = 'cost',
  cost_template = 'cost_template',
  dynamic_field_component_relationship = 'dynamic_field_component_relationship',
  color = 'color',
  key_date = 'key_date',
  frequency = 'frequency',
  timeline = 'timeline',
  coa = 'coa',
  resource = 'resource',
  finance_template_modal = 'finance_template_modal',
};

export interface FieldColumn {
  label: string;
  required: boolean;
  decimal?: number;
  precision?: number;
  min?: number;
  max?: number;
};

export interface FieldConfig {
  cardinality: number;
  filterable: boolean;
  format?: ListType | DateFieldTypes | CategoryFormat;
  hide_in_form: boolean;
  clear_value_on_hide: boolean;
  hide_in_list: boolean;
  locked: boolean;
  required: boolean;
  list?: string;
  mode?: string;
  target?: FieldConfigTarget[];
  enable_ftes?: boolean;
  measurement?: string;
  allowed_extensions: string[];
  can_create: boolean;
  can_reject?: boolean;
  can_approve?: boolean;
  can_delete?: boolean;
  suffix?: string;
  show_preview?: boolean;
  refresh_on_change?: boolean;
  force_refresh_options?: boolean;
  decimal?: number;
  showOwnership: boolean;
  version_changed: boolean;
  can_inline_create?: boolean;
  display_by_group?: boolean;
  max_file_size: number;
};

export interface FieldConfigTarget {
  bundle: string;
  type: string;
  label: string;
  can_create: boolean;
};

export interface FieldRule {
  type: RuleType;
  value: string | number;
  message: string;
};

export interface FormFieldConfig {
  tabID: number;
  tabIndex: number;
  groupID: number;
  groupIndex: number;
  elementIndex: number;
  fieldIndex?: number;
  fieldColSpan?: number;
};

export interface FormField {
  id: string;
  type: FormItemType;
  label: string;
  rules: Record<string, FieldRule[]>;
  values: FormValues[];
  countries?: PhoneCountry[];
  regions?: any[];
  roles?: any[];
  contacts?: any[];
  columns: Record<string, FieldColumn>;
  currencies?: Currency[];
  currency?: Currency;
  categories?: Category[];
  clauses?: any[];
  required_clauses?: any[];
  capex?: ExpenditureCategory[];
  opex?: ExpenditureCategory[];
  targets?: FormFieldTargets[];
  key_dates?: FormFieldKeyDate[];
  config: FieldConfig;
  options?: Option[];
  units?: AreaUnit[];
  description?: string;
  min?: number;
  max?: number;
  step?: any;
  dependencies?: any;
  dependencies_mode?: string;
  compare: null | {
    message: string | null;
    new_value: string | null;
    label_current: string | null;
    label_new: string | null;
    helptext: string | null
  };
  template?: any;
  templates: any[];
  resources?: any[];
  static_options?: any[];
  extensions?: string[];
  dynamic_template_sets?: any[];
  frequency_options?: any[];
  preview_source: {
    template_reference: string;
    column_reference: string;
  } | null;
  default_opex_coa_id?: number | null;
};

export interface FormFieldTargets {
  label: string;
  type: string;
};

export interface FormFieldKeyDate {
  bundle: string;
  id: number;
  required: boolean;
  title: string;
  type: string;
};

export interface FormFieldError {
  label: string;
  error: string[];
};

export interface ApiErrorValue {
  [key: string]: string[];
};

export interface ApiError {
  element: number;
  group: number;
  tab: number;
  values: Record<string, ApiErrorValue>;
};

export interface FormErrorResponse {
  message: string;
  errors: Record<string, ApiError>;
};

export interface FormRoles {
  id: number;
  created_at: string;
  description: string;
  reference: string;
  title: string;
  updated_at: string;
};

export interface FormFieldRoles {
  available: FormRoles[];
  id: number;
  label: string;
  type: FormItemType;
  values: {
    company_roles: any[];
    user_roles: any[];
  };
};

export interface FormPermissions {
  create: boolean;
  create_comment: boolean;
  delete: boolean;
  delete_all_comment: boolean;
  delete_own_comment: boolean;
  edit: boolean;
  edit_all_comment: boolean;
  edit_own_comment: boolean;
  manage_resource: boolean;
  view: boolean;
  view_audit: boolean;
};

export interface FormFieldInfoBoxErrorMessageContent {
  label: string;
  content: string[] | FormFieldInfoBoxErrorMessageContentChild[];
};

export interface FormFieldInfoBoxErrorMessageContentChild {
  label?: string;
  columnKey?: string;
  content: string[];
};

export interface FormFieldInfoBoxModifiedMessageContent {
  columnKey?: string;
  label?: string;
  from?: string | number;
  to?: string | number;
  content?: FormFieldInfoBoxModifiedMessageContent[];
};

export interface FormFieldInfoBoxMessage {
  id: string | number;
  cardinality: number;
  group: number;
  tab: number;
  order: number;
};

export interface FormFieldInfoBoxErrorMessage extends FormFieldInfoBoxMessage {
  content: FormFieldInfoBoxErrorMessageContent;
  errors: any;
  isHidden?: boolean;
};

export interface FormFieldInfoBoxModifiedMessage extends FormFieldInfoBoxMessage {
  content: FormFieldInfoBoxModifiedMessageContent;
  modified: any;
};

export interface FieldModifiedMessage {
  order: number;
  column: string;
  columnKey: string;
  change: {
    from: string | number;
    to: string | number;
  };
};

export interface FieldErrorMessage {
  order: number;
  column: string;
  columnKey: string;
  errors: string[];
  isHidden?: boolean;
};

export interface APIFieldErrorMessage {
  cardinality: number;
  errors: Record<string, string[]>;
  group: number;
  id: string;
  tab: number;
};

export type OnClone = (
  recordId: number,
  options: string[],
  callback: () => void,
  title?: string,
) => void;
export type OnLoading = (
  callback: () => void
) => void;
export type RenderCloneModal = (isCopying: boolean, onLoading?: OnLoading, onClone?: OnClone, onClose?: () => void) => void;
export const DEPENDENCY_MODE_AND = 'and';
export const DEPENDENCY_MODE_OR = 'or';
