// Libs
import * as React from 'react';
import { defineMessages, injectIntl, IntlShape } from 'react-intl';
import { connect } from 'react-redux';
import moment from 'moment';
import _ from 'lodash';

// Components
import BlockingSpinner from 'components/blocking-spinner';
import Jumbotron from 'components/jumbotron';
import { hasPermission } from 'components/restriction';
import CreateRecordView from 'views/common/CreateRecordView';
import Dropdown, { Action as DropdownAction } from 'components/dropdown';
import AdvancedList from "components/advanced-list";
import CalendarView from 'components/calendar-view';

// Services
import Notification from 'services/notification';
import { Api } from 'services/api';

// Actions
import { setBreadcrumbs } from 'store/UI/ActionCreators';

// Interfaces
import { UserEntity } from 'types/entities';
import AppState from 'store/AppState.interface';
import { Breadcrumb } from 'store/UI/State.interface';
import { UserPermissions } from 'types/permissions';

// Styles
import 'assets/styles/_layout.scss';

const API: Api = new Api();

const CALENDAR_VIEW_TAB_KEY = 'Calendar View';

const messages = defineMessages({
  error: {
    id: 'general.error',
    defaultMessage: 'Error',
    description: '',
  },
  create: {
    id: 'general.create',
    defaultMessage: 'Create',
    description: '',
  },
  title: {
    id: 'locations.column_title',
    defaultMessage: 'Title',
    description: '',
  },
  created: {
    id: 'locations.column_created',
    defaultMessage: 'Created',
    description: '',
  },
  list_view: {
    id: 'locations.list_view',
    defaultMessage: 'List View',
    description: '',
  },
  map_view: {
    id: 'locations.map_view',
    defaultMessage: 'Map View',
    description: '',
  },
});

interface Props {
  client_id: number;
  user: UserEntity;
  permissions: UserPermissions;
  intl: IntlShape;
  setBreadcrumbs(breadcrumbs: Breadcrumb[], concat: boolean): void;
}

interface State {
  record: any;
  isFetching: boolean;
  isCreateLoading: boolean;
  showCreateModal: {
    id?: number;
    bundle: string;
    type: string;
    parent_id?: number;
    parent_type?: string;
    parent_bundle?: string;
  } | null;
  activeTabKey: string;
};

class Actions extends React.Component<Props, State> {

  mounted: boolean = false;

  state: State = {
    record: null,
    isFetching: false,
    isCreateLoading: false,
    showCreateModal: null,
    activeTabKey: this.props.intl?.formatMessage(messages.list_view)
  };

  componentDidMount = async () => {
    const { intl: { formatMessage }, user, setBreadcrumbs } = this.props;

    this.mounted = true;
    setBreadcrumbs([
      { title: 'Home', path: '/' },
      { title: 'EH&S', path: '/ehs' },
      { title: 'Actions', path: null },
    ], false);

    try {
      await new Promise((resolve) => this.setState({ isFetching: true }, () => resolve(null)));

      const record = await API.get(`client/${user.active_client}/action/advanced-list`);

      this.mounted && this.setState({
        record: record,
      });

    } catch (error) {
      console.error(`${formatMessage(messages.error)}:`, error);
    } finally {
      this.mounted && this.setState({
        isFetching: false,
      });
    }
  };

  componentWillUnmount = () => {
    this.props.setBreadcrumbs([], false);
    this.mounted = false;
  };

  handleTabChange = (tabKey: string) => {
    this.mounted && this.setState({ activeTabKey: tabKey });
  };

  filterReport = async (filters: any, currency: string | null, measurement: string | null, callback: (successful: boolean) => void) => {
    const { user } = this.props;
    try {
      const record = await API.get(`client/${user.active_client}/action/advanced-list`, {
        filters: filters,
        currency: currency,
        measurement: measurement,
      });
      this.setState({
        record: record
      }, () => {
        callback(true);
      });
    } catch (error) {
      callback(false);
      Notification('error', 'Filter Failed');
    }
  };

  exportFile = async (filters: any, currency: string | null, measurement: string | null, callback: (successful: boolean) => void) => {
    const { user } = this.props;
    const { record } = this.state;
    try {
      await API.download(`client/${user.active_client}/action/advanced-list/export`, `pacs_report_${_.snakeCase(record.title)}_${moment().format('YYYY-MM-DD')}.xlsx`, {
        filters: filters,
        currency: currency,
        measurement: measurement,
      });
      callback(true);
    } catch (error) {
      callback(false);
      Notification('error', 'Export Failed');
    }
  };

  renderListView = (record: any) => {
    return (
      <AdvancedList
        clientId={ this.props.client_id }
        filters={ record?.filters || [] }
        columns={ record?.columns || [] }
        items={ record?.data || [] }
        config={ record?.config }
        onExport={ (filters: any, currency: string | null, measurement: string | null, callback: (successful: boolean) => void) => this.exportFile(filters, currency, measurement, callback) }
        onFilter={ (filters: any, currency: string | null, measurement: string | null, callback: (successful: boolean) => void) => this.filterReport(filters, currency, measurement, callback) }
      />
    );
  };

  renderCalendarView = () => {
    const { user } = this.props;

    return (
      <CalendarView
        client_id={ user.active_client }
        customEndpoint={ 'action' }
      />
    );
  };

  render = () => {
    const { intl: { formatMessage }, permissions } = this.props;
    const { showCreateModal, isFetching, isCreateLoading, record, activeTabKey } = this.state;
    const actions: DropdownAction[] = [];
    const tabs = [
      {
        label: formatMessage(messages.list_view),
        node: this.renderListView(record)
      },
      {
        label: CALENDAR_VIEW_TAB_KEY,
        // The condition is necessary for the Calendar View tab to reload on every click.
        node: activeTabKey === CALENDAR_VIEW_TAB_KEY ? this.renderCalendarView() : null,
      }
    ];

    actions.push({
      node: '',
      onClick: () => {}
    });

    if (hasPermission(permissions, 'action_audit_action_create')) {
      actions.push({
        node: 'Create Audit Action',
        onClick: () => this.mounted && this.setState({
          isCreateLoading: true,
          showCreateModal: {
            bundle: 'action',
            type: 'audit-action',
          },
        }),
        isLoading: isCreateLoading
      });
    }

    return (
      <BlockingSpinner isLoading={ isFetching }>
        <Jumbotron
          title={ 'Actions' }
          tabs={ tabs }
          rightActions={ !_.isEmpty(actions) ? [
            {
              node: (
                <Dropdown actions={ actions } />
              )
            }
          ] : [] }
          onChange={ (tab: string) => this.handleTabChange(tab) }
        />
        { !!showCreateModal &&
          <CreateRecordView
            type={ showCreateModal?.type.replaceAll('_', '-') }
            entity={ showCreateModal?.bundle.replaceAll('_', '-') }
            parent_id={ !!showCreateModal?.parent_id ? showCreateModal?.parent_id : undefined }
            parent_type={ !!showCreateModal?.parent_type ? showCreateModal?.parent_type : undefined }
            parent_bundle={ !!showCreateModal?.parent_bundle ? showCreateModal?.parent_bundle : undefined }
            onReady={ () => this.mounted && this.setState({ isCreateLoading: false }) }
            onClose={ () => this.mounted && this.setState({ showCreateModal: null }) }
          />
        }
      </BlockingSpinner>
    );
  };
}

// Make data available on props
const mapStateToProps = (store: AppState) => {
  return {
    permissions: store.UserState.user.permissions,
    user: store.UserState.user,
  };
};

// Make functions available on props
const mapDispatchToProps = (dispatch: any) => {
  return {
    setBreadcrumbs: (value: Breadcrumb[], concat: boolean) => dispatch(setBreadcrumbs(value, concat)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(Actions));
