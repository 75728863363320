// Libs
import React from 'react';
import classNames from 'classnames';
import moment from 'moment';
import _ from 'lodash';

// Components
import { Tooltip, Button, Empty } from 'antd';
import { TabView } from 'components/tab-view';
import BlockingSpinner from 'components/blocking-spinner/BlockingSpinner';
import Chart from 'components/insight/Chart';
import ChartTable from 'components/insight/ChartTable';
import InsightFilter from 'components/insight/InsightFilter';

// Services
import { Api } from 'services/api';
import Notification from 'services/notification';

// Interfaces
import { IPreview, IFilter } from 'components/insight/Insight.interfaces';

// Icons
import { QuestionCircleOutlined } from '@ant-design/icons';

// Styles
import './Insight.scss';

interface Props {
  clientId: number;
  insight: IPreview | null;
  isLoading: boolean;
  isPreview: boolean;
};

interface State {
  insight: IPreview | null;
  filters: { [key: string]: any };
  currentPage: number;
  itemsPerPage: number;
  isLoading: boolean;
  wrapperHeight: number;
};

const API: Api = new Api();

const getFilterValues = (filters: IFilter[] | undefined): any => {
  let filterValues: any = {};

  if (!!filters) {
    filters.forEach((_filter: IFilter) => {
      filterValues[_filter.id] = _filter.values;
    });
  }
  return filterValues;
};

class Insight extends React.Component<Props, State> {

  mounted: boolean = false;

  state: State = {
    insight: this.props.insight,
    filters: getFilterValues(this.props.insight?.filters),
    currentPage: 1,
    itemsPerPage: 50,
    isLoading: false,
    wrapperHeight: 500,
  };

  componentDidMount = () => {
    this.mounted = true;
    this.heightObserver();
  };

  componentDidUpdate = (prevProps: Props) => {
    if (!_.isEqual(prevProps.insight, this.props.insight) && !_.isEqual(this.props.insight, this.state.insight)) {
      this.setState({
        filters: getFilterValues(this.props.insight?.filters),
        insight: this.props.insight
      }, () => {
        this.heightObserver();
      });
    }

    if (!_.isEqual(prevProps.isLoading, this.props.isLoading) && !_.isEmpty(this.state.filters)) {
      this.setState({
        itemsPerPage: 50,
      }, () => {
        this.heightObserver();
      });
    }
  };

  componentWillUnmount = () => {
    this.mounted = false;
  };

  heightObserver = () => {
    const jumbotronHeight: number = document.getElementById('Jumbotron')?.offsetHeight || 100;
    const filterHeight: number = document.getElementById('Insight-filters')?.offsetHeight || 100;
    const wrapperHeight: number = document.getElementById('Insight-wrapper')?.offsetHeight || 500;
    const height: number = (jumbotronHeight + filterHeight + wrapperHeight) - 175;

    if (this.state.wrapperHeight !== height) {
      this.setState({
        wrapperHeight: height
      });
    }
  };

  export = async (callback: (successful: boolean) => void) => {
    try {
      await API.download(`client/${this.props.clientId}/insight/report/${this.state.insight?.id}/export`, `pacs_insight_${_.snakeCase(this.state.insight?.title)}_${moment().format('YYYY-MM-DD')}.xlsx`, {
        filters: this.state.filters
      });
      callback(true);
    } catch (error: any) {
      callback(false);

      let errorMessage = '';
      if (_.has(error, 'error.response.data')) {
        errorMessage = await error?.error.response.data.text();
      }
      Notification('error', errorMessage, 'Export Failed');
    }
  };

  filter = async (insight: IPreview, filters: { [key: string]: any }) => {
    try {
      await new Promise((resolve) => this.setState({ isLoading: true }, () => resolve(null)));

      let newInsight = null;

      if (!this.props.isPreview) {
        newInsight = await API.post(`client/${this.props.clientId}/insight/report/${insight.id}`, {
          data: _.set(insight, 'data', []),
          filters: filters
        });
      } else {
        newInsight = await API.post(`client/${this.props.clientId}/insight/builder/report/preview`, {
          data: _.set(insight, 'data', []),
          filters: filters
        });
      }

      this.mounted && this.setState({
        insight: newInsight
      });
    } catch (error) {
      Notification('error', 'Failed to generate insight', 'Failed');
    } finally {
      this.mounted && this.setState({ isLoading: false });
    }
  };

  renderFilters = (filters: IFilter[]) => {
    return filters.filter(filter => !filter.settings?.hidden).map((filter: IFilter, index: number) => {
      return (
        <div key={ index }
          className={ classNames('mB-10 mR-10', {
            'd-f ai-c': filter.type === 'exclude_empty'
          }) }
        >
          { filter.type !== 'exclude_empty' &&
            <div className="d-f ai-c text-ant-grey">
              <span>
                { filter?.settings?.custom_title || filter.title }
              </span>
              { !!filter?.settings?.tooltip &&
                <Tooltip
                  className="mL-5"
                  placement="top"
                  title={ filter?.settings?.tooltip }
                >
                  <QuestionCircleOutlined className="cur-p fsz-def" />
                </Tooltip>
              }
              { !!filter?.settings?.required &&
                <span className="text-required mL-2 lh-1 fsz-md va-t">*</span>
              }
              { filter?.type === 'datetime' &&
                <span className="mL-5">
                  {`(${ _.startCase(_.toLower(filter.settings.date_mode)).replaceAll('_', ' ') })`}
                </span>
              }
            </div>
          }
          <div className="d-f">
            <InsightFilter
              filters={ this.state.filters }
              filter={ filter }
              onSetFilters={ (filters: any) => this.setState({
                filters: filters
              }) }
            />
          </div>
        </div>
      );
    });
  };

  render = () => {
    const { clientId, isPreview, isLoading } = this.props;
    const { insight, filters } = this.state;
    const missingRequiredFilter = insight?.filters
      .filter((filter: IFilter) => filter.settings.required)
      .some((filter: IFilter) => {
        return !_.has(filters, [filter.id]) || _.isEmpty(filters[filter.id]);
      });

    if (!insight) {
      return <Empty image={ Empty.PRESENTED_IMAGE_SIMPLE } description={ 'No data' } />;
    }

    const tabs = [
      {
        label: 'Table',
        node: <ChartTable insight={ _.omit(insight, 'filters') } isLoading={ isLoading } clientId={ clientId } />,
      }
    ];

    if (!_.isEmpty(insight?.chart)) {
      tabs.push(
        {
          label: 'Chart',
          node: (
            <div className="d-f jc-c ai-c bg-white bdB p-10" style={ _.isEmpty(insight?.data) ? { minHeight: 300 } : { height: this.state.wrapperHeight - 150 } }>
              { isLoading ? (
                <div className="d-f jc-c ai-c"><BlockingSpinner isLoading /></div>
              ) : (
                _.isEmpty(insight?.data) ?
                (
                  <div className='d-f jc-c ai-c'>
                    <Empty image={ Empty.PRESENTED_IMAGE_SIMPLE } description={ 'No data' } />
                  </div>
                ) : (
                  <Chart insight={ insight } height={ this.state.wrapperHeight - 200 } />
                )
              ) }
            </div>
          ),
        }
      );
    }

    return (
      <div>
        <div>
          { !_.isEmpty(insight?.filters.filter(filter => !filter.settings?.hidden)) &&
            <div
              className={ classNames('d-f mT-10 jc-sb ai-c', {
                'mX-30 mT-20': !isPreview
              }) }
            >
              <div id="Insight-filters" className="d-f fxw-w ai-fe">
                { this.renderFilters(insight.filters) }
              </div>
              <div className="d-f">
                { missingRequiredFilter ? (
                  <Tooltip
                    placement="top"
                    title={ 'Missing required filter' }
                  >
                    <Button
                      type='primary'
                      disabled
                    >
                      { 'Apply Filters' }
                    </Button>
                  </Tooltip>
                ) : (
                  <Button
                    type='primary'
                    loading={ this.state.isLoading }
                    disabled={ this.props.isLoading || this.state.isLoading }
                    onClick={ () => this.filter(insight, filters) }
                  >
                    { 'Apply Filters' }
                  </Button>
                ) }
              </div>
            </div>
          }
        </div>
        <div id="Insight-wrapper" className="mT-10">
          <TabView
            transparent
            tabs={ tabs }
            barStyle={ isPreview ? { padding: 0, marginBottom: 20 } : {} }
            paneStyle={ isPreview ? { padding: 0 } : {} }
          />
        </div>
      </div>
    );
  };
};

export default Insight;
