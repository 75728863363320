// Libs
import React, { useState } from 'react';
import { Modal, Tree } from 'antd';
import _ from 'lodash';

const onDrop = (info: any, treeData: any, nestable: boolean) => {
  const dropKey = info.node.key;
  const dragKey = info.dragNode.key;
  const dropPos = info.node.pos.split('-');
  const dropPosition = info.dropPosition - Number(dropPos[dropPos.length - 1]);
  const data = [...treeData];
  let dragObj: any;

  const loop = (data: any, key: any, callback: any) => {
    for (let i = 0; i < data.length; i++) {
      if (data[i].key === key) {
        return callback(data[i], i, data);
      }
      if (data[i].children) {
        loop(data[i].children, key, callback);
      }
    }
  };

  // Find dragObject
  loop(data, dragKey, (item: any, index: any, arr: any) => {
    arr.splice(index, 1);
    dragObj = item;
  });

  if (!info.dropToGap && nestable) {
    // Drop on the content
    loop(data, dropKey, (item: any) => {
      item.children = item.children || [];
      return item.children.unshift(dragObj);
    });
  } else if (
    (info.node.props.children || []).length > 0 && // Has children
    info.node.props.expanded && // Is expanded
    dropPosition === 1 // On the bottom gap
  ) {
    loop(data, dropKey, (item: any) => {
      item.children = item.children || [];
      item.children.unshift(dragObj);
    });
  } else {
    let ar: any;
    let i: any;
    loop(data, dropKey, (_: any, index: any, arr: any) => {
      ar = arr;
      i = index;
    });
    if (dropPosition === -1) {
      ar.splice(i, 0, dragObj);
    } else {
      ar.splice(i + 1, 0, dragObj);
    }
  }

  return data;
};

interface Props {
  treeData: any;
  isNestable: boolean;
  isLoading: boolean;
  onOk: any;
  onCancel: any;
};

export default function RearrangeModal(props: Props) {
  const [tree, setTree] = useState(props.treeData);

  const replaceTitle = (category: any) => {
    return {
      'key': category.id,
      'title': category.title,
      ...category,
      'children': !_.isEmpty(category.children) ? category.children.map((child: any) => replaceTitle(child)) : null,
    };
  };

  // Since title is used in the table with the whole nested objects, we'll need to override this
  const manipulatedTreeData = tree.map((category: any) => replaceTitle(category));

  return (
    <Modal
      visible
      title={ 'Change order' }
      okButtonProps={{
        loading: props.isLoading,
        disabled: props.isLoading
      }}
      cancelButtonProps={{
        disabled: props.isLoading
      }}
      onOk={() => {
        props.onOk && props.onOk(manipulatedTreeData);
      } }
      onCancel={ () => props.onCancel() }
    >
      <Tree
        style={{ maxHeight: 500, overflow: 'scroll' }}
        className="draggable-tree"
        draggable
        blockNode
        defaultExpandAll
        showLine={{ showLeafIcon: false }}
        onDrop={ (info: any) => setTree(onDrop(info, manipulatedTreeData, props.isNestable)) }
        treeData={ manipulatedTreeData }
      />
    </Modal>
  );
};
