// Libs
import * as React from 'react';

// Components
import RecordView from 'views/common/RecordView';

// Interfaces
import { RecordFormEntity } from 'types/entities';
import { OnClone, OnLoading } from 'components/form/form-wrapper/FormWrapper.interface';

// Views
import MaintenancePlanCopyModal from 'views/workplace-service/maintenance-plan/MaintenancePlanCopyModal';

interface Props {
  record: RecordFormEntity;
  setRecord: (record: RecordFormEntity) => void;
};

class MaintenancePlanRecord extends React.Component<Props> {

  renderCloneDialog = (isCopying: boolean, onLoading?: OnLoading, onClone?: OnClone, onClose?: () => void) => {
    return (
      <MaintenancePlanCopyModal onClose={ onClose } onCopy={ onClone } isCopying={ isCopying } onLoading={ onLoading } record={ this.props.record } />
    );
  };

  render = () => {
    return <RecordView type={ 'asset_maintenance_plan' } entity={ 'category' } id={ this.props.record.id } record={ this.props.record } setRecord={ this.props.setRecord } renderCloneDialog={ this.renderCloneDialog }/>;
  };
};

export default MaintenancePlanRecord;
