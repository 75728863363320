// Libs
import React from 'react';
import _ from 'lodash';
import classNames from 'classnames';

//Components
import { Modal, Input, Form, Select, Row, Col, Tabs, Button, Table, Spin, Popconfirm, Tooltip, Checkbox } from 'antd';
import { EditOutlined, DeleteOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import FieldWrapper from 'components/form/field/field-wrapper';
import BadgeComponent, { BadgeType } from 'components/badge/Badge';

// Services
import Notification from 'services/notification';

// Interfaces
import {
  WorkflowCondition,
  WorkflowConditionType,
  WorkflowTransition,
  WorkflowTransitionHandle,
  WorkflowTransitionTrigger,
} from 'components/workflow';
import { RoleResourcesEntity } from 'types/entities';

// Utils
import { getBadges, transformFieldTitle } from 'utils/workflow';

// Styles
import './WorkflowMesh.scss';

const { TabPane } = Tabs;
const { TextArea } = Input;

type TransitionState = Partial<WorkflowTransition>;

type Config = Partial<{
  roles?: Array<string>,
  field?: Partial<{ id: string, label: string, type: string }>,
}>;

interface ConditionState {
  id: number;
  type: WorkflowConditionType;
  config: Config;
};

enum TabPaneType {
  Basic = "basic",
  Delete = "delete",
  Conditions = "conditions",
  Condition_details = "condition_details",
};

export enum Mode {
  Create = "Create",
  Edit = "Edit",
};

interface Props {
  mode: Mode;
  defaultTransition?: TransitionState;
  onCancel: () => void;
  request?: (data: TransitionState) => Promise<void>;
  deleteTransition?: () => Promise<void>;
  deleteCondition?: (conditionId: number) => Promise<WorkflowCondition[]>;
  getRoles: () => Promise<{ [id: string]: RoleResourcesEntity }>;
  getFields: () => Promise<Array<{ id: string; type: string; label: string; }>>;
  editCondition?: (conditionId: number, payload: { type: WorkflowConditionType, config: any }) => Promise<WorkflowCondition[]>;
  createCondition?: (payload: { type: WorkflowConditionType, config: any }) => Promise<WorkflowCondition[]>;
};

interface State {
  transition: TransitionState;
  isLoading: boolean;
  isFetching: boolean;
  activeTab: TabPaneType;
  condition: Partial<ConditionState> | null;
  isEditingCondition: boolean;
  roles: { [id: string]: RoleResourcesEntity } | null;
  fields: Array<{ id: string; type: string; label: string; }>;
};

export class WorkflowTransitionModal extends React.Component<Props, State> {
  state: State = {
    transition: this.props.defaultTransition || {
      title: '',
      description: '',
      conditions: [],
      requires_comment: false,
      requires_comment_description: '',
    },
    isLoading: false,
    isFetching: false,
    isEditingCondition: false,
    activeTab: TabPaneType.Basic,
    condition: null,
    roles: null,
    fields: [],
  };

  renderHandlers = () => {
    return (
      <>
        <Select.Option value={ WorkflowTransitionHandle.Top }>Top</Select.Option>
        <Select.Option value={ WorkflowTransitionHandle.Right }>Right</Select.Option>
        <Select.Option value={ WorkflowTransitionHandle.Bottom }>Bottom</Select.Option>
        <Select.Option value={ WorkflowTransitionHandle.Left }>Left</Select.Option>
      </>
    );
  };

  renderBasicTab = () => {
    const { transition } = this.state;
    const { mode } = this.props;
    return (
      <Form layout="vertical">
        <Form.Item label="Title" required>
          <Input
            value={ transition.title }
            onChange={ (e) => {
              this.setState({
                transition: { ...transition, title: e.target.value },
              });
            } }
          />
        </Form.Item>
        <Form.Item label="Description" required>
          <TextArea
            rows={ 4 }
            value={ transition.description || '' }
            onChange={ (e) => {
              this.setState({
                transition: { ...transition, description: e.target.value },
              });
            } }
          />
        </Form.Item>
        <Form.Item label="Trigger" required>
          <Select
            defaultValue={ transition.trigger }
            onChange={ (transitionTrigger: WorkflowTransitionTrigger) => {
              this.setState({
                transition: { ...transition, trigger: transitionTrigger },
              });
            } }
          >
            { Object.entries(WorkflowTransitionTrigger).map(([title, value], i) => (
              <Select.Option key={ value + i } value={ value }>
                { _.capitalize(title) }
              </Select.Option>
            )) }
          </Select>
        </Form.Item>
        { mode === Mode.Edit && (
          <>
            <Row>
              <Col span={ 10 }>
                <Form.Item label="Source Stage">
                  <Input value={ transition.source_stage_title } readOnly disabled />
                </Form.Item>
              </Col>
              <Col span={ 10 } offset={ 4 }>
                <Form.Item label="Target Stage">
                  <Input value={ transition.target_stage_title } readOnly disabled />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={ 10 }>
                <Form.Item label="Source-handle">
                  <Select
                    defaultValue={ transition.source_handle }
                    onChange={ (transitionHandle: WorkflowTransitionHandle) => {
                      this.setState({
                        transition: { ...transition, source_handle: transitionHandle },
                      });
                    } }
                  >
                    { this.renderHandlers() }
                  </Select>
                </Form.Item>
              </Col>
              <Col span={ 10 } offset={ 4 }>
                <Form.Item label="Target-handle">
                  <Select
                    defaultValue={ transition.target_handle }
                    onChange={ (transitionHandle: WorkflowTransitionHandle) => {
                      this.setState({
                        transition: { ...transition, target_handle: transitionHandle },
                      });
                    } }
                  >
                    { this.renderHandlers() }
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          </>
        ) }
        <Form.Item>
          <Checkbox
            onChange={ (e) => {
              const payload = {
                ...transition,
                requires_comment: e.target.checked,
                requires_comment_description: e.target.checked ? transition.requires_comment_description  : ''
              };
              this.setState({
                transition: payload,
              });
            } }
            checked={ transition.requires_comment }
          >
            Requires comment
          </Checkbox>
        </Form.Item>
        { !!transition.requires_comment && (
          <Form.Item label="Comment Tooltip">
            <TextArea
              rows={ 4 }
              value={ transition.requires_comment_description || '' }
              onChange={ (e) => {
                this.setState({
                  transition: { ...transition, requires_comment_description: e.target.value },
                });
              } }
            />
          </Form.Item>
        ) }
      </Form>
    );
  };

  renderBasicTabFooter = () => {
    const { mode, defaultTransition, onCancel, request } = this.props;
    const { transition, isLoading } = this.state;
    const successMessage= `Transition ${mode === Mode.Create ? 'created' : 'edited'}`;
    const errorMessage=`Failed to ${Mode.Create ? 'create' : 'edit'} transition`;

    const isDisabledOkBtn = () => {
      const { title, trigger } = transition;

      // if the title is empty, disable the ok button
      if (!title?.trim()) {
        return true;
      }

      // if the trigger is not selected, disable the ok button
      if (!trigger) {
        return true;
      }

      // if the data has not changed, disable the ok button
      if (mode === Mode.Edit && _.isEqual(defaultTransition, {
          ...transition,
          requires_comment: Number(transition.requires_comment),
        })
      ) {
        return true;
      }
    };

    return (
      <div className={ classNames({ "d-f fxd-r jc-sb": mode === Mode.Edit }) }>
        { mode === Mode.Edit && (
          <Button
            type="primary"
            danger
            disabled={ isLoading }
            onClick={ () => this.setState({ activeTab: TabPaneType.Delete }) }
          >
            Delete
          </Button>
        ) }
        <div>
          <Button disabled={ isLoading } onClick={ onCancel }>
            Cancel
          </Button>
          <Button
            type="primary"
            loading={ isLoading }
            disabled={ isDisabledOkBtn() }
            onClick={ () =>
              this.setState({ isLoading: true }, async () => {
                try {
                  await request?.(transition);
                  successMessage && Notification('success', '', successMessage);
                } catch (e) {
                  errorMessage && Notification('error', '', errorMessage);
                } finally {
                  this.setState({ isLoading: false });
                  onCancel();
                }
              })
            }
          >
            OK
          </Button>
        </div>
      </div>
    );
  };

  renderDeleteTab = () => {
    return (
      <>
        <p className="mB-10 mT-20">
          This will permanently delete this workflow transition.
        </p>
        <p>Please confirm you wish to proceed.</p>
      </>
    );
  };

  renderDeleteTabFooter = () => {
    const { onCancel, deleteTransition } = this.props;
    const { isLoading } = this.state;
    return (
      <div>
        <Button disabled={ isLoading } onClick={ () => this.setState({ activeTab: TabPaneType.Basic }) }>Cancel</Button>
        <Button
          type="primary"
          danger
          loading={ isLoading }
          onClick={ () =>
            this.setState({ isLoading: true }, async () => {
              try {
                await deleteTransition?.();
                Notification('success', '', 'Transition deleted');
              } catch (e) {
                Notification('error', '', 'Failed to delete transition');
              } finally {
                this.setState({ isLoading: false });
                onCancel();
              }
            })
          }
        >
          Delete
        </Button>
      </div>
    );
  };

  renderConditionsTab = () => {
    const { getRoles, getFields, deleteCondition, mode } = this.props;
    const { transition, isLoading } = this.state;

    const columns: any = [
      {
        dataIndex: 'type',
        key: 'type',
        title: 'Type',
        render: (type: string) => {
          return (
            <div>{ transformFieldTitle(type) }</div>
          );
        },
        ellipsis: true,
      },
      {
        dataIndex: 'config',
        key: 'config',
        title: 'Additional Config',
        render: (config: any) => {
          return (
            <div className="d-f fxw-w">
              { getBadges(config)?.map(({ title, tooltip }, i) => {
                const component = (
                  <BadgeComponent
                    key={ title + i }
                    className={ 'mR-5 mB-5' }
                    type={ tooltip ? BadgeType.Warning : BadgeType.Success }
                    text={ title }
                  />
                );
                if (tooltip) {
                  return (
                    <Tooltip placement="topRight" title={ tooltip } key={ title + i }>
                      <span>{ component }</span>
                    </Tooltip>
                  );
                }
                return component;
              }) }
            </div>
          );
        },
        ellipsis: true,
      },
      {
        key: 'actions',
        dataIndex: 'actions',
        title: () => (
          <Button onClick={ () => this.setState({ activeTab: TabPaneType.Condition_details }) }>
            Create New
          </Button>
        ),
        render: (__: any, record: { id: number, type: WorkflowConditionType, config: { [key: string]: any } }) => {
          return (
            <>
              <EditOutlined
                className="link"
                style={{ fontSize: 18 }}
                onClick={ () => {
                  this.setState({ isFetching: true, activeTab: TabPaneType.Condition_details, isEditingCondition: true }, async () => {
                    try {
                      let config: Config = {};
                      let roles: { [id: string]: RoleResourcesEntity } | null = null;
                      let fields: Array<{ id: string; type: string; label: string; }> = [];

                      if (record.config.roles) {
                        roles = await getRoles();
                        config.roles = record.config.roles;
                      }

                      if(record.config.field) {
                        fields = await getFields();
                        config.field = fields.find(f => f.label === record.config.field[0]);
                      }

                      this.setState({
                        condition: { id: record.id, type: record.type, config },
                        roles,
                        fields
                      });
                    } catch (e) {
                      console.log(e);
                    } finally {
                      this.setState({ isFetching: false });
                    }
                  });
                } }
              />
              <Popconfirm
                title={ 'Are you sure?' }
                icon={ <QuestionCircleOutlined style={{ color: 'red' }} /> }
                okButtonProps={{
                  danger: true
                }}
                placement="topRight"
                onConfirm={ async () => {
                  this.setState({ isLoading: true }, async () => {
                    try {
                      if (mode === Mode.Edit) {
                        const response = await deleteCondition?.(record.id);
                        this.setState({ transition: Object.assign(transition, { conditions: response }) });
                      }
                      if (mode === Mode.Create) {
                        const updatedConditions = transition.conditions?.filter((c) => c.id !== record.id) || [];
                        this.setState({ transition: Object.assign(transition, { conditions: updatedConditions }) });
                      }
                    } catch (e) {
                      console.log(e);
                    } finally {
                      this.setState({ isLoading: false });
                    }
                  });
                } }
              >
                <DeleteOutlined
                  className="mL-20 link"
                  style={{ fontSize: 18 }}
                />
              </Popconfirm>
            </>
          );
        },
        width: 120,
        ellipsis: true,
        align: 'center',
      },
    ];

    return (
      <div className="ConditionsTableWrapper">
        <Table
          size={ 'small' }
          rowKey={ 'id' }
          pagination={ false }
          columns={ columns }
          dataSource={  transition.conditions || [] }
          loading={ isLoading }
        />
      </div>
    );
  };

  renderConditionDetailsTab = () => {
    const { getRoles, getFields, mode } = this.props;
    const { condition, isFetching, roles, fields } = this.state;
    let fieldTypes = _.uniqBy(fields, 'type').map(field => field.type);
    const filteredFields = fields.filter(field => field.type === condition?.config?.field?.type);
    const showFieldsAdmin = condition?.type === WorkflowConditionType.Field_required || condition?.type === WorkflowConditionType.Date_passed;

    // Limit to date fields when condition is Date_passed
    if (condition?.type === WorkflowConditionType.Date_passed) {
      fieldTypes = fieldTypes.filter(type => type === 'datetime');
    }

    return (
      <Spin tip="Loading..." spinning={ isFetching }>
        <FieldWrapper label={ 'Condition Type' } style={{ minHeight: 50, marginTop: 20 }} required>
          <Select
            value={ condition?.type }
            placeholder={ 'Please choose the condition type' }
            onChange={ (conditionType: WorkflowConditionType) => {
              // generated id is needed to create a local condition
              const conditionId = mode === Mode.Create && _.isNull(condition) ? new Date().getTime() : condition?.id;
              this.setState({ isFetching: true, condition: { id: conditionId, type: conditionType, config: {} } }, async () => {
                try {
                  if (conditionType === WorkflowConditionType.Role_required) {
                    const response = await getRoles();
                    this.setState({ roles: response });
                  }
                  if (conditionType === WorkflowConditionType.Field_required || conditionType === WorkflowConditionType.Date_passed) {
                    const response = await getFields();
                    this.setState({ fields: response });
                  }
                } catch(e) {
                  console.log(e);
                } finally {
                  this.setState({ isFetching: false });
                }
              });
            } }
          >
            { Object.entries(WorkflowConditionType).map(([title, value], i) => (
                <Select.Option key={ value + i } value={ value }>
                  { transformFieldTitle(title) }
                </Select.Option>
            )) }
          </Select>
        </FieldWrapper>
        { condition?.type === WorkflowConditionType.Role_required && (
            <FieldWrapper label={ 'Roles' } style={{ minHeight: 50 }} required>
              <Select
                mode="multiple"
                value={ condition.config?.roles }
                placeholder={ 'Please choose the roles' }
                onChange={ (references: string[]) => {
                  this.setState({ condition: Object.assign(condition, { config: { roles: references } }) });
                } }
              >
                { !_.isNull(roles) && Object.keys(roles).map((id) => (
                    <Select.Option key={ id } value={ roles[id].reference }>
                      { roles[id].title }
                    </Select.Option>
                )) }
              </Select>
            </FieldWrapper>
        ) }
        { condition?.type && showFieldsAdmin && (
            <FieldWrapper label={ 'Field Type' } style={{ minHeight: 50 }} required>
              <Select
                value={ condition.config?.field?.type }
                placeholder={ 'Please choose the field type' }
                onChange={ (type: string) => this.setState({ condition: Object.assign(condition, { config: { field: { type } } }) }) }
              >
                { fieldTypes.map((type) => (
                  <Select.Option key={ type } value={ type }>
                    { type }
                  </Select.Option>
                )) }
              </Select>
            </FieldWrapper>
        ) }
        { condition?.type && showFieldsAdmin && (
            <FieldWrapper label={ 'Field' } style={{ minHeight: 50 }} required>
              <Select
                disabled={ !condition.config?.field?.type }
                value={ condition.config?.field?.id }
                placeholder={ 'Please choose the field' }
                onChange={ (fieldId: string) => {
                  const selectedField = fields.find((field) => field.id === fieldId);
                  if (selectedField) {
                    this.setState({ condition: Object.assign(condition, { config: { field: selectedField } }) });
                  }
                } }
              >
                { filteredFields.map(({ id, label }) => (
                  <Select.Option key={ id } value={ id }>
                    { label }
                  </Select.Option>
                )) }
              </Select>
            </FieldWrapper>
        ) }
      </Spin>
    );
  };

  renderConditionDetailsTabFooter = () => {
    const { mode, editCondition, createCondition } = this.props;
    const { isLoading, condition, isEditingCondition, transition } = this.state;

    const isDisabledOkBtn = () => {
      // if the condition type is not selected, disable the create button
      if (_.isNull(condition)) {
        return true;
      }

      const { type, config } = condition;

      // if the condition type is role_required and no additional configuration is selected, disable the create button
      if (type === WorkflowConditionType.Role_required && _.isEmpty(config?.roles)) {
        return true;
      }

      // if the condition type is field_required and no additional configuration is selected, disable the create button
      if (type === WorkflowConditionType.Field_required && !config?.field?.label) {
        return true;
      }
    };

    const getConfig = (config?: Config) => {
      if (config?.roles) {
        return { roles: config.roles };
      }
      if (config?.field?.id) {
        return { field: [config.field.id] };
      }
      return [];
    };

    const onClickHandler = () => {
      this.setState({ isLoading: true }, async () => {
        try {
          const { id, type, config } = condition as ConditionState;
          const { conditions = [] } = transition;
          const payload = { id, type, config: getConfig(config) };
          if (mode === Mode.Edit) {
            const response = await (isEditingCondition ? editCondition?.(id, payload) : createCondition?.(payload));
            this.setState({ transition: Object.assign(transition, { conditions: response }) });
          }
          if (mode === Mode.Create) {
            const updatedConditions = isEditingCondition
              ? conditions.map((item) => (item.id === id ? payload : item))
              : [...conditions, payload];
            this.setState({ transition: Object.assign(transition, { conditions: updatedConditions }) });
          }
        } catch(e) {
          console.log(e);
        } finally {
          this.setState({ activeTab: TabPaneType.Conditions, isEditingCondition: false, condition: null,  isLoading: false });
        }
      });
    };

    return (
      <div>
        <Button
          disabled={ isLoading }
          onClick={ () => this.setState({ activeTab: TabPaneType.Conditions, isEditingCondition: false, condition: null }) }
        >
          Cancel
        </Button>
        <Button
          type="primary"
          disabled={ isDisabledOkBtn() }
          loading={ isLoading }
          onClick={ onClickHandler }
        >
          OK
        </Button>
      </div>
    );
  };

  render() {
    const { mode, onCancel } = this.props;
    const { isLoading, activeTab, isEditingCondition } = this.state;

    const modalTitle: string = {
      [TabPaneType.Basic]: `${mode} Transition`,
      [TabPaneType.Delete]: 'Delete Transition',
      [TabPaneType.Conditions]: `${mode} Transition`,
      [TabPaneType.Condition_details]: `${isEditingCondition ? 'Edit' : 'Create'} Condition`,
    }[activeTab];

    const renderFooter: () => JSX.Element = {
      [TabPaneType.Basic]: this.renderBasicTabFooter,
      [TabPaneType.Delete]: this.renderDeleteTabFooter,
      [TabPaneType.Conditions]: this.renderBasicTabFooter,
      [TabPaneType.Condition_details]: this.renderConditionDetailsTabFooter,
    }[activeTab];

    const isHideTabBar = activeTab === TabPaneType.Delete || activeTab === TabPaneType.Condition_details;

    return (
      <Modal
        visible
        centered
        title={ modalTitle }
        maskClosable={ !isLoading }
        bodyStyle={{ paddingTop: 5 }}
        style={{ minWidth: 800 }}
        onCancel={ () => !isLoading && onCancel() }
        footer={ renderFooter() }
      >
        <Tabs
          activeKey={ activeTab }
          onTabClick={ (key) => this.setState({ activeTab: key as TabPaneType }) }
          renderTabBar={ isHideTabBar ? () => <></> : undefined }
        >
          <TabPane tab="Basic" key={ TabPaneType.Basic }>
            { this.renderBasicTab() }
          </TabPane>
          <TabPane tab="Conditions" key={ TabPaneType.Conditions }>
            { this.renderConditionsTab() }
          </TabPane>
          <TabPane key={ TabPaneType.Delete } disabled>
            { this.renderDeleteTab() }
          </TabPane>
          <TabPane key={ TabPaneType.Condition_details } disabled>
            { this.renderConditionDetailsTab() }
          </TabPane>
        </Tabs>
      </Modal>
    );
  };
};
