// Libs
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import classNames from 'classnames';

// Components
import FieldWrapper from 'components/form/field/field-wrapper';
import { Select, Menu, Dropdown, SelectProps } from 'antd';

// View
import CreateRecordView from 'views/common/CreateRecordView';

// Icons
import { PlusCircleOutlined } from '@ant-design/icons';

// Services
import { Api } from 'services/api';

// Interfaces
import {
  FormField,
  FormRecord,
  FormFieldConfig,
  FormFieldInfoBoxModifiedMessage,
  FormFieldInfoBoxErrorMessage,
  FieldConfigTarget,
} from 'components/form/form-wrapper';

import { RecordFormEntity } from 'types/entities';

// Utilities
import Console from 'utils/console';

const { OptGroup, Option } = Select;
const API: Api = new Api();

const REDACTED_LABEL = 'REDACTED';

interface RelationOption {
  bundle: string;
  id: number;
  title: string;
  type: string;
  path: string;
  reference: string;
  group?: string;
  redacted?: boolean;
  disabled?: boolean;
};

interface GroupedOptions<T> {
  label: string;
  options: T[];
};

interface Props {
  clientId: number;
  record: RecordFormEntity;
  entity: string;
  config: FormFieldConfig;
  field: FormField;
  state: any;
  originalState: any;
  modifiedForm: FormRecord;
  isDisabled: boolean;
  isModifying: boolean;
  border?: boolean;
  onChange(
    field: FormField,
    value: any,
    config: FormFieldConfig,
    column?: string,
  ): void;
  onRefreshForm(field_id: string): void;
  fieldErrorMessages: any;
  fieldModifiedMessages: any;
  setFieldModifiedMessage(id: string, message?: FormFieldInfoBoxModifiedMessage): void;
  setFieldErrorMessage(id: string, message?: FormFieldInfoBoxErrorMessage): void;
  validate(field: FormField, column: string, value: string | number): string[];
};

interface State {
  options: RelationOption[];
  isLoading: boolean;
  showCreateModal: boolean;
  isCreateLoading: boolean;
  bundle: string | null,
  type: string| null,
};

const generateGroupedOptions = <T extends {}>(options: T[], key: keyof T): GroupedOptions<T>[] => {
  return options.reduce((acc, option: T) => {
    const groupLabel: string = !!option[key] ? String(option[key]) : 'unknown';
    const index = acc.findIndex(group => group.label === groupLabel);
    if (index !== -1) {
      acc[index].options.push(option);
    } else {
      acc.push({ label: groupLabel, options: [option] });
    }
    return acc;
  }, [] as GroupedOptions<T>[]);
};

class RelationshipField extends Component<Props, State> {

  mounted: boolean = false;

  state: State = {
    options: [],
    isLoading: false,
    showCreateModal: false,
    isCreateLoading: false,
    bundle: null,
    type: null,
  };

  componentDidMount = () => {
    const { state, field } = this.props;
    this.mounted = true;

    this.validate(state);

    if (_.has(field, 'static_options') && !!field.static_options) {
      this.mounted && this.setState({ options: field.static_options });
    } else {
      const currentState = this.canSelectMultiple() ? state : state[0];
      if (!!currentState && _.has(currentState, 'target_title')) {
        this.handleSearch(currentState.target_title);
      } else {
        this.handleSearch('');
      }
    }
  };

  componentDidUpdate = (prevProps: Props) => {
    const { field, state, isModifying } = this.props;

    // If "force_refresh_options" config is set, force retrieval of new options
    // Allows API form manipulation to trigger a value refresh
    if(_.has(field.config, 'force_refresh_options') && field.config.force_refresh_options === true && isModifying === false) {
      if (!!field.config.force_refresh_options) {
        field.config.force_refresh_options = false;
        this.handleSearch('');
      }
    }

    if (!_.isEqual(prevProps.field.static_options, this.props.field.static_options)) {
      this.mounted && this.setState({ options: this.props.field.static_options || [] });
    }

    if (!_.isEqual(prevProps.field, field)) {
      this.validate(state);

      if (!!field.config.refresh_on_change && isModifying === false) {
        this.props.onRefreshForm(field.id);
      }
    }
  };

  componentWillUnmount = () => {

    const { field, originalState, config, onChange } = this.props;

    if (field.config?.clear_value_on_hide) {
      // Revert state
      onChange(field, originalState, config);
    }

    // Remove validations for this field
    this.validate(originalState, true);

    this.mounted = false;
  };

  validate = (state: any, shouldClear = false) => {
    const { originalState } = this.props;

    const pastValue = originalState && originalState.map((value: any) => value.target_id).sort((a: number, b: number) => a - b);
    const newValue = state && state.map((value: any) => value.target_id).sort((a: number, b: number) => a - b);

    this.generateModifiedState(pastValue, newValue, 'relationship', shouldClear);
    this.generateErrorState(newValue, 'relationship', shouldClear);
  };

  handleSearch = async (value: string) => {
    const { field, clientId } = this.props;
    try {
      await new Promise((resolve) => this.setState({ isLoading: true }, () => resolve(null) ));
      const options = await this.getRelationOptions(clientId, field.id, value);

      this.mounted && this.setState({
        options: options,
      });
    } catch (error) {
      Console.error(error);
    } finally {
      this.mounted && this.setState({ isLoading: false });
    }
  };

  getRelationOptions = async (clientID: number, fieldID: string, query: string): Promise<RelationOption[]> => {
    const { record, config, modifiedForm } = this.props;
    try {

      if (!clientID || !fieldID) throw new Error('Missing dependencies');

      return await API.post(`client/${clientID}/${record.bundle.replaceAll('_', '-')}/${record.type.replaceAll('_', '-')}/${ !!record.id ? `${record.id}/` : '' }field/relationship/${fieldID}/get-options`, {
        search: query,
        modifiedForm: JSON.stringify(_.set(_.cloneDeep(record), ['form', config.tabIndex], modifiedForm)),
      });

    } catch {
      return [];
    }
  };

  getConvertedOptions = (options: RelationOption[]): any[] => {
    const convertedOptions = options.map((option: RelationOption) => ({
      label: option.title,
      value: option.id,
      target_bundle: option.bundle,
      target_id: option.id,
      target_type: option.type,
      target_title: !!option.redacted ? REDACTED_LABEL : option.title,
      target_path: option.path,
      target_group: option.group,
      target_reference: option.reference
    }));

    if (this.isGrouped()) {
      return generateGroupedOptions(convertedOptions, 'target_group');
    }

    return convertedOptions;
  };

  generateModifiedState = (pastValue: any, newValue: any, columnKey: string, shouldClear = false) => {
    const { field, config, setFieldModifiedMessage } = this.props;

    const id = field.id;
    const cardinality = config.fieldIndex || 0;
    const key = `${id}_${cardinality}_${columnKey}`;

    if (!_.isEqual(pastValue, newValue) && !shouldClear) {
      const message: FormFieldInfoBoxModifiedMessage = {
        id: field.id,
        cardinality: cardinality,
        group: config.groupID,
        tab: config.tabID,
        order: config.elementIndex,
        content: {
          label: field.label,
          content: [],
        },
        modified: {}
      };

      setFieldModifiedMessage(key, message);
    } else {
      setFieldModifiedMessage(key);
    }
  };

  generateErrorState = (values: any, columnKey: string, shouldClear = false) => {
    const { field, config, setFieldErrorMessage } = this.props;

    const id = field.id;
    const cardinality = config.fieldIndex || 0;
    const key = `${id}_${cardinality}_${columnKey}`;

    let errors: string[] = [];

    if (field.config.required && _.isEmpty(values)) {
      errors.push('Cannot be empty');
    }

    if (!_.isEmpty(errors) && !shouldClear) {
      const message: FormFieldInfoBoxErrorMessage = {
        id: id,
        cardinality: cardinality,
        group: config.groupID,
        tab: config.tabID,
        order: config.elementIndex,
        content: {
          label: field.label,
          content: []
        },
        errors: errors
      };

      setFieldErrorMessage(key, message);
    } else {
      setFieldErrorMessage(key);
    }
  };

  canSelectMultiple = () => {
    return this.props.field.config.cardinality !== 1;
  };

  isGrouped = (): boolean => {
    // if the display_by_group configuration is passed, the component should display options by group.
    return !!this.props.field.config.display_by_group;
  };

  renderOptions = (options: RelationOption[]) => {
    const modifiedOptions = options.map((option: RelationOption) => !!option.redacted ? ({ ...option, title: REDACTED_LABEL }) : option);

    if (this.isGrouped()) {
      return generateGroupedOptions(modifiedOptions, 'group').map((group: GroupedOptions<RelationOption>, index: number) => (
        <OptGroup key={ `group_${group.label}_${index}` } label={ group.label }>
          { group.options.map((option: RelationOption, index: number) => (
            <Option key={ `option_${option.id}_${index}` } value={ `${option.bundle}_${option.type}_${option.id}` }>{ option.title }</Option>
          )) }
        </OptGroup>
      ));
    }

    return modifiedOptions.map((option: RelationOption, index: number) => (
      <Option key={ index } value={ `${option.bundle}_${option.type}_${option.id}` }>
        { option.title }
      </Option>
    ));
  };

  render = () => {
    const {
      field,
      state,
      config,
      fieldErrorMessages,
      fieldModifiedMessages,
      isDisabled,
      border,
      onChange,
      clientId,
      record,
    } = this.props;
    const {
      options,
      isLoading,
      showCreateModal,
      isCreateLoading,
      type,
      bundle,
    } = this.state;

    const id = field.id;
    const cardinality = config.fieldIndex || 0;
    const key = `${id}_${cardinality}_relationship`;
    const errors = _.has(fieldErrorMessages, key) ? fieldErrorMessages[key].errors : [];
    const isModified = _.has(fieldModifiedMessages, key);
    const targets = field?.config?.target && !_.isEmpty(field?.config?.target) ? field?.config?.target.filter((target: FieldConfigTarget) => !!target.can_create) : [];

    const selectProps: Partial<SelectProps> = {
      loading: isLoading
    };

    if (_.has(this.props.field, 'static_options') && !!field.static_options) {
      selectProps.filterOption = (input: string, optionOrGroup: any): boolean => {
        if (_.isArray(optionOrGroup.options)) {
          return false;
        }
        return !!options?.find((option: RelationOption) => option.title.toLowerCase() === optionOrGroup.children.toLowerCase() && option.title.toLowerCase().includes(input.toLowerCase()));
      };
    } else {
      selectProps.filterOption = false;
      selectProps.defaultActiveFirstOption = false;
      selectProps.onSearch = _.debounce((value: string) => this.handleSearch(value), 1000);
    }

    if (!!isDisabled) {

      let lockedField = <span className="pY-5">-</span>;

      const currentState = this.canSelectMultiple() ? state : state[0];

      if (_.has(currentState, 'target_title')) {
        lockedField = (
          <span className="pY-5">
            { currentState.target_title }
          </span>
        );
      }

      if (_.has(currentState, 'target_title') && _.has(currentState, 'target_path')) {
        lockedField = (
          <Link
            to={ `${currentState.target_path}` }
            className="pY-5"
          >
            { currentState.target_title }
          </Link>
        );
      }

      return (
        <FieldWrapper
          id={ `${config.tabID}|${config.groupID}|${field.id}` }
          col={ config.fieldColSpan }
          label={ field.label }
          required={ field.config.required }
          errors={ errors }
          border={ border }
          versionChanged={ !!field.config.version_changed }
          description={ !!field.description && field.description }
        >
          <div className="d-f">
            { lockedField }
          </div>
        </FieldWrapper>
      );
    }

    return (
      <>
        <FieldWrapper
          id={ `${config.tabID}|${config.groupID}|${field.id}` }
          col={ config.fieldColSpan }
          label={ field.label }
          errors={ errors }
          required={ field.config.required }
          border={ border }
          description={ !!field.description && field.description }
          refreshOnChange={ !!field.config.refresh_on_change }
          versionChanged={ !!field.config.version_changed }
          rightActions={ !_.isEmpty(targets) ? [
            {
              node: targets.length === 1 ? (
                <PlusCircleOutlined className="fsz-def text-ant-default" onClick={ () => this.setState({ isCreateLoading: true, showCreateModal: true, bundle: targets[0].bundle, type: targets[0].type }) } />
              ) : (
                <Dropdown
                  placement={ 'topLeft' }
                  overlay={ () => (
                    <Menu>
                      { targets.map( (target: FieldConfigTarget, index: number) => (
                        <Menu.Item key={ index } onClick={ () => this.setState({ isCreateLoading: true, showCreateModal: true, bundle: target.bundle, type: target.type }) }>
                          { _.upperFirst(target.label) }
                        </Menu.Item>
                      ) ) }
                    </Menu>
                  ) }
                  trigger={ ['click'] }
                >
                  <PlusCircleOutlined className="fsz-def text-ant-default" />
                </Dropdown>
              ),
              isDisabled: false,
              isLoading: isCreateLoading,
            }
          ] : [] }
        >
          <div className="d-f">
            { this.canSelectMultiple() ? (
              <Select
                { ...selectProps }
                labelInValue
                mode={ 'multiple' }
                className={ classNames('Select-Field', {
                  'Select-Field--has-warning': isModified && _.isEmpty(errors)
                }) }
                onChange={ (_options: any[]) => {

                  const newOptions = _options.map((_option: any) => {

                    let target = null;

                    // Try to find the option in the current option list (search list)
                    const currentOption = options.find((option: any) => option.id === _option.value);
                    if (currentOption) {
                      target = {
                        target_id: currentOption.id,
                        target_bundle: currentOption.bundle,
                        target_type: currentOption.type,
                        target_title: currentOption.title,
                        target_path: currentOption.path,
                        target_group: currentOption.group,
                        target_reference: currentOption.reference,
                      };
                    } else {
                      // Try to find the option in the current stats list
                      const stateOption = state.find((option: any) => option.target_id === _option.value);
                      target = {
                        target_id: stateOption.target_id,
                        target_bundle: stateOption.target_bundle,
                        target_type: stateOption.target_type,
                        target_title: stateOption.target_title,
                        target_path: stateOption.target_path,
                        target_group: stateOption.target_group,
                        target_reference: stateOption.target_reference,
                      };
                    }

                    return target;
                  });

                  onChange(field, newOptions, config);
                } }
                loading={ isLoading }
                disabled={ isDisabled }
                value={ state ? state.map((value: any) => {
                  return {
                    label: value.target_title,
                    value: value.target_id,
                    target_bundle: value.bundle,
                    target_id: value.id,
                    target_type: value.type,
                    target_title: value.title,
                    target_path: value.path,
                    target_group: value.group,
                    target_reference: value.reference,
                  };
                }) : [] }
                placeholder={ field.label }
                options={ this.getConvertedOptions(options) }
                // @ts-ignore
                autoComplete="none"
              />
            ) : (
              <Select
                { ...selectProps }
                showSearch
                className={ classNames('Select-Field', {
                  'Select-Field--has-warning': isModified && _.isEmpty(errors)
                }) }
                allowClear={ !field.config.required }
                onClear={ () => onChange(field, null, config) }
                onSelect={ (value: string) => {
                  const option: RelationOption | undefined = options.find((option: RelationOption) => `${option.bundle}_${option.type}_${option.id}` === value);
                  if (!!option) {
                    onChange(field, [{
                      target_bundle: option.bundle,
                      target_id: option.id,
                      target_type: option.type,
                      target_title: option.title,
                      target_path: option.path,
                      target_group: option.group,
                      target_reference: option.reference,
                    }], config);
                  }
                } }
                loading={ isLoading }
                disabled={ isDisabled }
                value={ !_.isEmpty(options) ? !_.isEmpty(state) ? `${state[0].target_bundle}_${state[0].target_type}_${state[0].target_id}` : undefined : '-' }
                placeholder={ field.label }
                // @ts-ignore
                autoComplete="none"
              >
                { this.renderOptions(options) }
              </Select>
            ) }
          </div>
        </FieldWrapper>
        { showCreateModal && !!record && !!type && !!bundle &&
          <CreateRecordView
            type={ _.kebabCase(type) }
            entity={ _.kebabCase(bundle) }
            label={ _.has(field, 'label') ? field.label : null }
            parent_id={ record.id }
            parent_bundle={ record.bundle }
            parent_type={ record.type }
            onReady={ () => this.mounted && this.setState({ isCreateLoading: false }) }
            onClose={ () => this.mounted && this.setState({ showCreateModal: false, type: null, bundle: null }) }
            onCreated={ async (record: any) => {
              try {

                const options = await this.getRelationOptions(clientId, field.id, record.title);
                await new Promise((resolve) => this.setState({ options: options }, () => resolve(null) ));
                const option = options.find((option: any) => option.id === record.id);

                if (!!option) {
                  onChange(field, [{
                    target_id: option.id,
                    target_title: option.title,
                    target_bundle: option.bundle,
                    target_type: option.type,
                    target_path: option.path,
                    target_reference: option.reference,
                  }], config);
                }

              } catch (error) {
                Console.error(error);
              } finally {
                this.mounted && this.setState({ showCreateModal: false, type: null, bundle: null });
              }
            } }
          />
        }
      </>
    );
  };
};

export default RelationshipField;
