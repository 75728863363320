// Libs
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import NumberFormat from 'react-number-format';
import classNames from 'classnames';
import moment from 'moment';
import _ from 'lodash';

// Components
import { Input, Select, Table, Pagination, Button, Badge as AntBadge, Typography, Modal, TreeSelect, Tooltip, DatePicker } from 'antd';
import Badge, { getBadgeType, BadgeType, BadgeSize } from 'components/badge';
import Progress, { calculatePercentage } from 'components/progress';
import Dropdown, { Action as DropdownAction } from 'components/dropdown';
import BulkAssignModal from 'components/bulk-operation/BulkAssignModal';
import AddResourceModal from 'components/bulk-operation/add-resource/AddResourceModal';

// Icons
import { ReactComponent as FilterIcon } from 'assets/svg/filter.svg';
import Icon, { CommentOutlined, UserOutlined, FileOutlined, CloseCircleOutlined, LoadingOutlined } from '@ant-design/icons';

// Services
import { getFormatedDate, getNumberFormatProps, getUserSetting } from 'services/settings';

// Interfaces
import { Action, BulkOperation } from 'components/basic-list/BasicList';

// Styles
import './AdvancedList.scss';

// Utils
import diff from 'utils/htmldiff';
import { isNumeric } from 'utils/utils';

const { Search } = Input;
const { Option } = Select;
const { Link, Text } = Typography;
const { SHOW_PARENT } = TreeSelect;

export type SizeType = 'small' | 'middle' | 'large' | undefined;

export interface List {
  columns: ListColumns[];
  data: ListData[];
  config: {
    nestable: boolean;
  };
  permissions: {
    [key: string]: boolean;
  };
};

interface ListColumns {
  key: string | number;
  dataIndex: string | number;
  id: string;
  type: string;
  options: any;
  label: string;
  width: number;
  align: string;
  sorter: boolean;
  filterable: boolean;
  ellipsis: boolean;
  fixed: string | boolean;
  render: (field: ListDataValues, row: any) => React.ReactNode;
};

interface ListData {
  [key: string]: ListDataValues;
};

interface ListDataValues {
  type: string;
  value: any;
  title?: string;
  old?: string;
  new?: string;
  total?: number;
  value_total?: number;
  prefix?: string | null;
  suffix?: string | null;
  format?: string | null;
  text?: string | number;
  color?: string;
  code?: string;
  unit?: string;
  size?: BadgeSize;
  path?: string;
  filename?: string;
};

export interface Config {
  can_create: boolean;
  nestable: boolean;
  conversions: Conversion;
  bulk_operations: BulkOperation[];
};

interface Conversion {
  currencies: CurrencyConversion[];
  measurements: MeasurementConversion[];
};

interface CurrencyConversion {
  id: number;
  title: string;
  code: string;
  rate: number;
  prefix: string | null;
  suffix: string | null;
};

interface MeasurementConversion {
  id: number;
  title: string;
  unit: string;
  rate: number;
  prefix: string | null;
  suffix: string | null;
};

interface Props {
  clientId: number;
  filters: any;
  columns: any;
  items: any;
  config?: Config | null;
  rightActions?: DropdownAction[];
  size?: SizeType;
  rawData?: boolean;
  isFetching?: boolean;
  dateRangeFilterFields?: string[];
  defaultSortOrder?: { field: string, order: 'ascend' | 'descend' };
  defaultExpandedRowKeys?: string[];
  onExport: (filters: any, currency: string | null, measurement: string | null, callback: (successful: boolean) => void) => void;
  onFilter: (filters: any, currency: string | null, measurement: string | null, callback: (successful: boolean) => void) => void;
};

interface State {
  columns: any;
  items: any;
  currency: string | null;
  measurement: string | null;
  currentPage: number;
  itemsPerPage: number;
  sorter: any;
  showFilter: boolean;
  quickFilter: string | null;
  filters: any;
  expandedRowKeys: string[];
  activeFilters: any;
  tableHeight: number;
  tableWidth: number;
  sublistItems: any[];
  activeSublist: any;
  isExporting: boolean;
  isLoading: boolean;
  selectedRowKeys: any[];
  bulkOperation: string | null;
};

class AdvancedListView extends React.Component<Props, State> {

  component: any = React.createRef(); // This is used to figure out when the component is rendered

  state: State = {
    columns: this.props.columns,
    items: this.props.items,
    currency: getUserSetting('currency'),
    measurement: getUserSetting('measurement'),
    currentPage: 1,
    itemsPerPage: 50,
    sorter: !_.isEmpty(this.props.defaultSortOrder) ? this.props.defaultSortOrder : null,
    expandedRowKeys: this.props?.defaultExpandedRowKeys ? this.props.defaultExpandedRowKeys : [],
    showFilter: false,
    quickFilter: null,
    filters: {},
    activeFilters: {},
    tableHeight: 0,
    tableWidth: 0,
    sublistItems: [],
    activeSublist: null,
    isExporting: false,
    isLoading: false,
    selectedRowKeys: [],
    bulkOperation: null
  };

  componentDidMount = async () => {
    if (this.component) {
      this.heightObserver();
    }

    this.filterDefaultValues();
  };

  componentDidUpdate = (prevProps: Props) => {

    // Update columns if needed
    if (!_.isEqual(this.props.columns, this.state.columns)) {
      this.setState({
        columns: this.props.columns
      });
    }

    // Update items if needed
    if (!_.isEqual(this.props.items, prevProps.items)) {
      this.setState({
        items: this.props.items
      });
    }

    if (this.component) {
      this.heightObserver();
    }
  };

  heightObserver = () => {
    const root: number = document.getElementById('root')?.offsetHeight || 0;
    const header: number = document.getElementById('Header')?.offsetHeight || 0;
    const jumbotron: number = document.getElementById('Jumbotron')?.offsetHeight || 0;
    const tabViewBar: number = document.getElementById('TabView-bar')?.offsetHeight || 0;
    const filtersHeight: number = document.getElementById('AdvancedListFilter')?.offsetHeight || 0;
    const tableHeight: number = root - (header + jumbotron + tabViewBar + filtersHeight + (this.shouldShowSummary() ? 170 : 130));

    if (this.state.tableHeight !== tableHeight) {
      this.setState({
        tableHeight: tableHeight
      });
    }

    const rootWidth = document.getElementById('root')?.offsetWidth || 0;
    const layoutMenu = document.getElementById('Layout-menu')?.offsetWidth || 0;
    const tableWidth = rootWidth - (layoutMenu + 160); // 160 is sum of margins and paddings around the table

    if (this.state.tableWidth !== tableWidth) {
      this.setState({
        tableWidth: tableWidth
      });
    }
  };

  getFlatten = (data: any) => {

    const collector: any = [];

    data.forEach((value: any) => {
      const check = (_value: any) => {
        collector.push({ ..._value });

        if (_.has(_value, 'children') && !_.isEmpty(_value.children)) {
          _value.children.forEach((__value: any) => {
            check(__value);
          });
        }
      };

      return check(value);
    });

    return collector;
  };

  totalSublistItems = (values: any) => {
    let count = 0;
    values.forEach((value: any) => {
      const check = (_value: any) => {
        count++;
        if (_.has(_value, 'children') && !_.isEmpty(_value.children)) {
          _value.children.forEach((__value: any) => {
            check(__value);
          });
        }
      };

      check(value);
    });

    return count;
  };

  shouldShowSummary = () => {
    return this.state.columns.some((column: any) => {
      return !!column?.footer_total;
    });
  };

  renderColumnTitle = (column: ListColumns, data: any[]) => {
    let label: React.ReactNode = column.label;

    if (column.type === 'currency') {
      label = `${label} (${this.state.currency})`;
    }

    if (column.type === 'area') {
      label = `${label} (${this.state.measurement})`;
    }

    if (column.id === 'title') {
      label = (
        <>
          <span>Title</span>
          <Tooltip
            placement="top"
            title={ _.isEmpty(this.state.expandedRowKeys) ? 'Expand all' : 'Collapse all' }
          >
            <button
              type="button"
              style={{
                marginTop: '2.5005px',
                marginRight: '8px',
                backgroundColor: '#f5f5f5',
              }}
              className={ classNames('ant-table-row-expand-icon', {
                'ant-table-row-expand-icon-collapsed': _.isEmpty(this.state.expandedRowKeys),
                'ant-table-row-expand-icon-expanded': !_.isEmpty(this.state.expandedRowKeys),
              }) }
              onClick={ () => {
                if (_.isEmpty(this.state.expandedRowKeys)) {
                  this.setState({ expandedRowKeys: this.getFlatten(data).map((_data: any) => _data?.key) });
                } else {
                  this.setState({ expandedRowKeys: [] });
                }
              } }
            />
          </Tooltip>
        </>
      );
    }

    return label;
  };

  getDefaultColumnWidth = (columns: ListColumns[] = []) => {
    const { config } = this.props;
    const { tableWidth } = this.state;
    let columnsCount = 0;
    let defaultColumnsWidth = 0;

    columns.forEach((column: ListColumns) => {
      if (!!column?.width) {
        columnsCount ++;
        defaultColumnsWidth += column.width;
      }
    });

    const rowSelectionOffset = !!config?.bulk_operations ? 32 : 0; // Default rowSelection width is 32px

    return Math.floor((tableWidth - defaultColumnsWidth - rowSelectionOffset)/columnsCount);
  };

  recordColumnMapping = (config: Config | null | undefined, columns: ListColumns[] = [], data: any[]) => {
    const defaultColumnWidth: any = this.getDefaultColumnWidth(columns);
    return columns && columns.map((column: ListColumns, index: number) => {
      const alignment = (column: ListColumns) => {
        if (_.has(column, 'align')) {
          return column.align;
        }
        return ['number', 'integer', 'currency', 'area', 'sublist'].includes(column.type) ? 'right' : 'left';
      };
      return {
        key: column?.key || column?.id,
        dataIndex: column?.dataIndex || column?.key || column?.id,
        title: this.renderColumnTitle(column, data),
        options: _.has(column, 'options') ? column.options : [],
        type: column.type,
        fixed: _.has(column, 'fixed') && !!column?.fixed ? column?.fixed : false,
        align: alignment(column),
        width: column.width || defaultColumnWidth,
        filterable: _.has(column, 'filterable') ? column.filterable : false,
        sorter: _.has(column, 'sorter') ? column.sorter : false,
        ellipsis: _.has(column, 'ellipsis') ? column.ellipsis : false,
        render: (field: ListDataValues, row: any) => {

          // Custom render
          if (!!column.render) {
            return column.render(field, row);
          }

          if (_.isEmpty(field) || !_.has(field, 'type')) return <>-</>;

          const total = _.has(field, 'total') && field.total;
          const color = _.has(field, 'color') && field.color;

          switch (field.type) {
            case 'text':
            case 'string':
              return <span style={{ color: color || 'inherit' }}>{ _.has(field, 'value') && !!field.value ? field.value : '-' }</span>;
            case 'comparison':

              if (!_.has(field, 'old')) return <>-</>;

              return (
                <div
                  className="Editor Editor--disabled"
                  dangerouslySetInnerHTML={{ __html: diff(field.old || '', _.has(field, 'new') ? field.new : '') }} >
                </div>
              );

            case 'route':

              if (!_.has(field, 'value.title')) return <>-</>;

              if (!_.has(field, 'value.path') || !field.value.path) return <>{ field.value.title }</>;

              return (
                <RouterLink className='d-f primaryColor' to={ field.value.path }>
                  { field.value.title }
                </RouterLink>
              );
            case 'hyperlink':

              if (!_.has(field, 'value.title')) return <>-</>;

              if (!_.has(field, 'value.path') || !field.value.path) return <>{ field.value.title }</>;

              return (
                <a className='d-f primaryColor' href={ field.value.path }>
                  { field.value.title }
                </a>
              );
            case 'quick_links':
              const entries = Object.entries<{ count: number, new: boolean, path: string, description: string }>(field.value);
              const quickLinks = entries.map(([key, entity]) => {

                const hasValues = entity.count > 0;

                switch (key) {
                  case 'comments':
                    return (
                      <RouterLink key={ `${column.id}-${key}-${index}` } className='mR-25' to={ entity.path }>
                        <Tooltip
                          placement="top"
                          title={ !!entity.description ? entity.description : 'Comments' }
                        >
                          <AntBadge dot={ !!entity.new }>
                            <CommentOutlined className={ classNames('fsz-md op-50p primaryColor', { 'op-100p': hasValues }) } />
                          </AntBadge>
                        </Tooltip>
                      </RouterLink>
                    );

                  case 'resources':
                    return (
                      <RouterLink key={ `${column.id}-${key}-${index}` } className='mR-25' to={ entity.path }>
                        <Tooltip
                          placement="top"
                          title={ !!entity.description ? entity.description : 'Resources' }
                        >
                          <AntBadge dot={ !!entity.new }>
                            <UserOutlined className={ classNames('fsz-md op-50p primaryColor', { 'op-100p': hasValues }) } />
                          </AntBadge>
                        </Tooltip>
                      </RouterLink>
                    );

                  case 'documents':
                    return (
                      <RouterLink key={ `${column.id}-${key}-${index}` } className='mR-25' to={ entity.path }>
                        <Tooltip
                          placement="top"
                          title={ !!entity.description ? entity.description : 'Documents' }
                        >
                          <AntBadge dot={ !!entity.new }>
                            <FileOutlined className={ classNames('fsz-md op-50p primaryColor', { 'op-100p': hasValues }) } />
                          </AntBadge>
                        </Tooltip>
                      </RouterLink>
                    );
                  default:
                    return null;
                }

              });

              return <div className="d-f">{ quickLinks }</div>;
            case 'datetime':
              if (!field.value) {
                return '-';
              }
              if (_.has(field, 'format') && field.format === 'date') {
                return <div>{ getFormatedDate(field.value, undefined, false) }</div>;
              }
              return <div>{ getFormatedDate(field.value, undefined, true) }</div>;
            case 'workflow_stage':
              return <Badge type={ getBadgeType(field.value.context) } text={ _.startCase(_.toLower(field.value.title)).split('_').join(' ') } />;
            case 'number':
              return (
                <div className="ta-r">
                  { total ? (
                      <span>
                        <NumberFormat
                          { ...getNumberFormatProps() }
                          style={{ color: color || 'inherit' }}
                          value={ field.value }
                          prefix={ field.prefix ? `${field.prefix} ` : undefined }
                          suffix={ field.suffix ? ` ${field.suffix}` : undefined }
                          displayType={ 'text' }
                        />
                        <span> / </span>
                        <NumberFormat
                          { ...getNumberFormatProps() }
                          style={{ color: color || 'inherit' }}
                          value={ total }
                          prefix={ field.prefix ? `${field.prefix} ` : undefined }
                          suffix={ field.suffix ? ` ${field.suffix}` : undefined }
                          displayType={ 'text' }
                        />
                      </span>
                    ) : (
                      <NumberFormat
                      { ...getNumberFormatProps() }
                        style={{ color: color || 'inherit' }}
                        value={ field.value }
                        prefix={ field.prefix ? `${field.prefix} ` : undefined }
                        suffix={ field.suffix ? ` ${field.suffix}` : undefined }
                        displayType={ 'text' }
                      />
                    )
                  }
                </div>
              );
            case 'integer':
              return (
                <div className="ta-r">
                  { total ? (
                      <span style={{ color: color || 'inherit' }}>{ field.prefix ? `${field.prefix} ` : '' }{ field.value }{ field.suffix ? ` ${field.suffix}` : '' } / {`${total}`}</span>
                    ) : (
                      <span style={{ color: color || 'inherit' }}>{ field.prefix ? `${field.prefix} ` : '' }{ field.value }{ field.suffix ? ` ${field.suffix}` : '' }</span>
                    )
                  }
                </div>
              );
            case 'area':
              let areaValue = parseFloat(field.value);

              if (this.state.measurement) {
                const activeMeasurement = config && config.conversions.measurements.find((_measurement: MeasurementConversion) => _measurement.unit === this.state.measurement);
                if (activeMeasurement) {
                  const sourceUnit = config && config.conversions.measurements.find((_measurement: MeasurementConversion) => _measurement.unit === field.unit);
                  if (sourceUnit) {
                    areaValue = (areaValue / sourceUnit.rate) * activeMeasurement.rate;
                  }
                }
              }

              const areaFieldElement = (
                <NumberFormat
                  { ...getNumberFormatProps() }
                  value={ areaValue }
                  displayType={ 'text' }
                />
              );

              return (
                <div className="ta-r">
                  { _.has(field, 'path') && field.path ? (
                      <RouterLink className='primaryColor' to={ field.path }>
                        { areaFieldElement }
                      </RouterLink>
                    ) : (
                      areaFieldElement
                    )
                  }
                </div>
              );
            case 'currency':
              let currencyValue = parseFloat(field.value);

              if (this.state.currency) {
                const activeCurrency = config && config.conversions.currencies.find((_currency: CurrencyConversion) => _currency.code === this.state.currency);
                if (activeCurrency) {
                  const sourceCurrency = config && config.conversions.currencies.find((_currency: CurrencyConversion) => _currency.code === field.code);
                  if (sourceCurrency) {
                    currencyValue = (currencyValue / sourceCurrency.rate) * activeCurrency.rate;
                  }
                }
              }

              const currencyFieldElement = (
                <NumberFormat
                  style={{ color: color || 'inherit' }}
                  { ...getNumberFormatProps() }
                  value={ currencyValue }
                  displayType={ 'text' }
                />
              );

              return (
                <div className="ta-r">
                  { _.has(field, 'path') && field.path ? (
                      <RouterLink className='primaryColor' to={ field.path }>
                        { currencyFieldElement }
                      </RouterLink>
                    ) : (
                      currencyFieldElement
                    )
                  }
                </div>
              );
            case 'local_currency':
              const localCurrencyFieldElement = (
                <NumberFormat
                { ...getNumberFormatProps() }
                  style={{ color: color || 'inherit' }}
                  value={ parseFloat(field.value) }
                  displayType={ 'text' }
                />
              );

              return (
                <div className="ta-r">
                  { _.has(field, 'path') && field.path ? (
                      <RouterLink className='primaryColor' to={ field.path }>
                        { localCurrencyFieldElement }
                      </RouterLink>
                    ) : (
                      localCurrencyFieldElement
                    )
                  }
                </div>
              );
            case 'sublist':
              if (!field.value || _.isEmpty(field.value)) {
                return (
                  <div className="ta-r">-</div>
                );
              } else if (field.value.length === 1 && _.isEmpty(field.value[0].children)) {
                if (!_.has(field.value[0], 'path')) {
                  return <>{ total ? `${field.value[0]['title']} / ${total}` : field.value[0]['title'] }</>;
                }

                return (
                  <RouterLink className='primaryColor' to={ field.value[0]['path'] }>
                    { total ? `${field.value[0]['title']} / ${total}` : field.value[0]['title'] }
                  </RouterLink>
                );
              } else {
                const columns = [
                  {
                    key: 'title',
                    dataIndex: 'title',
                    title: 'Name',
                    render: (__: any, item: any) => {
                      if (!item?.path) {
                        return <>{ item?.title || '-' }</>;
                      };

                      return (
                        <RouterLink className='primaryColor' to={ item.path }>
                          { item.title }
                        </RouterLink>
                      );
                    },
                    sorter: true,
                    ellipsis: true,
                  }
                ];
                const dataSource = (data: any = this.state.sublistItems) => {
                  return data.map((item: any) => {
                    return {
                      'key': `${item.bundle}-${item.type}-${item.id}`,
                      'title': item.title,
                      'path': item.path,
                      'children': !_.isEmpty(item.children) ? dataSource(item.children) : null,
                    };
                  });
                };
                return (
                  <>
                    <Link onClick={ () => this.setState({ activeSublist: `${column.id}-${row.id}`, sublistItems: field.value }) }>
                      <div className="ta-r">
                        { this.getSublistLinkTitle(field) }
                      </div>
                    </Link>
                    { this.state.activeSublist === `${column.id}-${row.id}` &&
                      <Modal
                        centered
                        visible
                        title={ column.label || 'List' }
                        onCancel={ () => this.setState({ activeSublist: null, sublistItems: [] }) }
                        cancelText={ 'Close' }
                        style={{ maxHeight: '80vh', minWidth: 700 }}
                        okButtonProps={{ style: { display: 'none' } }}
                      >
                        <Table
                          className="ov-a"
                          style={{ height: 400 }}
                          size={ 'small' }
                          columns={ columns }
                          dataSource={ dataSource() }
                          pagination={ false }
                          expandable={{
                            defaultExpandAllRows: true
                          }}
                        />
                      </Modal>
                    }
                  </>
                );
              }
            case 'badge':
              let type = BadgeType.Default;
              if (_.has(field, 'color')) {
                switch (_.toUpper(field.color)) {
                  case 'GREEN':
                    type = BadgeType.Success;
                  break;
                  case 'AMBER':
                    type = BadgeType.Warning;
                  break;
                  case 'RED':
                    type = BadgeType.Danger;
                  break;
                }
              }

              if (field.text === null) {
                return <span>-</span>;
              }

              return (
                <Badge type={ type } text={ field.text } size={ field.size } />
              );
            case 'progress':

              if (!_.has(field, 'total') || !field.total) {
                return <>-</>;
              }

              return (
                <Progress total={ field.total } value={ field.value } />
              );
            case 'nested':
              if (!_.has(field, 'title') || !field.title) {
                return <>-</>;
              }

              return <>{ field.title  }</>;
          }
        },
      };
    });
  };

  handleSort = (pagination: any, filters: any, sorter: any, extra: any) => {
    this.setState({
      sorter: sorter
    });
  };

  paginageItems = (items: any[], currentPage = 1, itemsPerPage = 25) => {
    return _.drop(items, (currentPage - 1) * itemsPerPage).slice(0, itemsPerPage);
  };

  sortData = (items: any[], sorter: any) => {
    const field = sorter.field;
    const order = sorter.order === 'descend' ? 'desc' : 'asc';
    const metaitem = items.find((item: any) => !!item[field]);
    const metafield = metaitem?.[field];

    if (metafield && typeof metafield === 'object') {
      switch (metafield.type) {
        case 'number':
        case 'area':
        case 'currency':
          return _.orderBy(items, item => _.has(item, `${field}.value`) ? parseFloat(item[field]['value']) : -1, order);
        case 'string':
        case 'integer':
          return _.orderBy(items, `${field}.value`, order);
        case 'route':
        case 'workflow_stage':
          return _.orderBy(items, `${field}.value.title`, order);
        case 'nested':
          return _.orderBy(items, `${field}.title`, order);
        case 'sublist':
          return items.sort((a: any, b: any) => {
            let aValue: any = '';
            let bValue: any = '';

            if (_.has(a[field], 'value')) {
              if (a[field].value.length === 1 && _.has(a[field], 'value[0].title') && !_.has(a[field], 'value_total')) {
                aValue = a[field].value[0].title;
              } else {
                aValue = this.totalSublistItems(a[field].value);
              }
            }

            if (_.has(b[field], 'value')) {
              if (b[field].value.length === 1 && _.has(b[field], 'value[0].title') && !_.has(b[field], 'value_total')) {
                bValue = b[field].value[0].title;
              } else {
                bValue = this.totalSublistItems(b[field].value);
              }
            }

            if (order === 'desc') {
              if (isNumeric(aValue) && isNumeric(bValue)) {
                return bValue - aValue;
              }
              return String(bValue) > String(aValue) ? 1 : -1;
            } else {
              if (isNumeric(aValue) && isNumeric(bValue)) {
                return aValue - bValue;
              }
              return String(bValue) > String(aValue) ? -1 : 1;
            }
          });
        case 'badge':
          return _.orderBy(items, `${field}.text`, order);
        case 'progress':
          return items.sort((a, b) => {
            if (order === 'desc') {
              return calculatePercentage(b[field].total, b[field].value) - calculatePercentage(a[field].total, a[field].value);
            } else {
              return calculatePercentage(a[field].total, a[field].value) - calculatePercentage(b[field].total, b[field].value);
            }
          });
      }
    }

    return _.orderBy(items, field, order);
  };

  quickFilter = (items: any[], value: string) => {
    return items.filter((item: any) => {
      return Object.keys(item).some((columnKey: string) => {
        if (_.has(item, `${columnKey}.type`) && ['string', 'route'].includes(item[columnKey]['type'])) {
          switch (item[columnKey]['type']) {
            case 'string':
              return !!item[columnKey]['value'] && item[columnKey]['value'].toLowerCase().includes(value.toLowerCase());
            case 'route':
              return _.has(item, `${columnKey}.value.title`) && item[columnKey]['value']['title'].toLowerCase().includes(value.toLowerCase());
            default:
              return false;
          }
        }
        return false;
      });
    });
  };

  filterDefaultValues = () => {
    let newFilters = _.cloneDeep(this.state.filters);
    this.props.filters.forEach((filter :any) => {
      let options = _.has(filter, 'options') ? filter.options : [];
      const preselected = options.filter((option: any) =>  _.has(option, 'preselect') && !!option.preselect);

      if (!!preselected) {
        switch (filter.type) {
          case 'daterange':
            const defaultDate = !_.isEmpty(preselected) ? preselected[0].id : 0;
            if (defaultDate !== 'all') {
              const now = moment();
              newFilters = Object.assign({}, newFilters, { [filter.id]: { ...this.state.filters[filter.id], ['startDate']: now, ['endDate']: moment().add(defaultDate, 'days'), ['preset']: defaultDate } });
            }
            break;

          case 'multiselect':
            const defaultOptions: any = [];
            if (!_.isEmpty(preselected)) {
              preselected.forEach((option: any) => {
                defaultOptions.push(option.id);
              });
              newFilters = Object.assign({}, newFilters, { [filter.id]: defaultOptions });
            }
            break;
        }

        this.setState({
          filters: newFilters,
          showFilter: true
        });
      }
    });
  };

  filter = async (filters: any, currency: string | null, measurement: string | null) => {
    this.setState({ isLoading: true, filters:  _.cloneDeep(filters), activeFilters: _.cloneDeep(filters) }, () => {
      this.props.onFilter(filters, currency, measurement, (successful: boolean) => {
        if (!successful) {
          console.error('Failed');
        }
        this.setState({ isLoading: false });
      });
    });
  };

  renderSelectFilter = (filter: any, selectMode: "multiple" | "tags" | undefined = undefined) => {
    let options = _.has(filter, 'options') ? filter.options : [];

    if (_.has(filter, 'has_dependency') && !!filter.has_dependency) {
      options = options.filter((option: any) => {
        let visible = false;
        if (_.has(this.state.filters, `${option.config.key}`)) {
            this.state.filters[option.config.key].forEach((id: number) => {
              if (option.config.ids.includes(id)) {
                visible = true;
              }
            });
        } else {
          visible = true;
        }
        return visible;
      });
    }

    return (
      <Select
        key={ `${filter.label}-${filter.id}` }
        showSearch
        mode={ selectMode }
        className="m-5"
        style={{ width: 200 }}
        placeholder={ filter.label }
        dropdownMatchSelectWidth={ false }
        filterOption={ (input: any, option: any) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 }
        onChange={(value: any) => {
          let newFilters = this.state.filters;
          if (!value.length) {
            delete newFilters[filter.id];
          } else {
            newFilters = Object.assign({}, this.state.filters, { [filter.id]: value });
          }
          this.setState({
            filters: newFilters,
            currentPage: 1,
          });
        }}
        value={ this.state.filters[filter.id] ? this.state.filters[filter.id] : [] }
        // @ts-ignore
        autoComplete="none"
      >
        { options.map((option: any) => (
          <Option key={ option.id } value={ option.id }>
            { option.title }
          </Option>
        )) }
      </Select>
    );
  };

  renderNestedFilter = (filter: any) => {

    const nestedSet = (data: any = []) => {
      return !_.isEmpty(data) && data.map((entity: any) => {
        const appendChildrenKeys = (children: any) => {

          // Prevent nesting
          if (_.isEmpty(children)) return null;

          return children.map((childEntity: any) => {
            return {
              'key': childEntity.id,
              'id': childEntity.id,
              'value': childEntity.id,
              'title': childEntity.title,
              'children': appendChildrenKeys(childEntity.children),
            };
          });
        };

        return {
          'key': entity.id,
          'id': entity.id,
          'value': entity.id,
          'title': entity.title,
          'children': appendChildrenKeys(entity.children),
        };
      });
    };

    return (
      <TreeSelect
        key={ `${filter.label}-${filter.id}` }
        className="m-5"
        style={{ width: 200 }}
        dropdownMatchSelectWidth={ false }
        placeholder={ filter.label }
        showCheckedStrategy={ SHOW_PARENT }
        maxTagCount={ 5 }
        treeDefaultExpandedKeys={ filter.options.length > 1 ? [] : [filter.options[0].id] }
        treeCheckable
        multiple
        value={ this.state.filters[filter.id] ? this.state.filters[filter.id] : [] }
        treeData={ nestedSet(filter.options) }
        filterTreeNode={ (input: string, option: any) => {
          if (option) {
            const filteredInput = input.toLocaleLowerCase();
            const title = option.title && option.title.toLowerCase();

            if (title.includes(filteredInput)) {
              return true;
            }
          }

          return false;
        } }
        onChange={(value: any) => {
          let newFilters = this.state.filters;
          if (!value.length) {
            delete newFilters[filter.id];
          } else {
            newFilters = Object.assign({}, this.state.filters, { [filter.id]: value });
          }
          this.setState({
            filters: newFilters,
            currentPage: 1,
          });
        }}
      />
    );
  };

  renderFilterDateRangePicker = (filter: any) => {
    let options = _.has(filter, 'options') ? filter.options : [];
    const preselected = options.filter((option: any) =>  _.has(option, 'preselect') && !!option.preselect);

    const defaultValue = !_.isEmpty(preselected) ? preselected[0].id : 0;

    const changeDate = (value: moment.Moment | null, datePickerKey: 'startDate' | 'endDate') => {
      let newFilters = _.cloneDeep(this.state.filters);
      if (!value) {
        if (Object.keys(newFilters[filter.id]).length > 1) {
          delete newFilters[filter.id]?.[datePickerKey];
        } else {
          delete newFilters[filter.id];
        }
      } else {
        newFilters = Object.assign({}, this.state.filters, { [filter.id]: { ...this.state.filters[filter.id], [datePickerKey]: value, ['preset']: 0 } });
      }
      this.setState({
        filters: newFilters,
      });
    };

    return (
      <Tooltip
        key={ `${filter.label}-${filter.id}` }
        title={ `${filter.label}` }
        className={ 'm-5' }
      >
        <Select
          placeholder={ filter.label }
          dropdownMatchSelectWidth={ false }
          defaultValue={ defaultValue }
          value={ this.state.filters?.[filter.id]?.preset }
          onChange={(value) => {
              const now = moment();
              const startDate = value === 'all' ? null : now;
              const addDays = value === 'all' ? 0 : value;
              const endDate = value === 'all' ? null : moment().add(addDays, 'days');

              let newFilters = _.cloneDeep(this.state.filters);
              newFilters = Object.assign({}, this.state.filters, { [filter.id]: { ...this.state.filters[filter.id], ['startDate']: startDate, ['endDate']: endDate, ['preset']: value } });
              this.setState({
                filters: newFilters,
              });
          }}
          // @ts-ignore
          autoComplete="none"
        >
          { options.map((option: any) => (
            <Option key={ option.id } value={ option.id }>
              { option.title }
            </Option>
        ))}
        </Select>
        <DatePicker
          placeholder={ 'Select start date' }
          format={ getUserSetting('date_format') }
          value={ this.state.filters?.[filter.id]?.startDate }
          disabledDate={ date => this.state.filters?.[filter.id]?.endDate && date.isAfter(this.state.filters?.[filter.id]?.endDate) }
          onChange={ (value) => changeDate(value, 'startDate') }
        />
        <DatePicker
          placeholder={ 'Select end date' }
          format={ getUserSetting('date_format') }
          value={ this.state.filters?.[filter.id]?.endDate }
          disabledDate={ date => this.state.filters?.[filter.id]?.startDate && date.isBefore(this.state.filters?.[filter.id]?.startDate) }
          onChange={ (value) => changeDate(value, 'endDate') }
        />
      </Tooltip>
    );
  };

  renderFilters = (filters: any) => {
    return (
      <div style={{ marginBottom: 10 }}>
        { filters
          .map((filter: any, index: number) => {
            switch (filter.type) {
              case 'select':
                return this.renderSelectFilter(filter);
              case 'multiselect':
                return this.renderSelectFilter(filter, 'multiple');
              case 'nested':
                return this.renderNestedFilter(filter);
              case 'daterange':
                return this.renderFilterDateRangePicker(filter);
              default:
                return <React.Fragment key={ index }></React.Fragment>;
            }
          })
        }
        <Button
          type='primary'
          className="m-5"
          onClick={ () => this.filter(this.state.filters, this.state.currency, this.state.measurement) }
          disabled={ this.state.isLoading }
        >
          { 'Apply Filters' }
        </Button>
      </div>
    );
  };

  calculateColumn = (column: any) => {
    const { items } = this.state;
    return items.reduce((acc: any, row: any) => {
      const value = row[column.id]?.value || '0';
      return parseFloat(acc) + parseFloat(value);
    }, 0);
  };

  getSublistLinkTitle = (field: ListDataValues) => {
    if (_.has(field, 'value_total')) {
      return field.value_total;
    }

    return !!field?.total ? `${this.totalSublistItems(field.value)} / ${field.total}` : this.totalSublistItems(field.value);
  };

  renderSummary = () => {

    if (!this.shouldShowSummary()) {
      return null;
    }

    const numberFormat = getNumberFormatProps();

    return (
      <Table.Summary fixed>
        <Table.Summary.Row>
          { this.state.columns.map((column: any, index: number) => {
            return (
              <Table.Summary.Cell className="ta-r" key={ index } index={ index } colSpan={ 1 }>
                <Text className="ta-r fw-600">
                  { !!column?.footer_total &&
                    <NumberFormat
                      { ...numberFormat }
                      fixedDecimalScale={ 2 }
                      decimalScale={ 2 }
                      displayType={ 'text' }
                      value={ this.calculateColumn(column) }
                    />
                  }
                </Text>
              </Table.Summary.Cell>
            );
          }) }
        </Table.Summary.Row>
      </Table.Summary>
    );
  };

  renderBulkOperations = (bulkOperations: BulkOperation[], entities: any[]) => {
    const bulkActions: DropdownAction[] = [
      {
        node: '',
        onClick: () => {}
      }
    ];

    bulkOperations.forEach((operation: BulkOperation) => {
      bulkActions.push({
        node: operation.title,
        onClick: () => this.setState({ bulkOperation: operation.reference }),
        isLoading: false,
        disabled: _.isEmpty(entities) ? ['No rows selected'] : false
      });
    });

    return (
      <span>
        <Dropdown actions={ bulkActions } />
      </span>
    );
  };

  bulkOperationOnSuccess = (responseData: any) => {
    const { items } = this.state;
    const newListItems = items.map((item: any) => responseData.find((responseItem: any) => responseItem.relation_id === item.relation_id) ? responseData.find((responseItem: any) => responseItem.relation_id === item.relation_id) : item);

    this.setState({ items: newListItems });
  };

  renderBulkOperationModal = (bulkOperationRef: string, bulkOperations: BulkOperation[]) => {
    const bulkOperation: BulkOperation | undefined = bulkOperations.find((_bulkOperation: BulkOperation) => _bulkOperation.reference === bulkOperationRef);
    const { selectedRowKeys } = this.state;
    const { clientId, items } = this.props;

    if (bulkOperation) {
      const selectedEntities: any[] = [];

      selectedRowKeys.forEach((_selectedKey: any) => {
        const item = items.find((_item: any) => _item.id === _selectedKey);
        if (item) {
          selectedEntities.push({
            id: item.id,
            type: item.type,
            bundle: item.bundle
          });
        }
      });

      // Add bespoke component if a complex form logic needed. Default to
      switch (bulkOperation.reference) {
        case 'add-resource':
          return (
            <AddResourceModal
              clientId = { clientId }
              runEndpoint={ `client/${clientId}/bulk-operations/${bulkOperation.reference}/run` }
              checkEndpoint={ `client/${clientId}/bulk-operations/${bulkOperation.reference}/check` }
              entities={ selectedEntities }
              skipNotifications={ bulkOperation?.skip_notifications }
              onClose={ () => this.setState({ bulkOperation: null }) }
              onSuccess={ this.bulkOperationOnSuccess }
            />
          );

        default:
          return (
            <BulkAssignModal
              runEndpoint={ `client/${clientId}/bulk-operations/${bulkOperation.reference}/run` }
              checkEndpoint={ `client/${clientId}/bulk-operations/${bulkOperation.reference}/check` }
              elements={ bulkOperation.elements }
              entities={ selectedEntities }
              skipNotifications={ bulkOperation?.skip_notifications }
              onClose={ () => this.setState({ bulkOperation: null }) }
              onSuccess={ this.bulkOperationOnSuccess }
            />
          );
      }
    }
  };

  render = () => {
    const {
      size,
      config,
      isFetching,
    } = this.props;
    const {
      currentPage,
      itemsPerPage,
      showFilter,
      sorter,
      quickFilter,
      filters,
      columns,
      items,
      tableHeight,
      tableWidth,
      isLoading,
      currency,
      measurement,
      expandedRowKeys,
      selectedRowKeys,
      bulkOperation
    } = this.state;

    let rightActions = this.props.rightActions ? this.props.rightActions : [];
    let _data = items || [];

    // Quickfilter
    if (quickFilter) {
      _data = this.quickFilter(_data, quickFilter);
    }

    // Sort data
    if (_data && sorter) {
      _data = this.sortData(_data, sorter);
    }

    let _columns: any = this.recordColumnMapping(config, columns, _data);

    const paginatedData = this.paginageItems(_data || [], currentPage, itemsPerPage);

    // Hacky solution to update export buttons isLoading state.
    rightActions = rightActions.filter((action: DropdownAction) => action.node !== 'Export');
    rightActions.push({
      disabled: _.isEmpty(_data) ? ['Nothing to export'] : false,
      isLoading: this.state.isExporting,
      node: 'Export',
      onClick: () => {
        this.setState({ isExporting: true }, () => {
          this.props.onExport(filters, currency, measurement, (successful: boolean) => {
            if (!successful) {
              console.error('Failed');
            }
            this.setState({ isExporting: false });
          });
        });
      }
    });

    let rowSelection = null;
    if (!!config?.bulk_operations) {
      rowSelection = {
        fixed: true,
        checkStrictly: false,
        selectedRowKeys: selectedRowKeys,
        onChange: (selectedRowKeys: any) => {
          this.setState({selectedRowKeys: selectedRowKeys});
        }
      };
    }

    return (
      <>
        <div id="AdvancedListFilter" ref={ node => (this.component = node) }>
          <div className="d-f jc-sb ai-c mB-20 mT-5">
            <div className="d-if">
              <Search
                disabled={ _.isEmpty(_columns) }
                placeholder={ 'Quick Search' }
                allowClear
                style={{ width: 300 }}
                onBlur={ event => {
                  this.setState({
                    quickFilter: event.target.value || null,
                    currentPage: 1,
                  });
                }}
                onSearch={ value => {
                  this.setState({
                    quickFilter: value || null,
                    currentPage: 1,
                  });
                }}
              />
              { config && _.has(config, 'conversions.currencies') && !_.isEmpty(config.conversions.currencies) &&
                <Select
                  style={{ width: 200 }}
                  className="Select-Field mL-10"
                  onChange={ (code: string) => {
                    this.setState({
                      currency: code
                    }, () => {
                      this.filter(this.state.filters, code, this.state.measurement);
                    });
                  } }
                  value={ this.state.currency ? this.state.currency : undefined }
                  placeholder={ 'Currency Conversion' }
                  disabled={ false }
                >
                  { config.conversions.currencies.map((currency: CurrencyConversion, index: number) => {
                    return (
                      <Option
                        key={ index }
                        value={ currency.code }
                      >
                        { `${currency.title} (${currency.code})` }
                      </Option>
                    );
                  }) }
                </Select>
              }
              { config && _.has(config, 'conversions.measurements') && !_.isEmpty(config.conversions.measurements) &&
                <Select
                  style={{ width: 200 }}
                  className="Select-Field mL-10"
                  onChange={ (unit: string) => {
                    this.setState({
                      measurement: unit
                    }, () => {
                      this.filter(this.state.filters, this.state.currency, unit);
                    });
                  } }
                  value={ this.state.measurement ? this.state.measurement : undefined }
                  placeholder={ 'Measurement Conversion' }
                  disabled={ false }
                >
                  { config.conversions.measurements.map((measurement: MeasurementConversion, index: number) => {
                    return (
                      <Option
                        key={ index }
                        value={ measurement.unit }
                      >
                        { `${measurement.title} (${measurement.suffix ? measurement.suffix : '' })` }
                      </Option>
                    );
                  }) }
                </Select>
              }
            </div>
            <div className="d-if">
              { !_.isEmpty(rightActions) && <Dropdown actions={ rightActions } /> }
              { this.renderBulkOperations(!!config?.bulk_operations ? config.bulk_operations : [], selectedRowKeys) }
            </div>
          </div>
          <div
            className="d-f jc-sb ai-c mB-10"
            style={{ userSelect: 'none' }}
          >
            <div className="d-if mL-10">
              <span>{ 'Show' }</span>
              <span className="mL-10 mR-10">
                <Select
                  disabled={ (_.isEmpty(_columns) || _.isEmpty(_data)) }
                  size={ 'small' }
                  onChange={ (value: number) => {
                    this.setState({
                      currentPage: 1,
                      itemsPerPage: value
                    });
                  } }
                  defaultValue={ itemsPerPage }
                >
                  <Option value={ 25 }>25</Option>
                  <Option value={ 50 }>50</Option>
                  <Option value={ 100 }>100</Option>
                </Select>
              </span>
              <span>Entries of <b>{_data.length || 0 }</b></span>
              <span
                className={ classNames('mL-30', {
                  'link': !_.isEmpty(this.props.filters),
                  'text-ant-disabled disabled': _.isEmpty(this.props.filters) || _.isEmpty(_columns),
                  'active': showFilter || !_.isEmpty(filters)
                }) }
                onClick={ () => {
                  this.setState({
                    showFilter: !showFilter
                  });
                } }
              >
                <Icon component={ FilterIcon } />
                <span>Filter</span>
              </span>
              { !_.isEmpty(this.state.filters) &&
                <span
                  className="mL-10"
                  onClick={ () => !_.isEmpty(this.state.filters) && this.filter({}, null, null) }
                >
                  <CloseCircleOutlined className="primaryColor cur-p mL-5" height={ 20 } width={ 20 } />
                  <span className='primaryColor link mL-5'>Reset</span>
                </span>
              }
            </div>
            <div className="d-if">
              <Pagination
                disabled={ _data.length === 0 }
                showSizeChanger={ false }
                current={ currentPage }
                total={ _data.length }
                pageSize={ itemsPerPage }
                onChange={ page => {
                  this.setState({
                    currentPage: page
                  });
                } }
              />
            </div>
          </div>
          <div>
            { showFilter && this.renderFilters(this.props.filters) }
          </div>
        </div>
        <div className='Layout-box'>
          <Table
            className="AdvancedList"
            sticky
            size={ size ? size : 'small' }
            columns={ _columns }
            showSorterTooltip={ false }
            expandable={{
              expandedRowKeys: expandedRowKeys,
              onExpand: (expanded: boolean, row: any) => {
                if (expanded) {
                  this.setState({ expandedRowKeys: [...expandedRowKeys, row.key] });
                } else {
                  this.setState({ expandedRowKeys: !_.isEmpty(expandedRowKeys) ? expandedRowKeys.filter((rowKey) => rowKey !== row.key) : expandedRowKeys });
                }
              },
            }}
            dataSource={ paginatedData.map((row: ListData, index: number) => ({ key: index, ...row })) }
            onChange={ this.handleSort }
            pagination={ false }
            loading={ isLoading || isFetching }
            rowClassName='va-tp'
            scroll={{
              x: tableWidth || _columns.length * 200,
              y: tableHeight,
            }}
            summary={ () => this.renderSummary() }
            rowSelection={ rowSelection ? rowSelection : undefined }
          />
        </div>
        { bulkOperation && this.renderBulkOperationModal(bulkOperation, !!config?.bulk_operations ? config.bulk_operations : []) }
      </>
    );
  };
};

export default AdvancedListView;
