// Libs
import React from 'react';
import classNames from 'classnames';
import { v4 as uuidv4 } from 'uuid';
import _ from 'lodash';

// Components
import BlockingSpinner from 'components/blocking-spinner';
import Field from 'components/form/field/dynamic/common/Field';
import { Button, Form, Input, InputNumber, Modal, Select, Tooltip, Switch, Tree, Badge } from 'antd';
import Dropdown from 'components/dropdown';

// Icons
import { QuestionCircleOutlined, DownOutlined, ApartmentOutlined } from '@ant-design/icons';

// Interfaces
import {
  IDynamicFieldTemplate,
  IDynamicFieldTemplateComponent,
  IDynamicFieldTemplateSet,
  ComponentType,
  ComponentDependency
} from 'views/admin/templates/Templates.interfaces';
import {
  SelectListItem,
  SelectOptionItem,
  SliderRange
} from 'components/form/field/dynamic-field-template/DynamicFieldTemplate.interface';

// Styles
import 'views/admin/templates/dynamic-field/DynamicFieldComponentList.scss';

const { TextArea } = Input;

const nestedSet = (data: any = []) => {
  return data.map((entity: any) => {
    const appendChildrenKeys = (children: any) => {

      // Prevent nesting
      if (_.isEmpty(children)) return [];

      return children.map((childEntity: any) => {
        return {
          ...childEntity,
          'key': childEntity.id || childEntity.key || uuidv4(),
          'id': childEntity.id,
          'value': childEntity.id,
          'title': childEntity.label,
          'children': appendChildrenKeys(childEntity.children),
        };
      });
    };

    return {
      ...entity,
      'key': entity.id || entity.key || uuidv4(),
      'id': entity.id,
      'value': entity.id,
      'title': entity.label,
      'children': appendChildrenKeys(entity.children),
    };
  });
};

const onDrop = (info: any, treeData: any, nestable: boolean) => {
  const dropKey = info.node.key;
  const dragKey = info.dragNode.key;
  const dropPos = info.node.pos.split('-');
  const dropPosition = info.dropPosition - Number(dropPos[dropPos.length - 1]);
  const data = [...treeData];
  let dragObj: any;

  const loop = (data: any, key: any, callback: any) => {
    for (let i = 0; i < data.length; i++) {
      if (data[i].key === key) {
        return callback(data[i], i, data);
      }
      if (data[i].children) {
        loop(data[i].children, key, callback);
      }
    }
  };

  // Find dragObject
  loop(data, dragKey, (item: any, index: any, arr: any) => {
    arr.splice(index, 1);
    dragObj = {
      ...item,
      parent_component_id: null,
    };
  });

  if (!info.dropToGap && nestable) {
    // Drop on the content
    loop(data, dropKey, (item: any) => {
      item.children = item.children || [];
      return item.children.unshift({
        ...dragObj,
        parent_component_id: item.id,
      });
    });
  } else if (
    (info.node.props.children || []).length > 0 && // Has children
    info.node.props.expanded && // Is expanded
    dropPosition === 1 // On the bottom gap
  ) {
    loop(data, dropKey, (item: any) => {
      item.children = item.children || [];
      return item.children.unshift({
        ...dragObj,
        parent_component_id: item.id
      });
    });
  } else {
    let ar: any;
    let i: any;
    loop(data, dropKey, (_: any, index: any, arr: any) => {
      ar = arr;
      i = index;
    });
    if (dropPosition === -1) {
      ar.splice(i, 0, dragObj);
    } else {
      ar.splice(i + 1, 0, dragObj);
    }
  }

  return data;
};

const getRootComponent = (component: IDynamicFieldTemplateComponent, setComponents: IDynamicFieldTemplateComponent[]): IDynamicFieldTemplateComponent|null => {
  for (let i = 0; i < setComponents.length; i++) {
    const _component = setComponents[i];

    if (_.isEmpty(_component.children)) {
      continue;
    }

    const foundComponent = componentBelongsToSet(component, _component);
    if (foundComponent !== null) {
      return foundComponent;
    }
  }

  return null;
};

const componentBelongsToSet = (component: IDynamicFieldTemplateComponent, parentComponent: IDynamicFieldTemplateComponent): IDynamicFieldTemplateComponent|null => {

  if (parentComponent.children != null && parentComponent.children.length > 0) {

    const childrenComponents = parentComponent.children;
    for (let i = 0; i < childrenComponents.length; i++) {
      const _component = childrenComponents[i];

      if (component.key !== null && _component.key !== null && (component.key === _component.key)) {
        return parentComponent;
      } else if (component.id !== null && _component.id !== null && (component.id === _component.id)) {
        return parentComponent;
      }

      let foundComponent = componentBelongsToSet(component, _component);
      if (foundComponent !== null) {
        return foundComponent;
      }
    }
  }

  return null;
};

interface Props {
  clientId: number;
  state: any;
  setState: (state: any, callback?: () => void) => void;
  selectedSetId: number | string | undefined;
  template: IDynamicFieldTemplate;
};

interface State {
  deleteComponentId: number | null;
  activeComponent: IDynamicFieldTemplateComponent | null;
  activeParentComponent: IDynamicFieldTemplateComponent | null;
  activeComponentDependencies: ComponentDependency[] | null;
  showEditComponentModal: boolean;
  showDeleteComponentDialog: boolean;
  showDependencyModal: boolean;
  showMoveModal: boolean;
  newComponentSet: any;
  isCreatingComponent: boolean;
  isDeletingComponent: boolean;
  isFetchingPreviewList: boolean;
  componentPlaceholder: Partial<IDynamicFieldTemplateComponent> | null;
};

class DynamicFieldComponentList extends React.Component<Props, State> {

  mounted: boolean = false;

  state: State = {
    deleteComponentId: null,
    activeComponent: null,
    activeParentComponent: null,
    activeComponentDependencies: null,
    showEditComponentModal: false,
    showDeleteComponentDialog: false,
    isFetchingPreviewList: false,
    showDependencyModal: false,
    showMoveModal: false,
    newComponentSet: null,
    isCreatingComponent: false,
    isDeletingComponent: false,
    componentPlaceholder: null,
  };

  setTemplate = (template: IDynamicFieldTemplate, callback?: () => void) => {
    this.props.setState({
      ...this.props.state,
      modifiedTemplate: template
    }, callback);
  };

  handleDrop = (selectedSetId: number | string | undefined, template: IDynamicFieldTemplate, componentTree: any, tree: any) => {
    if (selectedSetId) {
      this.setTemplate(this.modifySet(selectedSetId, template, onDrop(tree, componentTree, true)));
    }
  };

  modifySet = (setId: number | string | undefined, template: IDynamicFieldTemplate, components: IDynamicFieldTemplateComponent[]): IDynamicFieldTemplate => {
    const index = template.sets.findIndex((set: IDynamicFieldTemplateSet) => set.id === setId || set.key === setId);
    return {
      ...template,
      sets: _.set(_.cloneDeep(template.sets), index, {
        ...template.sets[index],
        components: components
      })
    };
  };

  getSet = (template: IDynamicFieldTemplate, setId: number | string): IDynamicFieldTemplateSet | undefined => {
    return template.sets.find((set: IDynamicFieldTemplateSet) => set.id === setId || set.key === setId);
  };

  getComponentTypeLabel = (componentType: string, componentTypes: ComponentType[] | undefined) => {
    const type = componentTypes && componentTypes.find((_type: ComponentType) => _type.id === componentType);
    if (type) {
      return type.title;
    }

    return componentType;
  };

  modifyComponent = (componentId: number | string | undefined, components: IDynamicFieldTemplateComponent[], component: Partial<IDynamicFieldTemplateComponent>): IDynamicFieldTemplateComponent[] => {
    return components.map((_component: IDynamicFieldTemplateComponent) => {
      const appendChildrenKeys = (children: IDynamicFieldTemplateComponent[], _identifier: number | string | undefined): IDynamicFieldTemplateComponent[] => {
        return children.map((child: any) => {

          if (child.id === _identifier || child.key === _identifier) {
            child = {
              ...child,
              ...component
            };
          }

          return {
            ...child,
            'children': !_.isEmpty(child.children) && child.children ? appendChildrenKeys(child.children, _identifier) : [],
          };
        });
      };

      if (_component.id === componentId || _component.key === componentId) {
        _component = {
          ..._component,
          ...component
        };
      }

      return {
        ..._component,
        'children': !_.isEmpty(_component.children) && _component.children ? appendChildrenKeys(_component.children, componentId) : [],
      };
    });
  };

  deleteDeepValues = (identifier: number | string, values: any) => {
    return values
      .filter((value: any) => (value.id !== identifier && value.key !== identifier))
      .map((value: any) => {
        const appendChildrenKeys = (children: any, _identifier: number | string) => {
          return children
            .filter((childEntity: any) => childEntity.id !== _identifier && childEntity.key !== _identifier)
            .map((childEntity: any) => {
              return {
                ...childEntity,
                'children': !_.isEmpty(children) ? appendChildrenKeys(childEntity.children, _identifier) : [],
              };
            });
        };

        return {
          ...value,
          'children': !_.isEmpty(value.children) ? appendChildrenKeys(value.children, identifier) : [],
        };
    });
  };

  duplicateComponent = (template: IDynamicFieldTemplate, set: IDynamicFieldTemplateSet, component: IDynamicFieldTemplateComponent, callback: (newTemplate: IDynamicFieldTemplate) => void = () => {} ) => {
    const newComponent = {
      ...component,
      id: null,
      key: uuidv4(),
      parent_component_id: null,
    };

    if (_.has(newComponent, 'dependencies') && !_.isEmpty(newComponent.dependencies)) {
      newComponent.dependencies.forEach((dependency: any) => {
        dependency.component_id = null;
      });
    }

    if (_.has(newComponent, 'children') && !_.isEmpty(newComponent.children)) {
      const check = (newComponent: any, parent: any) => {
        newComponent.children.forEach((newChildComponent: any) => {
          newChildComponent.id = null;
          newChildComponent.key = uuidv4();
          newChildComponent.parent_component_id = parent;

          if (_.has(newChildComponent, 'dependencies') && !_.isEmpty(newChildComponent.dependencies)) {
            newChildComponent.dependencies.forEach((dependency: any) => {
              dependency.component_id = null;
            });
          }

          check(newChildComponent, newChildComponent.parent_component_id);
        });
      };
      check(component, component.parent_component_id);
    }

    const modifiedTemplateState = this.modifySet(set.id || set.key, template, set.components.concat(
      newComponent
    ));

    this.props.setState({
      ...this.props.state,
      modifiedTemplate: modifiedTemplateState
    }, () => callback(modifiedTemplateState) );
  };

  onDeleteComponent = (template: IDynamicFieldTemplate, set: IDynamicFieldTemplateSet, componentId: number | string) => {
    set && this.setTemplate(this.modifySet(set.id || set.key, template, this.deleteDeepValues(componentId, set.components)), () => {
      this.setState({
        deleteComponentId: null,
        showDeleteComponentDialog: false,
      });
    });
  };

  renderComponentPreview = (type: string | null | undefined, component: any) => {
    const mockData: any = {
      'type': type,
      'label': component?.label || 'Question',
      'config': {
        'required': false,
        'cardinality': 0,
        'locked': false,
        'allow_comment': false,
        'allow_attachments': false,
        'allow_scoring': false,
        'show_label': false,
        'scoring': null,
        ...component?.config,
      }
    };

    if (type === 'select' || type === 'slider') {
      if (component?.config?.list) {
        mockData.options = this.props.state.selectLists.find((list: any) => list.id === component?.config?.list).options;
      } else if (component?.config?.range) {
        mockData.options = this.props.state.sliderRanges.find((list: any) => list.id === component?.config?.range).options;
      }
    }

    return (
      <div className="w-100p">
        <div className="bd p-10 h-100p">
          <div className="ta-c mB-15">
            <h3>Preview</h3>
          </div>
          <Field
            clientId={ this.props.clientId }
            dynamicField={ mockData }
            fieldErrorMessages={ [] }
            fieldModifiedMessages={ [] }
            isDisabled={ false }
            isPreviewing
            setFieldModifiedMessage={ () => {} }
            setFieldErrorMessage={ () => {} }
            onChange={ () => {} }
            onComment={ () => {} }
            onUpload={ () => {} }
            onRemove={ () => {} }
            onCreateAction={ () => {} }
            onScore={ () => {} }
          />
        </div>
      </div>
    );
  };

  renderCreateComponentModal = (template: IDynamicFieldTemplate, set: IDynamicFieldTemplateSet) => {
    const { isCreatingComponent, componentPlaceholder } = this.state;
    const { sliderRanges, selectLists } = this.props.state;
    const extensions = _.has(template, 'extensions') ? template.extensions : [];
    const maxFileSize = _.has(template, 'max_file_size') ? template.max_file_size : 50;
    const defaultType = !_.isEmpty(template.component_types) && template.component_types ? template.component_types[0].id : null;
    const defaultAssessmentList = template?.scoring_mode === 'SLIDER' ? template?.slider_range_id : template?.select_list_id;
    return (
      <Modal
        centered
        visible
        maskClosable={ false }
        closable={ false }
        style={ { minWidth: '80%' } }
        title={ 'Create Question/Group' }
        onCancel={ () => {
          this.props.setState({
            ...this.props.state,
            showCreateComponentModal: false,
          }, () => {
            this.setState({ componentPlaceholder: null });
          });
        } }
        okText={ 'Create' }
        cancelButtonProps={ {
          disabled: isCreatingComponent
        } }
        okButtonProps={ {
          disabled:
            !componentPlaceholder ||
            !componentPlaceholder?.label ||
            (componentPlaceholder?.type === 'select' && !componentPlaceholder?.config?.list) ||
            (componentPlaceholder?.type === 'slider' && !componentPlaceholder?.config?.range),
          loading: isCreatingComponent
        } }
        onOk={ () => {
          if (componentPlaceholder) {

            const list_id = !!componentPlaceholder?.config?.scoring?.list_id ? componentPlaceholder?.config?.scoring?.list_id : template?.scoring_mode === 'SLIDER' ? template?.slider_range_id : template?.select_list_id;

            if (!!componentPlaceholder.config?.allow_scoring && list_id) {
              componentPlaceholder.config = {
                ...componentPlaceholder.config,
                scoring: {
                  list_id: list_id
                }
              };
            }

            this.props.setState({
              ...this.props.state,
              showCreateComponentModal: false,
              modifiedTemplate: this.modifySet(set.id || set.key, template, set.components.concat([{
                id: null,
                key: uuidv4(),
                template_set_id: set.id || set.key,
                label: '',
                title: '',
                description: null,
                type: !_.isEmpty(template.component_types) && template.component_types ? template.component_types[0].id : 'text',
                parent_component_id: null,
                children: [],
                dependencies: [],
                ...componentPlaceholder
              }]))
            }, () => {
              this.setState({
                componentPlaceholder: null
              });
            });
          }
        } }
      >
        <div className='d-f'>
          <div className='d-if w-40p'>
            <Form
              className="w-100p"
              layout="vertical"
              preserve={ false }
              initialValues={ {
                type: defaultType,
                assessment_list: defaultAssessmentList
              } }
            >
              <Form.Item
                label="Title"
                name="label"
                rules={ [{ required: true, message: 'Required' }] }
              >
                <Input
                  autoFocus
                  autoComplete="off"
                  onChange={ (event: React.ChangeEvent<HTMLInputElement>) => {
                    this.setState({ componentPlaceholder: Object.assign({}, componentPlaceholder, { label: event.target.value }) });
                  } }
                />
              </Form.Item>
              <Form.Item
                label="Type"
                name="type"
                rules={ [{ required: true, message: 'Required' }] }
              >
                <Select
                  style={ { minWidth: 180 } }
                  onChange={ (type: string) => {
                    this.setState({ componentPlaceholder: Object.assign({}, componentPlaceholder, { type: type, config: null }) });
                  } }
                >
                  { !_.isEmpty(template.component_types) && template.component_types?.map((type: ComponentType) => (
                    <Select.Option key={ type.id } value={ type.id }>
                      { type.title }
                    </Select.Option>
                  )) }
                </Select>
              </Form.Item>
              { componentPlaceholder?.type === 'select' && selectLists &&
                <Form.Item
                  label="List"
                  name="list"
                  rules={ [{ required: true, message: 'Required' }] }
                >
                  <Select
                    style={ { minWidth: 180 } }
                    placeholder={ 'Please select' }
                    onChange={ (id: string) => {
                      this.setState({ componentPlaceholder: Object.assign({}, componentPlaceholder, { config: Object.assign({}, componentPlaceholder?.config, { list: id }) }) });
                    } }
                  >
                    { selectLists.map((selectList: SelectListItem) => (
                      <Select.Option key={ selectList.id } value={ selectList.id }>
                        { selectList.title }
                      </Select.Option>
                    )) }
                  </Select>
                </Form.Item>
              }
              { componentPlaceholder?.type === 'slider' && sliderRanges &&
                <Form.Item
                  label="Slider"
                  name="range"
                  rules={ [{ required: true, message: 'Required' }] }
                >
                  <Select
                    style={ { minWidth: 180 } }
                    placeholder={ 'Please select' }
                    onChange={ (id: string) => {
                      this.setState({ componentPlaceholder: Object.assign({}, componentPlaceholder, { config: Object.assign({}, componentPlaceholder?.config, { range: id }) }) });
                    } }
                  >
                    { sliderRanges.map((sliderRange: SliderRange) => (
                      <Select.Option key={ sliderRange.id } value={ sliderRange.id }>
                        { sliderRange.title }
                      </Select.Option>
                    )) }
                  </Select>
                </Form.Item>
              }
              { componentPlaceholder?.type === 'file' &&
                <>
                  <Form.Item
                    label="Allowed extensions"
                    name="allowed_extensions"
                  >
                    <Select
                      allowClear
                      mode={ 'multiple' }
                      style={ { minWidth: 180 } }
                      placeholder={ 'Leave empty to allow all file extensions' }
                      onChange={ (extensions: string[]) => {
                        this.setState({ componentPlaceholder: Object.assign({}, componentPlaceholder, { config: Object.assign({}, componentPlaceholder?.config, { allowed_extensions: extensions }) }) });
                      } }
                    >
                      { extensions?.map((extension: string) => (
                        <Select.Option key={ extension } value={ extension }>
                          { extension }
                        </Select.Option>
                      )) }
                    </Select>
                  </Form.Item>
                  <Form.Item
                    label="Max File Size"
                    name="max_file_size"
                  >
                    <InputNumber
                      style={ { width: '100%' } }
                      placeholder={ `${maxFileSize / 1024 / 1024}` }
                      addonAfter={ 'MB' }
                      onChange={ (value: number) => {
                        this.setState({ componentPlaceholder: Object.assign({}, componentPlaceholder, { config: Object.assign({}, componentPlaceholder?.config, { max_file_size: !!value ? (value * 1024 * 1024) : null }) }) });
                      } }
                    />
                  </Form.Item>
                </>
              }
              <Form.Item label="Settings" style={{ marginBottom: 0 }}>
                { componentPlaceholder?.type !== 'group' ? (
                  <>
                    <Form.Item
                      className="d-if mR-10"
                      name="allow_actions"
                    >
                      <Switch
                        checkedChildren={ 'Actions' }
                        unCheckedChildren={ 'Actions' }
                        onChange={ (checked: boolean) => {
                          this.setState({ componentPlaceholder: Object.assign({}, componentPlaceholder, { config: Object.assign({}, componentPlaceholder?.config, { allow_actions: checked }) }) });
                        } }
                        checked={ componentPlaceholder?.config?.allow_actions }
                      />
                    </Form.Item>
                    { componentPlaceholder?.type === 'select' && selectLists &&
                      <Form.Item
                        className="d-if mR-10"
                        name="allow_multiselect"
                      >
                        <Switch
                          checkedChildren={ 'Allow Multiselect' }
                          unCheckedChildren={ 'Allow Multiselect' }
                          onChange={ (checked: boolean) => {
                            this.setState({ componentPlaceholder: Object.assign({}, componentPlaceholder, { config: Object.assign({}, componentPlaceholder?.config, { allow_multiselect: checked }) }) });
                          } }
                          checked={ componentPlaceholder?.config?.allow_multiselect }
                        />
                      </Form.Item>
                    }
                    <Form.Item
                      className="d-if mR-10"
                      name="allow_scoring"
                    >
                      <Switch
                        checkedChildren={ 'Assessment' }
                        unCheckedChildren={ 'Assessment' }
                        onChange={ (checked: boolean) => {
                          if (checked) {
                            this.setState({ componentPlaceholder: Object.assign({}, componentPlaceholder, { config: Object.assign({}, componentPlaceholder?.config, {
                              allow_scoring: true,
                              scoring: {
                                list_id: template?.scoring_mode === 'SLIDER' ? template?.slider_range_id : template?.select_list_id
                              }
                            }) }) });
                          } else {
                            this.setState({ componentPlaceholder: Object.assign({}, componentPlaceholder, { config: Object.assign({}, componentPlaceholder?.config, {
                              allow_scoring: false,
                              scoring: null
                            }) }) });
                          }
                        } }
                        checked={ componentPlaceholder?.config?.allow_scoring }
                      />
                    </Form.Item>
                    <Form.Item
                      className="d-if mR-10"
                      name="can_mark_not_applicable"
                    >
                      <Switch
                        checkedChildren={ 'Can Mark Not Applicable' }
                        unCheckedChildren={ 'Can Mark Not Applicable' }
                        onChange={ (checked: boolean) => {
                          this.setState({ componentPlaceholder: Object.assign({}, componentPlaceholder, { config: Object.assign({}, componentPlaceholder?.config, { can_mark_not_applicable: checked }) }) });
                        } }
                        checked={ componentPlaceholder?.config?.can_mark_not_applicable }
                      />
                    </Form.Item>

                    <Form.Item
                      className="d-if mR-10"
                      name="allow_comment"
                    >
                      <Switch
                        checkedChildren={ 'Commenting' }
                        unCheckedChildren={ 'Commenting' }
                        onChange={ (checked: boolean) => {
                          this.setState({ componentPlaceholder: Object.assign({}, componentPlaceholder, { config: Object.assign({}, componentPlaceholder?.config, { allow_comment: checked }) }) });
                        } }
                        checked={ componentPlaceholder?.config?.allow_comment }
                      />
                    </Form.Item>
                    <Form.Item
                      className="d-if mR-10"
                      name="allow_attachments"
                    >
                      <Switch
                        checkedChildren={ 'File Attachments' }
                        unCheckedChildren={ 'File Attachments' }
                        onChange={ (checked: boolean) => {
                          this.setState({ componentPlaceholder: Object.assign({}, componentPlaceholder, { config: Object.assign({}, componentPlaceholder?.config, { allow_attachments: checked }) }) });
                        } }
                        checked={ componentPlaceholder?.config?.allow_attachments }
                      />
                    </Form.Item>
                    <Form.Item
                      className="d-if mR-10"
                      name="required"
                    >
                      <Switch
                        checkedChildren={ 'Required' }
                        unCheckedChildren={ 'Required' }
                        onChange={ (checked: boolean) => {
                          this.setState({ componentPlaceholder: Object.assign({}, componentPlaceholder, { config: Object.assign({}, componentPlaceholder?.config, { required: checked }) }) });
                        } }
                        checked={ componentPlaceholder?.config?.required }
                      />
                    </Form.Item>
                  </>
                ) : (
                  <Form.Item
                    className="d-if mR-10"
                    name="show_label"
                  >
                    <Switch
                      checkedChildren={ 'Show Title' }
                      unCheckedChildren={ 'Show Title' }
                      onChange={ (checked: boolean) => {
                        this.setState({ componentPlaceholder: Object.assign({}, componentPlaceholder, { config: Object.assign({}, componentPlaceholder?.config, { show_label: checked }) }) });
                      } }
                      checked={ componentPlaceholder?.config?.show_label }
                    />
                  </Form.Item>
                ) }
                { componentPlaceholder?.config?.allow_scoring &&
                  <Form.Item
                    label="Assessment List"
                    name="assessment_list"
                    rules={ [{ required: true, message: 'Required' }] }
                  >
                    { template.scoring_mode === 'SLIDER' ? (
                      <Select
                        style={{ minWidth: 180 }}
                        placeholder={ 'Please select' }
                        value={ componentPlaceholder?.config?.scoring?.list_id || undefined }
                        onChange={ (type: string) => {
                          this.setState({ componentPlaceholder: Object.assign({}, componentPlaceholder, { config: Object.assign({}, componentPlaceholder?.config, {
                            scoring: {
                              list_id: type
                            }
                          }) }) });
                        } }
                      >
                        { sliderRanges?.map((sliderRanges: SliderRange) => (
                          <Select.Option key={ sliderRanges.id } value={ sliderRanges.id }>
                            { sliderRanges.title }
                          </Select.Option>
                        )) }
                      </Select>
                    ) : (
                      <Select
                        style={{ minWidth: 180 }}
                        placeholder={ 'Please select' }
                        value={ componentPlaceholder?.config?.scoring?.list_id || undefined }
                        onChange={ (type: string) => {
                          this.setState({ componentPlaceholder: Object.assign({}, componentPlaceholder, { config: Object.assign({}, componentPlaceholder?.config, {
                            scoring: {
                              list_id: type
                            }
                          }) }) });
                        } }
                      >
                        { selectLists?.map((selectList: SelectListItem) => (
                          <Select.Option key={ selectList.id } value={ selectList.id }>
                            { selectList.title }
                          </Select.Option>
                        )) }
                      </Select>
                    ) }
                  </Form.Item>
                }
              </Form.Item>
              { componentPlaceholder?.type !== 'group' &&
                <Form.Item
                  name="description"
                  label={
                    <>
                      <span>Description</span>
                      <Tooltip
                        className="mL-5"
                        placement="top"
                        title={ 'Question Tooltip' }
                      >
                        <QuestionCircleOutlined className="cur-p fsz-def text-ant-default" />
                      </Tooltip>
                    </>
                  }
                >
                  <TextArea
                    autoSize={ { minRows: 4 } }
                    onChange={ (event: React.BaseSyntheticEvent) => {
                      this.setState({ componentPlaceholder: Object.assign({}, componentPlaceholder, { description: event.target.value }) });
                    } }
                  />
                </Form.Item>
              }
            </Form>
          </div>
          <div className='d-if mL-20 w-60p'>
            { this.renderComponentPreview(componentPlaceholder?.type || defaultType, componentPlaceholder) }
          </div>
        </div>
      </Modal>
    );
  };

  renderEditComponentModal = (
    template: IDynamicFieldTemplate,
    set: IDynamicFieldTemplateSet,
    activeComponent: IDynamicFieldTemplateComponent
  ) => {

    const { componentPlaceholder } = this.state;
    const { selectLists, sliderRanges } = this.props.state;
    const extensions = _.has(template, 'extensions') ? template.extensions : [];
    const maxFileSize = _.has(template, 'max_file_size') ? template.max_file_size : 50;

    return (
      <Modal
        centered
        visible
        closable={ false }
        style={ { minWidth: '80%' } }
        maskClosable={ false }
        title={ 'Edit Question/Group' }
        onCancel={ () => this.setState({
          showEditComponentModal: false,
          componentPlaceholder: null
        }) }
        okText={ 'Ok' }
        okButtonProps={ {
          disabled:
            !componentPlaceholder ||
            !componentPlaceholder?.label ||
            (componentPlaceholder?.type === 'select' && !componentPlaceholder?.config?.list) ||
            (componentPlaceholder?.type === 'slider' && !componentPlaceholder?.config?.range),
        } }
        onOk={ () => {
          if (componentPlaceholder && activeComponent) {

            const list_id = !!componentPlaceholder?.config?.scoring?.list_id ? componentPlaceholder?.config?.scoring?.list_id : template?.scoring_mode === 'SLIDER' ? template?.slider_range_id : template?.select_list_id;

            if (!!componentPlaceholder.config?.allow_scoring && list_id) {
              componentPlaceholder.config = {
                ...componentPlaceholder.config,
                scoring: {
                  list_id: list_id
                }
              };
            }

            this.setTemplate(this.modifySet(set.id || set.key, template, this.modifyComponent(activeComponent.id || activeComponent.key, set.components, componentPlaceholder)), () => {
              this.setState({
                componentPlaceholder: null,
                showEditComponentModal: false,
              });
            });
          }
        } }
      >
        <div className='d-f'>
          <div className='d-if w-40p'>
            <Form
              className="w-100p"
              layout="vertical"
              preserve={ false }
              initialValues={ {
                label: componentPlaceholder?.label,
                type: componentPlaceholder?.type,
                list: componentPlaceholder?.config?.list,
                range: componentPlaceholder?.config?.range,
                allowed_extensions: componentPlaceholder?.config?.allowed_extensions,
                max_file_size: !!componentPlaceholder?.config?.max_file_size ? (componentPlaceholder?.config?.max_file_size / 1024 / 1024) : undefined,
                description: componentPlaceholder?.description,
                assessment_list: componentPlaceholder?.config?.scoring?.list_id ? componentPlaceholder?.config?.scoring?.list_id : template?.scoring_mode === 'SLIDER' ? template?.slider_range_id : template?.select_list_id,
              } }
            >
              <Form.Item
                label="Title"
                name="label"
                rules={ [{ required: true, message: 'Required' }] }
              >
                <Input
                  autoFocus
                  autoComplete="off"
                  onChange={ (event: React.ChangeEvent<HTMLInputElement>) => {
                    this.setState({ componentPlaceholder: Object.assign({}, componentPlaceholder, { label: event.target.value }) });
                  } }
                />
              </Form.Item>
              <Form.Item
                label="Type"
                name="type"
                rules={ [{ required: true, message: 'Required' }] }
              >
                <Select
                  disabled={ activeComponent.type === 'group' }
                  style={ { minWidth: 180 } }
                  onChange={ (type: string) => {
                    this.setState({ componentPlaceholder: Object.assign({}, componentPlaceholder, { type: type, config: null }) });
                  } }
                >
                  { !_.isEmpty(template.component_types) && template.component_types?.map((type: ComponentType) => (
                    <Select.Option key={ type.id } value={ type.id }>
                      { type.title }
                    </Select.Option>
                  )) }
                </Select>
              </Form.Item>
              { componentPlaceholder?.type === 'select' && selectLists &&
                <Form.Item
                  label="List"
                  name="list"
                  rules={ [{ required: true, message: 'Required' }] }
                >
                  <Select
                    style={ { minWidth: 180 } }
                    placeholder={ 'Please select' }
                    onChange={ (id: string) => {
                      this.setState({ componentPlaceholder: Object.assign({}, componentPlaceholder, { config: Object.assign({}, componentPlaceholder?.config, { list: id }) }) });
                    } }
                  >
                    { selectLists.map((selectList: SelectListItem) => (
                      <Select.Option key={ selectList.id } value={ selectList.id }>
                        { selectList.title }
                      </Select.Option>
                    )) }
                  </Select>
                </Form.Item>
              }
              { componentPlaceholder?.type === 'slider' && sliderRanges &&
                <Form.Item
                  label="Slider"
                  name="range"
                  rules={ [{ required: true, message: 'Required' }] }
                >
                  <Select
                    style={ { minWidth: 180 } }
                    placeholder={ 'Please select' }
                    onChange={ (id: string) => {
                      this.setState({ componentPlaceholder: Object.assign({}, componentPlaceholder, { config: Object.assign({}, componentPlaceholder?.config, { range: id }) }) });
                    } }
                  >
                    { sliderRanges.map((sliderRange: SliderRange) => (
                      <Select.Option key={ sliderRange.id } value={ sliderRange.id }>
                        { sliderRange.title }
                      </Select.Option>
                    )) }
                  </Select>
                </Form.Item>
              }
              { componentPlaceholder?.type === 'file' &&
                <>
                  <Form.Item
                    label="Allowed extensions"
                    name="allowed_extensions"
                  >
                    <Select
                      allowClear
                      mode={ 'multiple' }
                      style={ { minWidth: 180 } }
                      placeholder={ 'Leave empty to allow all file extensions' }
                      onChange={ (extensions: string[]) => {
                        this.setState({ componentPlaceholder: Object.assign({}, componentPlaceholder, { config: Object.assign({}, componentPlaceholder?.config, { allowed_extensions: extensions }) }) });
                      } }
                    >
                      { extensions?.map((extension: string) => (
                        <Select.Option key={ extension } value={ extension }>
                          { extension }
                        </Select.Option>
                      )) }
                    </Select>
                  </Form.Item>
                  <Form.Item
                    label="Max File Size"
                    name="max_file_size"
                  >
                    <InputNumber
                      style={ { width: '100%' } }
                      placeholder={ `${maxFileSize / 1024 / 1024}` }
                      addonAfter={ 'MB' }
                      onChange={ (value: number) => {
                        this.setState({ componentPlaceholder: Object.assign({}, componentPlaceholder, { config: Object.assign({}, componentPlaceholder?.config, { max_file_size: !!value ? (value * 1024 * 1024) : null }) }) });
                      } }
                    />
                  </Form.Item>
                </>
              }
              <Form.Item label="Settings" style={{ marginBottom: 0 }}>
                { componentPlaceholder?.type !== 'group' ? (
                  <>
                    <Form.Item
                      className="d-if mR-10"
                      name="allow_actions"
                    >
                      <Switch
                        checkedChildren={ 'Actions' }
                        unCheckedChildren={ 'Actions' }
                        onChange={ (checked: boolean) => {
                          this.setState({ componentPlaceholder: Object.assign({}, componentPlaceholder, { config: Object.assign({}, componentPlaceholder?.config, { allow_actions: checked }) }) });
                        } }
                        checked={ componentPlaceholder?.config?.allow_actions }
                      />
                    </Form.Item>
                    { componentPlaceholder?.type === 'select' && selectLists &&
                      <Form.Item
                        className="d-if mR-10"
                        name="allow_multiselect"
                      >
                        <Switch
                          checkedChildren={ 'Allow Multiselect' }
                          unCheckedChildren={ 'Allow Multiselect' }
                          onChange={ (checked: boolean) => {
                            this.setState({ componentPlaceholder: Object.assign({}, componentPlaceholder, { config: Object.assign({}, componentPlaceholder?.config, { allow_multiselect: checked }) }) });
                          } }
                          checked={ componentPlaceholder?.config?.allow_multiselect }
                        />
                      </Form.Item>
                    }
                    <Form.Item
                      className="d-if mR-10"
                      name="allow_scoring"
                    >
                      { !template?.scoring_mode ? (
                        <Tooltip
                          title={ 'Assessment can only be used if an Assessment Type has been selected on this template.' }
                        >
                          <Switch
                            disabled
                            unCheckedChildren={ 'Assessment' }
                          />
                        </Tooltip>
                      ) : (
                        <Switch
                          checkedChildren={ 'Assessment' }
                          unCheckedChildren={ 'Assessment' }
                          onChange={ (checked: boolean) => {
                            if (checked) {
                              this.setState({ componentPlaceholder: Object.assign({}, componentPlaceholder, { config: Object.assign({}, componentPlaceholder?.config, {
                                allow_scoring: true
                              }) }) });
                            } else {
                              this.setState({ componentPlaceholder: Object.assign({}, componentPlaceholder, { config: Object.assign({}, componentPlaceholder?.config, {
                                allow_scoring: false,
                                scoring: null
                              }) }) });
                            }
                          } }
                          checked={ componentPlaceholder?.config?.allow_scoring }
                        />
                      ) }
                    </Form.Item>
                    <Form.Item
                      className="d-if mR-10"
                      name="can_mark_not_applicable"
                    >
                      <Switch
                        checkedChildren={ 'Can Mark Not Applicable' }
                        unCheckedChildren={ 'Can Mark Not Applicable' }
                        onChange={ (checked: boolean) => {
                          this.setState({ componentPlaceholder: Object.assign({}, componentPlaceholder, { config: Object.assign({}, componentPlaceholder?.config, { can_mark_not_applicable: checked }) }) });
                        } }
                        checked={ componentPlaceholder?.config?.can_mark_not_applicable }
                      />
                    </Form.Item>
                    <Form.Item
                      className="d-if mR-10"
                      name="allow_comment"
                    >
                      <Switch
                        checkedChildren={ 'Commenting' }
                        unCheckedChildren={ 'Commenting' }
                        onChange={ (checked: boolean) => {
                          this.setState({ componentPlaceholder: Object.assign({}, componentPlaceholder, { config: Object.assign({}, componentPlaceholder?.config, { allow_comment: checked }) }) });
                        } }
                        checked={ componentPlaceholder?.config?.allow_comment }
                      />
                    </Form.Item>
                    <Form.Item
                      className="d-if mR-10"
                      name="allow_attachments"
                    >
                      <Switch
                        checkedChildren={ 'File Attachments' }
                        unCheckedChildren={ 'File Attachments' }
                        onChange={ (checked: boolean) => {
                          this.setState({ componentPlaceholder: Object.assign({}, componentPlaceholder, { config: Object.assign({}, componentPlaceholder?.config, { allow_attachments: checked }) }) });
                        } }
                        checked={ componentPlaceholder?.config?.allow_attachments }
                      />
                    </Form.Item>
                    <Form.Item
                      className="d-if mR-10"
                      name="required"
                    >
                      <Switch
                        checkedChildren={ 'Required' }
                        unCheckedChildren={ 'Required' }
                        onChange={ (checked: boolean) => {
                          this.setState({ componentPlaceholder: Object.assign({}, componentPlaceholder, { config: Object.assign({}, componentPlaceholder?.config, { required: checked }) }) });
                        } }
                        checked={ componentPlaceholder?.config?.required }
                      />
                    </Form.Item>
                  </>
                ) : (
                  <Form.Item
                    className="d-if mR-10"
                    name="show_label"
                  >
                    <Switch
                      checkedChildren={ 'Show Title' }
                      unCheckedChildren={ 'Show Title' }
                      onChange={ (checked: boolean) => {
                        this.setState({ componentPlaceholder: Object.assign({}, componentPlaceholder, { config: Object.assign({}, componentPlaceholder?.config, { show_label: checked }) }) });
                      } }
                      checked={ componentPlaceholder?.config?.show_label }
                    />
                  </Form.Item>
                ) }
                { componentPlaceholder?.config?.allow_scoring &&
                  <Form.Item
                    label="Assessment List"
                    name="assessment_list"
                    rules={ [{ required: true, message: 'Required' }] }
                  >
                    { template.scoring_mode === 'SLIDER' ? (
                      <Select
                        style={{ minWidth: 180 }}
                        placeholder={ 'Please select' }
                        onChange={ (type: string) => {
                          this.setState({ componentPlaceholder: Object.assign({}, componentPlaceholder, { config: Object.assign({}, componentPlaceholder?.config, {
                            scoring: {
                              list_id: type
                            }
                          }) }) });
                        } }
                      >
                        { sliderRanges?.map((sliderRanges: SliderRange) => (
                          <Select.Option key={ sliderRanges.id } value={ sliderRanges.id }>
                            { sliderRanges.title }
                          </Select.Option>
                        )) }
                      </Select>
                    ) : (
                      <Select
                        style={{ minWidth: 180 }}
                        placeholder={ 'Please select' }
                        onChange={ (type: string) => {
                          this.setState({ componentPlaceholder: Object.assign({}, componentPlaceholder, { config: Object.assign({}, componentPlaceholder?.config, {
                            scoring: {
                              list_id: type
                            }
                          }) }) });
                        } }
                      >
                        { selectLists?.map((selectList: SelectListItem) => (
                          <Select.Option key={ selectList.id } value={ selectList.id }>
                            { selectList.title }
                          </Select.Option>
                        )) }
                      </Select>
                    ) }
                  </Form.Item>
                }
              </Form.Item>
              { componentPlaceholder?.type !== 'group' &&
                <Form.Item
                  label={
                    <>
                      <span>Description</span>
                      <Tooltip
                        className="mL-5"
                        placement="top"
                        title={ 'Question Tooltip' }
                      >
                        <QuestionCircleOutlined className="cur-p fsz-def text-ant-default" />
                      </Tooltip>
                    </>
                  }
                  name="description"
                >
                  <TextArea
                    autoSize={ { minRows: 4 } }
                    onChange={ (event: React.BaseSyntheticEvent) => {
                      this.setState({ componentPlaceholder: Object.assign({}, componentPlaceholder, { description: event.target.value }) });
                    } }
                  />
                </Form.Item>
              }
            </Form>
          </div>
          <div className='d-if mL-20 w-60p'>
            { this.renderComponentPreview(componentPlaceholder?.type, componentPlaceholder) }
          </div>
        </div>
      </Modal>
    );
  };

  renderDependencyModal = (
    template: IDynamicFieldTemplate,
    set: IDynamicFieldTemplateSet,
    component: IDynamicFieldTemplateComponent,
    parentComponent: IDynamicFieldTemplateComponent
  ) => {

    const { selectLists } = this.props.state;
    const listType: string | undefined = parentComponent.config?.list;
    const selectList = listType !== undefined && selectLists.find((selectList: any) => selectList.id === listType);
    const selectOptions: SelectOptionItem[] = selectList != null ! ? selectList.options : [];
    const { activeComponentDependencies } = this.state;
    const componentDependencies = activeComponentDependencies != null ? activeComponentDependencies : [];

    return (
      <Modal
        centered
        visible
        closable={ false }
        maskClosable={ false }
        title={ 'Dependency' }
        onCancel={ () => this.setState({ showDependencyModal: false, activeComponentDependencies: null }) }
        okText={ 'Apply' }
        okButtonProps={ {
          disabled: (_.isEmpty(activeComponentDependencies)),
        } }
        onOk={ async () => {
            if (activeComponentDependencies !== null && activeComponentDependencies.length > 0) {
              this.setTemplate(this.modifySet(set.id || set.key, template, this.modifyComponent(component.id || component.key, set.components, { dependencies: activeComponentDependencies })), () => {
                this.setState({
                  activeComponentDependencies: null,
                  showDependencyModal: false,
                });
              });
            }
          }
        }
        style={{ minWidth: 500 }}
      >
        <Form
          layout="vertical"
          initialValues={ {
            question: parentComponent.label,
            options: component.dependencies,
          } }
        >
          <Form.Item
            label="Question"
            name="question"
          >
            <Input
              key={ parentComponent.id }
              disabled={ true }
              autoComplete="off"
              autoFocus
            />
          </Form.Item>
          <Form.Item
            label={ selectList.title }
            name="options"
            rules={ [{ required: true, message: 'Required' }] }
          >
            <Select
              style={ { minWidth: 180 } }
              placeholder={ 'Please select' }
              onChange={ (id: string) => {
                const dependencyIndex = componentDependencies.findIndex(dependency => dependency.value === id);
                if (dependencyIndex === -1) {
                  this.setState({
                    activeComponentDependencies: [
                      ...componentDependencies,
                      Object.assign({}, activeComponentDependencies, { component_id: component.id, operator: '=', value: id }),
                    ]
                  });
                }
              } }
            >
              { selectOptions.map((selectOption: SelectOptionItem) => (
                <Select.Option key={ selectOption.id } value={ selectOption.id }>
                  { selectOption.option }
                </Select.Option>
              )) }
            </Select>
          </Form.Item>
        </Form>
      </Modal>
    );
  };

  renderMoveModal = (
    template: IDynamicFieldTemplate,
    set: IDynamicFieldTemplateSet,
    component: IDynamicFieldTemplateComponent
  ) => {
    const { modifiedTemplate } = this.props.state;
    const { newComponentSet } = this.state;

    return (
      <Modal
        centered
        visible
        closable={ false }
        maskClosable={ false }
        style={{ minWidth: 500 }}
        title={ 'Move' }
        onCancel={ () => this.setState({ showMoveModal: false, newComponentSet: null }) }
        okText={ 'Move' }
        okButtonProps={{
          disabled: !newComponentSet
        }}
        onOk={ async () => {

            const templateSet: IDynamicFieldTemplateSet | undefined = template.sets.find((set: IDynamicFieldTemplateSet) => set.id === newComponentSet || set.key === newComponentSet);
            const componentKey: number | string | undefined = component?.id || component.key;

            if (!!templateSet&& !!componentKey) {
              this.duplicateComponent(template, templateSet, component, (newTemplate: IDynamicFieldTemplate) => {
                this.onDeleteComponent(newTemplate, set, componentKey);
              });
            }

            this.setState({
              newComponentSet: null,
              showMoveModal: false,
            });
        } }
      >
        <Form
          layout="vertical"
        >
          <Form.Item
            label={ 'Target Question Set' }
            name="options"
            required
          >
            <Select
              style={ { minWidth: 180 } }
              placeholder={ 'Please select' }
              onChange={ (id: string) => {
                this.setState({
                  newComponentSet: id
                });
              } }
            >
              { modifiedTemplate.sets
                .filter((templateSet: IDynamicFieldTemplateSet) => {
                  return templateSet?.id !== set.id || templateSet?.key !== set.key;
                })
                .map((templateSet: IDynamicFieldTemplateSet) => (
                  <Select.Option key={ templateSet.id || templateSet.key } value={ templateSet?.key || templateSet.id }>
                    { templateSet.title }
                  </Select.Option>
                ))
              }
            </Select>
          </Form.Item>
        </Form>
      </Modal>
    );
  };

  renderDeleteDialog = (
    template: IDynamicFieldTemplate,
    set: IDynamicFieldTemplateSet,
    componentId: string | number,
  ) => {
    return (
      <Modal
        visible
        centered
        title={ 'Remove' }
        maskClosable={ false }
        closable={ false }
        okButtonProps={{
          danger: true
        }}
        cancelButtonProps={{
          disabled: false,
        }}
        onOk={ () => this.onDeleteComponent(template, set, componentId) }
        onCancel={ () => this.setState({
          showDeleteComponentDialog: false,
          deleteComponentId: null,
        }) }
      >
        <p>Are you sure you want to remove this question?</p>
      </Modal>
    );
  };

  renderTree = (template: IDynamicFieldTemplate, set: IDynamicFieldTemplateSet) => {
    const { componentPlaceholder } = this.state;
    const isDisabled = _.has(template, 'can_edit') && !template.can_edit;

    return (
      <Tree
        className="DynamicFieldComponentList"
        defaultExpandAll
        autoExpandParent
        draggable
        blockNode
        switcherIcon={ <DownOutlined /> }
        showLine={ false }
        allowDrop={ ({dragNode, dropNode, dropPosition}) => {

          // Top level
          if (dropPosition === -1) {
            return true;
          }

          // Dragging group
          if (dragNode.type === 'group') {
            if (dropNode.type !== 'group') {
              return true;
            }
          }

          // Dragging question
          if (dragNode.type !== 'group') {
            if (dropNode.type === 'group' || dropPosition === 1) {
              return true;
            }
          }

          return false;
        } }
        onDrop={ (tree: any) => this.handleDrop(set.id || set.key, template, nestedSet(set.components), tree) }
        titleRender={ (component: any) => {

          const parentComponent = getRootComponent(component, set.components);
          const dependenciesDisabled = !(parentComponent !== null && parentComponent.type === 'select');
          const dependenciesCount: number = component.dependencies ? component.dependencies.length : 0;

          return (
            <div
              className={ classNames('d-f jc-sb p-5', { 'bdB bg-grey-100 pY-10 pX-5': component.type === 'group' }) }
              onClick={ (event: React.MouseEvent) => {
                event.stopPropagation();
                if (!isDisabled) {
                  this.setState({
                    activeComponent: component,
                    showEditComponentModal: true,
                    componentPlaceholder: Object.assign({}, componentPlaceholder, {
                      label: component.label,
                      type: component.type,
                      config: component?.config,
                      description: component.description,
                    })
                  });
                }
              } }
            >
              <span className="d-f jc-c ai-c pR-5">
                <span className='mL-10'>
                  <div>{ component.label || '-' }</div>
                  <div className='fsz-xs op-60p'>
                    { component.type !== 'group' &&
                      <>
                        <div className="d-if whs-nw pR-10">Type:<b className="mL-5">{ this.getComponentTypeLabel(component.type, template.component_types) }</b></div>
                        <div className="d-if whs-nw pR-10">Allow Comment:<b className="mL-5">{ _.has(component, 'config.allow_comment') && component.config.allow_comment ? 'Yes' : 'No' }</b></div>
                        <div className="d-if whs-nw pR-10">Allow Attachments:<b className="mL-5">{ _.has(component, 'config.allow_attachments') && component.config.allow_attachments ? 'Yes' : 'No' }</b></div>
                        <div className="d-if whs-nw pR-10">Allow Actions:<b className="mL-5">{ _.has(component, 'config.allow_actions') && component.config.allow_actions ? 'Yes' : 'No' }</b></div>
                        <div className="d-if whs-nw pR-10">Required:<b className="mL-5">{ _.has(component, 'config.required') && component.config.required ? 'Yes' : 'No' }</b></div>
                        <div className="d-if whs-nw pR-10">Can Mark Not Applicable:<b className="mL-5">{ _.has(component, 'config.can_mark_not_applicable') && component.config.can_mark_not_applicable ? 'Yes' : 'No' }</b></div>
                      </>
                    }
                    <span>Allow Assessment:<b className="mL-5">{ _.has(component, 'config.allow_scoring') && component.config.allow_scoring ? 'Yes' : 'No' }</b></span>
                  </div>
                </span>
              </span>
              { !isDisabled &&
                <span className='d-f ai-c ta-r'>
                  <span
                    className='d-f jc-fe'
                    style={{ minWidth: 110 }}
                    onClick={ (event: React.MouseEvent) => event.stopPropagation() }
                  >
                    { component.type === 'group' && !dependenciesDisabled &&
                      <Button
                        disabled={ dependenciesDisabled }
                        className="mR-5"
                        onClick={ () => {
                          this.setState({
                            showDependencyModal: true,
                            activeComponent: component,
                            activeParentComponent: parentComponent ?? null,
                          });
                        } }
                        style={ {
                          padding: '4px 7px',
                          width: '32px',
                        } }
                      >
                        <Badge
                          style={ { backgroundColor: dependenciesCount > 0 ? '#52c41a' : '#b9c2d0', top: -9, right: -8 } }
                          size={ 'small' }
                          count={ dependenciesCount }
                          showZero={ true }
                        >
                          <Tooltip
                            placement="top"
                            title={ 'Dependencies' }
                          >
                            <ApartmentOutlined />
                          </Tooltip>
                        </Badge>
                      </Button>
                    }
                    { component.type === 'group' && dependenciesDisabled &&
                      <Button
                        disabled={ dependenciesDisabled }
                        className="mR-5"
                        onClick={ () => {
                          this.setState({
                            showDependencyModal: true,
                            activeComponent: component,
                            activeParentComponent: parentComponent ?? null,
                          });
                        } }
                        style={ {
                          padding: '4px 7px',
                          width: '32px',
                        } }
                      >
                        <ApartmentOutlined />
                      </Button>
                    }
                    <Dropdown
                      actions={ [
                        {
                          node: '',
                          onClick: () => {}
                        },
                        {
                          node: 'Edit',
                          onClick: () => {
                            this.setState({
                              activeComponent: component,
                              showEditComponentModal: true,
                              componentPlaceholder: Object.assign({}, componentPlaceholder, {
                                label: component.label,
                                type: component.type,
                                config: component?.config,
                                description: component.description,
                              })
                            });
                          }
                        },
                        {
                          node: 'Duplicate',
                          onClick: () => {
                            this.duplicateComponent(template, set, component);
                          }
                        },
                        {
                          node: 'Move',
                          disabled: this.props.state.modifiedTemplate?.sets.length === 1 ? [`There's only one set`] : false,
                          onClick: () => {
                            this.setState({
                              showMoveModal: true,
                              activeComponent: component
                            });
                          }
                        },
                        {
                          node: <span className="ant-text-danger">Remove</span>,
                          onClick: () => {
                            this.setState({
                              deleteComponentId: component?.id || component.key,
                              showDeleteComponentDialog: true,
                            });
                          }
                        },
                        ]
                      }
                    />
                  </span>
                </span>
              }
            </div>
          );
        } }
        treeData={ nestedSet(set.components) }
      />
    );
  };

  render = () => {
    const { template, selectedSetId } = this.props;
    const { showEditComponentModal, activeComponent, showDependencyModal, showMoveModal, activeParentComponent, showDeleteComponentDialog, deleteComponentId } = this.state;
    const { showCreateComponentModal, isLoading } = this.props.state;

    if (isLoading) return <div className="d-f jc-c ai-c mH-450"><BlockingSpinner isLoading /></div>;

    const templateSet: IDynamicFieldTemplateSet | undefined = template.sets.find((set: IDynamicFieldTemplateSet) => set.id === selectedSetId || set.key === selectedSetId);

    if (!templateSet) return <></>;

    return (
      <div
        className="ovY-s pT-5"
        style={ { minHeight: 500 } }
      >
        { this.renderTree(template, templateSet) }
        { showDeleteComponentDialog && !!deleteComponentId && this.renderDeleteDialog(template, templateSet, deleteComponentId) }
        { showEditComponentModal && selectedSetId && activeComponent && this.renderEditComponentModal(template, templateSet, activeComponent) }
        { showCreateComponentModal && this.renderCreateComponentModal(template, templateSet) }
        { showDependencyModal && activeParentComponent && activeComponent && this.renderDependencyModal(template, templateSet, activeComponent, activeParentComponent) }
        { showMoveModal && activeComponent && this.renderMoveModal(template, templateSet, activeComponent) }
      </div>
    );
  };
};

export default DynamicFieldComponentList;
