// Libs
import * as React from 'react';

// Components
import RecordView from 'views/common/RecordView';

// Interfaces
import { RecordFormEntity } from 'types/entities';

interface Props {
  record: RecordFormEntity;
  setRecord: (record: RecordFormEntity) => void;
};

class AssetRecord extends React.Component<Props> {
  render = () => {
    return <RecordView type={ 'asset' } entity={ 'record' } id={ this.props.record.id } record={ this.props.record } setRecord={ this.props.setRecord } />;
  };
};

export default AssetRecord;
