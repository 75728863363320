// Libs
import * as React from 'react';
import { connect } from 'react-redux';
import { Link, withRouter, RouteComponentProps } from 'react-router-dom';
import _ from 'lodash';

// Components
import BlockingSpinner from 'components/blocking-spinner';
import BasicList from 'components/basic-list';
import Jumbotron from 'components/jumbotron';
import { RestrictionHoC } from 'components/restriction';
import Dropdown from 'components/dropdown';
import { Form, Input, Modal } from 'antd';
import Badge, { BadgeType } from 'components/badge';

// Services
import { Api } from 'services/api';
import { getFormatedDate } from 'services/settings';
import Notification from 'services/notification';
import history from 'utils/history';

// Actions
import { setBreadcrumbsLoading, setBreadcrumbs } from 'store/UI/ActionCreators';

// Interfaces
import AppState from 'store/AppState.interface';
import { Breadcrumb } from 'store/UI/State.interface';
import { IDynamicFieldTemplate, IDynamicFieldTemplateType } from 'views/admin/templates/Templates.interfaces';

const API: Api = new Api();

interface Props {
  client_id: number;
  permissions: any;
  match: {
    isExact: boolean;
    params: Record<string, any>;
    path: string;
    url: string;
  };
  setBreadcrumbsLoading(value: boolean): void;
  setBreadcrumbs(breadcrumbs: Breadcrumb[], concat: boolean): void;
};

interface State {
  templateType: IDynamicFieldTemplateType | null;
  templates: IDynamicFieldTemplate[];
  activeTemplate: IDynamicFieldTemplate | null;
  showDeleteModal: boolean;
  showCreateModal: boolean;
  showEditModal: boolean;
  isLoading: boolean;
  isUpdating: boolean;
  isCreating: boolean;
  isDeleting: boolean;
};

class DynamicFieldTemplates extends React.Component<RouteComponentProps<{}> & Props, State> {

  mounted: boolean = false;
  createFormRef: any = React.createRef();
  editFormRef: any = React.createRef();

  state: State = {
    templateType: null,
    templates: [],
    activeTemplate: null,
    showDeleteModal: false,
    showCreateModal: false,
    showEditModal: false,
    isLoading: false,
    isUpdating: false,
    isCreating: false,
    isDeleting: false,
  };

  componentDidMount = async () => {
    const { client_id, setBreadcrumbs } = this.props;
    const type_id = this.props.match.params.type_id;

    this.mounted = true;

    try {

      this.props.setBreadcrumbsLoading(true);

      await new Promise((resolve) => this.setState({ isLoading: true }, () => resolve(null)));

      const templates = await API.get(`client/${client_id}/admin/templates/dynamic-field/types/${type_id}/templates`);
      const templateType = await API.get(`client/${client_id}/admin/templates/dynamic-field/types/${type_id}`);

      setBreadcrumbs([
        { title: 'Home', path: '/' },
        { title: 'Admin', path: '/admin' },
        { title: 'Template Types', path: '/admin/templates/dynamic-field' },
        { title: templateType.title, path: null },
      ], false);

      this.mounted && this.setState({
        templates: templates,
        templateType: templateType,
      });

    } catch (error) {
      console.error('Error: ', error);
    } finally {
      this.props.setBreadcrumbsLoading(false);
      this.mounted && this.setState({
        isLoading: false
      });
    }
  };

  componentWillUnmount = () => {
    this.props.setBreadcrumbs([], false);
    this.mounted = false;
  };

  duplicateTemplate = async (activeTemplate: IDynamicFieldTemplate) => {
    const { client_id } = this.props;
    const type_id = this.props.match.params.type_id;

    try {

      await new Promise((resolve) => this.setState({ isUpdating: true }, () => resolve(null)));
      await API.put(`client/${client_id}/admin/templates/dynamic-field/types/${type_id}/templates/${activeTemplate.id}/duplicate`);
      const templates = await API.get(`client/${client_id}/admin/templates/dynamic-field/types/${type_id}/templates`);

      this.setState({
        templates: templates
      }, () => {
        Notification('success', 'Template Duplicated', 'Created');
      });

    } catch (error) {
      Notification('error', 'Failed to duplicate the template', 'Failed');
    } finally {
      this.setState({
        isUpdating: false,
      });
    }
  };

  renderCreateModal = () => {
    const { client_id } = this.props;
    const { isCreating } = this.state;
    const type_id = this.props.match.params.type_id;
    return (
      <Modal
        centered
        visible
        closable={ false }
        title={ 'Create Template' }
        onCancel={ () => this.setState({ showCreateModal: false }) }
        okText={ 'Create' }
        cancelButtonProps={{
          disabled: isCreating
        }}
        okButtonProps={{
          loading: isCreating
        }}
        onOk={ async () => {
          this.createFormRef
            .current
            .validateFields()
            .then(async (values: any) => {
              try {

                await new Promise((resolve) => this.setState({ isCreating: true }, () => resolve(null)));

                const template = await API.post(`client/${client_id}/admin/templates/dynamic-field/types/${type_id}/templates`, {
                  title: values.title
                });

                const templates = await API.get(`client/${client_id}/admin/templates/dynamic-field/types/${type_id}/templates`);

                this.setState({
                  templates: templates
                }, () => {
                  Notification('success', 'Template saved', 'Created');
                  history.push(`${type_id}/template/${template.id}`);
                });

              } catch (error) {
                Notification('error', 'Failed to create template', 'Failed');
              }
            })
            .catch((info: any) => {
              console.error('Invalid state');
            });
        } }
        style={{ minWidth: 500 }}
      >
        <Form
          ref={ this.createFormRef }
          layout="vertical"
        >
          <Form.Item
            label="Title"
            name="title"
            rules={[{ required: true, message: 'Required' }]}
          >
            <Input autoFocus />
          </Form.Item>
        </Form>
      </Modal>
    );
  };

  renderEditSetModal = (activeTemplate: IDynamicFieldTemplate) => {
    const { client_id } = this.props;
    const { isUpdating } = this.state;
    const type_id = this.props.match.params.type_id;
    return (
      <Modal
        centered
        visible
        closable={ false }
        title={ 'Edit Template' }
        onCancel={ () => this.setState({ showEditModal: false }) }
        okText={ 'Edit' }
        cancelButtonProps={{
          disabled: isUpdating
        }}
        okButtonProps={{
          loading: isUpdating
        }}
        onOk={ async () => {
          this.editFormRef
            .current
            .validateFields()
            .then( async (values: any) => {
              try {

                await new Promise((resolve) => this.setState({ isUpdating: true }, () => resolve(null)));

                await API.put(`client/${client_id}/admin/templates/dynamic-field/types/${type_id}/templates/${activeTemplate.id}`, {
                  title: values.title
                });

                const templates = await API.get(`client/${client_id}/admin/templates/dynamic-field/types/${type_id}/templates`);

                this.setState({
                  templates: templates
                }, () => {
                  Notification('success', 'Template Changed', 'Created');
                });

              } catch (error) {
                Notification('error', 'Failed to edit template', 'Failed');
              } finally {
                this.setState({
                  showEditModal: false,
                  isUpdating: false,
                });
              }
            })
            .catch((info: any) => {
              console.error('Invalid state');
            });
          }
        }
        style={{ minWidth: 500 }}
      >
        <Form
          ref={ this.editFormRef }
          layout="vertical"
          initialValues={{
            title: activeTemplate.title
          }}
        >
          <Form.Item
            label="Title"
            name="title"
            rules={[{ required: true, message: 'Required' }]}
          >
            <Input autoFocus />
          </Form.Item>
        </Form>
      </Modal>
    );
  };

  renderDeleteDialog = (template: IDynamicFieldTemplate) => {
    const type_id = this.props.match.params.type_id;
    return (
      <Modal
        title={ 'Remove Template' }
        closable={ false }
        maskClosable={ false }
        centered
        visible
        onCancel={ () => this.setState({ showDeleteModal: false, activeTemplate: null }) }
        okText={ 'Remove' }
        onOk={ async () => {
          try {

            if (!this.state.activeTemplate) return;

            await new Promise((resolve) => this.setState({ isDeleting: true }, () => resolve(null)));
            await API.delete(`client/${this.props.client_id}/admin/templates/dynamic-field/types/${type_id}/templates/${template.id}`);

            const templates = await API.get(`client/${this.props.client_id}/admin/templates/dynamic-field/types/${type_id}/templates`);

            this.setState({
              templates: templates
            }, () => {
              Notification('success', 'Template Removed', 'Removed');
            });
          } catch (error) {
            Notification('error', 'Failed to remove template', 'Failed');
          } finally {
            this.setState({
              showDeleteModal: false,
              isDeleting: false,
            });
          }
        } }
        okButtonProps={{
          danger: true
        }}
        confirmLoading={ this.state.isDeleting }
      >
        <p>Are you sure you want to remove <b>{ template.title }</b>?</p>
      </Modal>
    );
  };

  renderTemplates = () => {
    const { templates, isLoading, activeTemplate, showDeleteModal } = this.state;
    const type_id = this.props.match.params.type_id;

    if (isLoading) return <div className="d-f jc-c ai-c mH-450"><BlockingSpinner isLoading /></div>;

    const mapColumns = () => {
      return [
        {
          key: 'title',
          dataIndex: 'title',
          title: 'Title',
          render: (title: string, record: IDynamicFieldTemplate) => {
            return (
              <Link className='primaryColor' to={ `/admin/templates/dynamic-field/type/${type_id}/template/${record.id}` }>
                { title }
              </Link>
            );
          },
          filterable: true,
          sorter: true,
          ellipsis: true,
        },
        {
          key: 'version',
          dataIndex: 'version',
          title: 'Version',
          filterable: true,
          sorter: true,
          ellipsis: true,
          width: 150,
          render: (version: number) => {
            return (
              <Badge type={ BadgeType.Disabled } text={ `Version ${version}` } />
            );
          },
        },
        {
          key: 'version_status',
          dataIndex: 'version_status',
          title: 'Status',
          filterable: true,
          sorter: true,
          ellipsis: true,
          width: 150,
          render: (version_status: string, record: IDynamicFieldTemplate) => {
            return (
              <Badge type={ record.version_status === 'PUBLISHED' ? BadgeType.Success : BadgeType.Default } text={ _.startCase(_.toLower(version_status)) } />
            );
          },
        },
        {
          key: 'count',
          dataIndex: 'count',
          title: 'Record Count',
          filterable: false,
          sorter: true,
          ellipsis: true,
          width: 150,
          render: (count: string, record: IDynamicFieldTemplate) => {
            return (
               <span className="ta-r">
                 { count }
               </span>
            );
          },
        },
        {
          key: 'created_at',
          dataIndex: 'created_at',
          title: 'Created',
          render: (created_at: string) => getFormatedDate(created_at, undefined, true),
          width: 200,
          sorter: true,
          ellipsis: true,
          filterable: false,
        },
        {
          key: 'actions',
          title: '',
          render: (__: any, template: IDynamicFieldTemplate) => {
            return (

                <Dropdown actions={ [
                  {
                    node: '',
                    onClick: () => {}
                  },
                  {
                    node: 'Edit',
                    onClick: () => this.setState({ activeTemplate: template, showEditModal: true })
                  },
                  {
                    node: 'Duplicate',
                    onClick: () => {
                      this.duplicateTemplate(template);
                    }
                  },
                  {
                    node: (
                      <span style={{ color: 'red' }}>Remove</span>
                    ),
                    onClick: () => {
                      this.setState({ activeTemplate: template, showDeleteModal: true });
                    }
                  },
                  ] }
                />
            );
          },
          align: 'center' as 'center',
          fixed: 'right' as 'right',
          width: 90,
        }
      ];
    };

    const mapData = (templates: IDynamicFieldTemplate[]) => {
      return templates.map((template: IDynamicFieldTemplate, index: number) => {
        return {
          'key': index,
          ...template
        };
      });
    };

    return (
      <>
        <BasicList
          rawData
          columns={ mapColumns() }
          items={ mapData(templates) }
        />
        { showDeleteModal && activeTemplate && this.renderDeleteDialog(activeTemplate) }
      </>
    );
  };

  render = () => {
    const { isLoading, activeTemplate, templateType, showCreateModal, showEditModal } = this.state;

    if (!templateType || isLoading) return <div className="d-f jc-c ai-c mH-450"><BlockingSpinner isLoading /></div>;

    return (
      <>
        <Jumbotron
          content={ `${templateType.title} Templates` }
          tabs={[
            {
              label: 'Overview',
              node: this.renderTemplates(),
            }
          ]}
          rightActions={[
            {
              node: (
                <Dropdown
                  actions={[
                    {
                      node: 'Create',
                      onClick: () => this.setState({ showCreateModal: true })
                    }
                  ]}
                />
              )
            }
          ]}
        />
        { showCreateModal && this.renderCreateModal() }
        { showEditModal && activeTemplate && this.renderEditSetModal(activeTemplate) }
      </>
    );
  };
}

// Make data available on props
const mapStateToProps = (store: AppState) => {
  return {
    client_id: store.ClientState.client_id,
    permissions: store.UserState.user.permissions,
  };
};

// Make functions available on props
const mapDispatchToProps = (dispatch: any) => {
  return {
    setBreadcrumbsLoading: (value: boolean) => dispatch(setBreadcrumbsLoading(value)),
    setBreadcrumbs: (value: Breadcrumb[], concat: boolean) => dispatch(setBreadcrumbs(value, concat)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RestrictionHoC(withRouter(DynamicFieldTemplates), 'access_admin_content_manager'));