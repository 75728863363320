import { ComponentDependency } from "views/admin/templates/Templates.interfaces";

export interface DynamicField {
  id: number;
  key?: number;
  template_set_id?: number;
  label: string;
  description: string;
  reference: string;
  type: string;
  config: DynamicFieldConfig;
  not_applicable: boolean
  options: any[];
  scoring: DynamicFieldScoring;
  range_options: RangeOptions;
  presets: any[];
  values: any[];
  attachments: Attachments;
  action_list: any;
  dependencies: ComponentDependency[];
  children: DynamicField[];
  parent_component_id: number;
};

export interface Attachments {
  comment: string | null;
  files: any[];
};

export interface DynamicFieldScoring {
  mode: string;
  value: string | number | null;
  list: DynamicFieldScoringList;
};

export interface DynamicFieldScoringList {
  id: string;
  tool_id: number | null;
  title: string;
  min: number;
  max: number;
  step: string;
  options: DynamicFieldScoringOption[];
};

export interface DynamicFieldScoringOption {
  id: number | number;
  title: string;
  option: string;
  description: string;
  order: number;
  value: string | number;
  color?: string;
  icon_id?: number;
  icon?: {
    id: number;
    title: string;
    file: string;
  },
};

export interface DynamicFieldConfig {
  cardinality: number;
  required: boolean;
  locked: boolean;
  mode: string;
  version_changed: boolean;
  allowed_extensions: string[];
  can_inline_create: boolean;
  allow_attachments: boolean;
  allow_comment: boolean;
  allow_actions: boolean;
  can_mark_not_applicable: boolean;
  allow_scoring: boolean;
  show_label: boolean;
  max_file_size: number;
  allow_multiselect: boolean;
};

export interface RangeOptions {
  min: number;
  max: number;
  step: string;
};

export const DEPENDENCY_OPERATOR_EQUAL = '=';