import { Dispatch } from 'redux';
import UserActionTypes from './ActionTypes.enum';
import { UserEntity } from 'types/entities';

import {
  GetUserSuccessAction,
  GetUserFailureAction,
  GetAuthFailureAction,
  GetAuthSuccessAction,
  LogoutUserFailureAction,
  LogoutUserSuccessAction,
  UpdatedUserSettingAction,
  GetAuthStartAction,
} from './Actions.interface';

// Services
import { Api } from 'services/api';

export function authenticationStart(): GetAuthStartAction {
  return {
    type: UserActionTypes.AUTHENTICATION_START,
  };
}

export function authenticationFailure(error: string): GetAuthFailureAction {
  return {
    type: UserActionTypes.AUTHENTICATION_FAILURE,
    payload: {
      error
    }
  };
}

export function authenticationSuccess(): GetAuthSuccessAction {
  return {
    type: UserActionTypes.AUTHENTICATION_SUCCESS
  };
}

export function failedToFetchMe(error: string): GetUserFailureAction {
  return {
    type: UserActionTypes.GET_USER_FAILURE,
    payload: {
      error
    }
  };
}

export function sessionExpired() {
  return {
    type: UserActionTypes.SESSION_EXPIRATION
  };
}

export function logoutFailure(): LogoutUserFailureAction {
  return {
    type: UserActionTypes.LOGOUT_FAILURE
  };
}

export function logoutSuccess(): LogoutUserSuccessAction {
  return {
    type: UserActionTypes.LOGOUT_SUCCESS
  };
}

export function receiveMe(user: UserEntity): GetUserSuccessAction {
  return {
    type: UserActionTypes.GET_USER_SUCCESS,
    payload: {
      user
    }
  };
}

export function updatedUserSetting(user: UserEntity): UpdatedUserSettingAction {
  return {
    type: UserActionTypes.UPDATED_USER_SETTINGS,
    payload: {
      user
    }
  };
}

export const setUserSetting = (API: Api, key: string, value: any) => {
  return (dispatch: Dispatch) => {
    return new Promise((resolve, reject) => {
      API.setUserSetting(key, value)
        .then((response) => {
          dispatch(updatedUserSetting(response.data));
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
};
