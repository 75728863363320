// Libs
import { AxiosResponse } from 'axios';

import { api } from 'store/Store';
import { initialiseAppComplete, initialiseAppFailed, initialiseAppStart } from './ActionCreators';
import { authenticationFailure, authenticationSuccess, receiveMe } from 'store/User/ActionCreators';
import { logoutUser } from 'store/User/Actions';
import { getClientSuccess } from 'store/Client/Actions';
import { getNotifications, getNotificationsCountSuccess } from 'store/Notification/Actions';

// Store
import store from 'store/Store';

// Utils
import Console from 'utils/console';

// Interfaces
import ClientRecord from 'store/Client/Record.interface';
import AppState from 'store/AppState.interface';
import { UserEntity } from 'types/entities';

export function ping() {
  return async (dispatch: (action: Record<string, any>) => void) => {
    try {

      const appState: AppState = store.getState();
      const client_id = appState.ClientState.client_id;

      if (!client_id) throw new Error('client_id does not exist');

      const pingResponse = await api.instance.get(`client/${client_id}/ping`);

      // User changed client, refresh app
      if (client_id !== pingResponse.data.client_id) {
        window.location.reload();
      }

      dispatch(getNotificationsCountSuccess(pingResponse?.data?.notification_count));

    } catch (error) {
      dispatch(initialiseAppFailed(error));
    }
  };
};

export function initialiseApp() {
  return async (dispatch: (action: Record<string, any>) => void) => {
    try {
      Console.group('[PACS] - App initialisation');

      dispatch(initialiseAppStart());
      const hasTokens = api.hasTokens();

      if (hasTokens) {
        // has tokens
        Console.info('[Auth] - Existing Session - attempt to rehydrate');

        await setUpUser(dispatch);
      } else {
        // does not have tokens so the user must log in
        Console.info('[Auth] - New Session - user authenticates fresh');

        dispatch(initialiseAppComplete());
      }
    } catch (error) {
      Console.info('Initialisation error');
      Console.info(error);
      dispatch(initialiseAppFailed(error));
    } finally {
      Console.groupEnd();
    }
  };
};

async function setUpUser(dispatch: (action: Record<string, any>) => void) {
  try {
    Console.info('[Auth] - Existing Session - refetch user to test tokens');
    Console.info('[Auth] - Fetching user');

    const userResponse: AxiosResponse<UserEntity> = await api.instance.get('/user').catch(error => Promise.reject({ callee: 'fetchUser', error }));
    const user = userResponse.data;
    dispatch(receiveMe(user));

    if (user.active_client && user.active_client !== 0) {
      Console.info('[Auth] - Get Client');

      const clientResponse: AxiosResponse<ClientRecord> = await api.instance.get(`client/${user.active_client}`).catch(error => Promise.reject({ callee: 'fetchClient', error }));
      const client = clientResponse.data;

      dispatch(getClientSuccess(client));
      dispatch(getNotifications());
      dispatch(authenticationSuccess());
      dispatch(initialiseAppComplete());

      Console.info('[Auth] - Success');
    } else {
      dispatch(initialiseAppComplete());
    }
  } catch (error: any) {
    switch (error.callee) {
      case 'fetchUser':
        Console.info('[Auth] - Error fetching user');

        if (error.error.response && (error.error.response.status === 401 || error.error.response.status === 403)) {
          dispatch(authenticationFailure('Unauthenticated'));
        } else {
          dispatch(authenticationFailure('Unknown error'));
        }
        break;

      case 'fetchClient':
        Console.info('[Auth] - Error fetching client');

        if (error.error.response && (error.error.response.status === 401 || error.error.response.status === 403)) {
          dispatch(authenticationFailure('Unauthenticated'));
        } else {
          dispatch(authenticationFailure('Unknown error'));
        }
        break;

      default:
        dispatch(authenticationFailure('Unknown error'));
        break;
    }

    dispatch(logoutUser());
    dispatch(initialiseAppFailed('Failed to refresh old state, cleaning up and starting fresh'));
  }
};
