import axios, {  AxiosResponse } from 'axios';
import jwtDecode, { JwtPayload } from 'jwt-decode';
import moment from 'moment';
import { api } from 'store/Store';
import { AuthenticationResponse } from 'utils/api';
import Console from 'utils/console';
import {
  authenticationFailure,
  authenticationStart,
  logoutFailure,
  logoutSuccess,
  receiveMe,
  sessionExpired,
} from './ActionCreators';

// Interfaces
import { UserEntity } from 'types/entities';

async function exchangeUserCredentialsForTokens(email: string, password: string) {
  try {
    Console.info('[Auth] - Exchanging credentials for tokens');
    const response: AxiosResponse<AuthenticationResponse> = await axios.post(
      `${process.env.REACT_APP_API_URL}/v1/oauth/token`,
      `grant_type=password&client_id=${process.env.REACT_APP_AUTH_CLIENT_ID}&client_secret=${process.env.REACT_APP_AUTH_CLIENT_SECRET}&username=${email}&password=${encodeURIComponent(password)}&scope=*&`,
    );

    const decodedToken = jwtDecode<JwtPayload>(response.data.access_token);
    const expiresAt = decodedToken.exp || moment().add(response.data.expires_in, 'seconds').valueOf();
    api.setTokens(response.data.access_token, response.data.refresh_token, expiresAt);
    Console.info('[Auth] - Tokens set');
  } catch (error) {
    return Promise.reject({
      callee: 'tokenExchange',
      error
    });
  }
};

export function authenticateUser(email: string, password: string) {
  return async (dispatch: (action: Record<string, any>) => void) => {
    try {
      dispatch(authenticationStart());
      Console.group('[PACS] - Authenticating user');
      await exchangeUserCredentialsForTokens(email, password);
      const response: AxiosResponse<UserEntity> = await api.instance.get('/user').catch(error => Promise.reject({ callee: 'fetchUser', error }));
      dispatch(receiveMe(response.data));
    } catch (error) {
      Console.info('[Auth] - Login error');

      switch (error.callee) {
        case 'tokenExchange':
          Console.info('[Auth] - Error exchanging tokens');

          if (error.error.response && error.error.response.status === 401) {
            dispatch(authenticationFailure('Email / Password combination was incorrect'));
          } else {
            dispatch(authenticationFailure('Unknown error'));
          }
          break;

        case 'fetchUser':
          Console.info('[Auth] - Error fetching user');

          if (error.error.response && (error.error.response.status === 401 || error.error.response.status === 403)) {
            dispatch(authenticationFailure('Unauthenticated'));
          } else {
            dispatch(authenticationFailure('Unknown error'));
          }

          break;
        default:
          dispatch(authenticationFailure('Unknown error'));
          break;
      }

      Console.groupEnd();
      throw error;
    }
  };
};

export function logoutUser() {
  return async (dispatch: (action: Record<string, any>) => void) => {
    try {
      Console.group('[PACS] - Logging out');

      if (api.hasTokens()) {
        await api.instance.get('/user/logout').catch((error) => Promise.reject({ callee: 'apiLogout', error }));
      }

      dispatch(logoutSuccess());
    } catch (error) {
      Console.info('Logout error');
      dispatch(logoutFailure());

      throw error;
    } finally {
      api.destroyInstance();
      Console.groupEnd();
    }
  };
};

export function expireSession() {
  return async (dispatch: (action: Record<string, any>) => void) => {
    try {
      Console.group('[PACS] - Logging out, session expired');

      if (api.hasTokens()) {
        await api.instance.get('/user/logout').catch((error) => Promise.reject({ callee: 'apiLogout', error }));
      }

      dispatch(sessionExpired());
    } catch (error) {
      Console.info('Logout error');
      dispatch(logoutFailure());

      throw error;
    } finally {
      api.destroyInstance();
      Console.groupEnd();
    }
  };
};