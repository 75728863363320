// @ts-nocheck
import moment from 'moment';
import {
  isEmpty,
  isEqual,
  isNaN as _isNaN,
  isNil
} from 'lodash';

export function pad(value: number) {
  return (value < 10) ? '0' + value.toString() : value.toString();
};

/*
* Use to convert large positive numbers in to short form like 1K+, 100K+, 199K+, 1M+, 10M+, 1B+ etc
*
* @param value number
* @return string
*/
export function convertToAbbreviateNumber(value: number) {
  let valueFormatted = null;
  let suffix = null;

  if (value > 0 && value < 1000) {
    valueFormatted = Math.floor(value);
    suffix = '';
  } else if (value == 1000) {
    valueFormatted = Math.floor(value / 1000);
    suffix = 'K';
  } else if (value > 1000 && value < 1000000) {
    valueFormatted = Math.floor(value / 1000);
    suffix = 'K+';
  } else if (value == 1000000) {
    valueFormatted = Math.floor(value / 1000000);
    suffix = 'M';
  } else if (value > 1000000 && value < 1000000000) {
    valueFormatted = Math.floor(value / 1000000);
    suffix = 'M+';
  } else if (value == 1000000000) {
    valueFormatted = Math.floor(value / 1000000000);
    suffix = 'B';
  } else if (value > 1000000000 && value < 1000000000000) {
    valueFormatted = Math.floor(value / 1000000000);
    suffix = 'B+';
  } else if (value == 1000000000000) {
    valueFormatted = Math.floor(value / 1000000000000);
    suffix = 'T';
  } else if (value >= 1000000000000) {
    valueFormatted = Math.floor(value / 1000000000000);
    suffix = 'T+';
  }

  return (valueFormatted + suffix).length > 0 ? `${valueFormatted}${suffix}` : `0`;
};

export function getBase64FromBlob(blob: Blob) {
  return new Promise(resolve => {
    // Make new FileReader
    let reader = new FileReader();

    // Convert the file to base64 text
    reader.readAsDataURL(blob);
    reader.onload = () => {
      resolve(reader.result);
    };
  });
};

export function isHex(value: string) {
  return !!value && Boolean(value.match(/^#[0-9A-F]{6}$/i));
};

// https://github.com/kvz/locutus/blob/master/src/php/strings/number_format.js
export function number_format(number, decimals, decPoint, thousandsSep) {
  number = (number + '').replace(/[^0-9+\-Ee.]/g, '');
  const n = !isFinite(+number) ? 0 : +number;
  const prec = !isFinite(+decimals) ? 0 : Math.abs(decimals);
  const sep = (typeof thousandsSep === 'undefined') ? ',' : thousandsSep;
  const dec = (typeof decPoint === 'undefined') ? '.' : decPoint;
  let s = '';

  const toFixedFix = function (n, prec) {
    if (('' + n).indexOf('e') === -1) {
      return +(Math.round(n + 'e+' + prec) + 'e-' + prec);
    } else {
      const arr = ('' + n).split('e');
      let sig = '';
      if (+arr[1] + prec > 0) {
        sig = '+';
      }
      return (+(Math.round(+arr[0] + 'e' + sig + (+arr[1] + prec)) + 'e-' + prec)).toFixed(prec);
    }
  };

  // @todo: for IE parseFloat(0.55).toFixed(0) = 0;
  s = (prec ? toFixedFix(n, prec).toString() : '' + Math.round(n)).split('.');
  if (s[0].length > 3) {
    s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep);
  }
  if ((s[1] || '').length < prec) {
    s[1] = s[1] || '';
    s[1] += new Array(prec - s[1].length + 1).join('0');
  }

  return s.join(dec);
};

export function validEmail(value) {
  //eslint-disable-next-line
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(value).toLowerCase());
};

export function validatePasswordStrength(password = '', config = false) {

  const errors = [];
  const FALLBACKS = [
    {
      '^.{11,27}$': 'Must be between 12 - 28 characters long',
    },
    {
      '(?=.*[a-z])': 'Must contain at least one lower case letter',
    },
    {
      '(?=.*[A-Z])': 'Must contain at least one upper case letter',
    },
    {
      '(?=.*[0-9])': 'Must contain at least one number',
    },
    {
      '(?=.*[-\#\$\.\(\)\%\&\*])': 'Must contain at least one special character $ . % # * & -',
    }
  ];

  const configs = config || FALLBACKS;

  configs.forEach((config: any) => {
    const pattern = Object.keys(config)[0];
    const message = Object.values(config)[0];

    const regex = new RegExp(pattern);
    const result = regex.test(password.toString());

    if (result === false) {
      errors.push(message);
    }
  });

  return errors;
};

// (123) 456-7890
// +(123) 456-7890
// +(123)-456-7890
// +(123) - 456-7890
// +(123) - 456-78-90
// 123-456-7890
// 123.456.7890
// 1234567890
// +31636363634
// 075-63546725
export function validPhone(value) {
  //eslint-disable-next-line
  return /^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s\./0-9]*$/.test(value);
};

// foobar.txt -> foobar
export function filterFilenameExtention(value) {
  return value.replace(/\.[^/.]+$/, "");
};

// foobar.txt -> .txt
export function extractFilenameExtention(value) {
  //eslint-disable-next-line
  const re = /(?:\.([^.]+))?$/;
  return re.exec(value)[0];
};

export function parseBasicFieldValueToString(value: any): string {
  if (typeof value === 'string') {
    return value;
  } else if (typeof value === 'number') {
    return value.toString();
  }

  return '';
}

export function fieldIsModified(pastValue: any, newValue: any): boolean {
  // We need to check the type of the field value to compare correctly
  if (typeof pastValue === 'string' && typeof newValue === 'string') {
    // If the original value was empty, null or undefined and the new value is the same fields are not modified
    if (isEmpty(pastValue)) {
      if (isEmpty(newValue)) {
        return false;
      }

      return true;
    } else {
      // Do a simple equality check and return the false if the same
      return !isEqual(pastValue, newValue);
    }
  } else if (Array.isArray(pastValue) || Array.isArray(newValue)) {
    return !isEqual(pastValue, newValue);
  }

  return !isEqual(pastValue, newValue);
}

/**
 * Checks if `value` is `null`, `undefined`, string is empty, a number or an empty `array` or `object`.
 *
 * @category Lang
 * @param value The value to check.
 * @returns Returns `true` if `value` is nullish or `empty`, else `false`.
 * @example
 *
 * _.isBlank(null);
 * // => true
 *
 * _.isBlank(0);
 * // => false
 *
 * _.isBlank(NaN);
 * // => true
 *
 * _.isBlank('');
 * // => true
 *
 * _.isBlank([]);
 * // => true
 *
 * _.isBlank({});
 * // => true
 */
export function isBlank(value: any): boolean {
  if (typeof value === 'string') {
    return value.length === 0;
  }

  if (typeof value === 'number') {
    return _isNaN(value);
  }

  return isEmpty(value) || isNil(value);
};

/**
 * It iterates through each deep nested object and if finds object that has prop and value specified in objToFindBy
 * argument, it stops the walk and returns the object. If none is found, it returns false.
 *
 * https://github.com/brojd/obj-traverse
 *
 * @param tree object
 * @param childrenKey string
 * @param objToFindBy object
 * @returns Returns object or false
 *
 */
 export function findFirst(tree, childrenKey, objToFindBy) {
  const findKeys = Object.keys(objToFindBy);
  let treeToReturn = tree;
  let found = false;

  findKeys.forEach((key) => {
    isEqual(tree[key], objToFindBy[key]) ? found = true : found = false;
  });

  if (found) {
    return tree;
  }

  const findInChildren = (obj, childrenKey, objToFindBy) => {
    let foundInChild = false;
    if (obj.hasOwnProperty(childrenKey) && obj[childrenKey]) {
      for (let i = 0; i < obj[childrenKey].length; i++) {
        //eslint-disable-next-line
        findKeys.forEach((key) => {
          isEqual(obj[childrenKey][i][key], objToFindBy[key]) ? foundInChild = true : foundInChild = false;
        });
        if (foundInChild) {
          found = true;
          treeToReturn = obj[childrenKey][i];
          break;
        }
      }
      if (!foundInChild && !found) {
        obj[childrenKey].forEach(child => findInChildren(child, childrenKey, objToFindBy));
      }
    }
    return obj;
  };

  findInChildren(tree, childrenKey, objToFindBy);

  return found ? treeToReturn : false;
};

/**
 * It iterates through each deep nested object and if finds object that has prop and value specified in objToFindBy
 * argument, it replaces the current object with replacementObj, stops recursive walk and returns the whole tree.
 * If none is found, it returns false.
 *
 * https://github.com/brojd/obj-traverse
 *
 * @param tree object
 * @param childrenKey string
 * @param objToFindBy object
 * @param replacementObj object
 * @returns Returns object or false
 *
 */
export function findAndModifyFirst(tree, childrenKey, objToFindBy, replacementObj) {
  const findKeys = Object.keys(objToFindBy);
  let treeToReturn = tree;
  let findSuccess = false;
  let modifiedObj = false;

  findKeys.forEach((key) => {
    isEqual(tree[key], objToFindBy[key]) ? findSuccess = true : findSuccess = false;
  });

  if (findSuccess) {
    for (let prop in tree) {
      delete tree[prop];
    }
    for (let prop in replacementObj) {
      tree[prop] = replacementObj[prop];
    }
    return tree;
  }

  const findInChildren = (obj, childrenKey, objToFindBy, replacementObj) => {
    if (obj.hasOwnProperty(childrenKey) && obj[childrenKey] !== null) {
      for (let i = 0; i < obj[childrenKey].length; i++) {
        //eslint-disable-next-line
        findKeys.forEach((key) => {
          isEqual(obj[childrenKey][i][key], objToFindBy[key]) ? findSuccess = true : findSuccess = false;
        });

        if (findSuccess) {
          obj[childrenKey][i] = replacementObj;
          modifiedObj = true;
          break;
        }
      }
      if (!findSuccess) {
        obj[childrenKey].forEach(child => findInChildren(child, childrenKey, objToFindBy, replacementObj));
      }
    }
    return obj;
  };

  findInChildren(tree, childrenKey, objToFindBy, replacementObj);

  return modifiedObj ? treeToReturn : false;
};

/**
/* It iterates through each deep nested object and for every found object that has prop and value specified in
 * objToFindBy argument, it replaces the current object with replacementObj and returns the whole tree.
 * If none is found, it returns false.
 *
 * https://github.com/brojd/obj-traverse
 *
 * @param tree object
 * @param childrenKey string
 * @param objToFindBy object
 * @param replacementObj object
 * @returns Returns object or false
 *
 */
 export function findAndModifyAll(tree, childrenKey, objToFindBy, replacementObj) {
  let found = false;
  function innerFunc(tree, childrenKey, objToFindBy, replacementObj) {
    const findKeys = Object.keys(objToFindBy);
    let findSuccess = false;
    findKeys.forEach((key) => {
      isEqual(tree[key], objToFindBy[key]) ? findSuccess = true : findSuccess = false;
    });
    if (findSuccess) {
      for (let prop in tree) {
        delete tree[prop];
      }
      for (let prop in replacementObj) {
        tree[prop] = replacementObj[prop];
      }
      found = true;
    } else if (tree.hasOwnProperty(childrenKey)) {
      for (let n of tree[childrenKey]) {
        innerFunc(n, childrenKey, objToFindBy, replacementObj);
      }
    }
  }
  innerFunc(tree, childrenKey, objToFindBy, replacementObj);
  return found ? tree : false;
};

/**
 * It iterates through each deep nested object and if finds object that has prop and value specified in objToFindBy
 * argument, it deletes it, stops the walk and returns the whole tree.
 * If none is found, it returns false.
 *
 * https://github.com/brojd/obj-traverse
 *
 * @param tree object
 * @param childrenKey string
 * @param objToFindBy object
 * @param replacementObj object
 * @returns Returns object or false
 *
 */
export function findAndDeleteFirst(tree, childrenKey, objToFindBy) {
  let treeToReturn = tree;
  let modifiedObj = false;

  const findInChildren = (obj, childrenKey, objToFindBy) => {
    const findKeys = Object.keys(objToFindBy);
    let findSuccess = false;

    if (obj.hasOwnProperty(childrenKey)) {
      for (let i = 0; i < obj[childrenKey].length; i++) {
        //eslint-disable-next-line
        findKeys.forEach((key) => {
          isEqual(obj[childrenKey][i][key], objToFindBy[key]) ? findSuccess = true : findSuccess = false;
        });

        if (findSuccess) {
          obj[childrenKey].splice(i, 1);
          modifiedObj = true;
          break;
        }
      }
      if (!findSuccess) {
        obj[childrenKey].forEach(child => findInChildren(child, childrenKey, objToFindBy));
      }
    }
    return obj;
  };

  findInChildren(tree, childrenKey, objToFindBy);

  return modifiedObj ? treeToReturn : false;
};

export function isNumeric(value: string | number) {
  if (typeof value !== 'string' && typeof value !== 'number') return false; // we only process strings and numbers
  return !isNaN(value) && !isNaN(parseFloat(value));
};

export function isBlankNumeric(value: any) {
  if (typeof value !== 'string' && typeof value !== 'number') return false; // we only process strings and numbers
  return !isNaN(value) && !isNaN(parseFloat(value)) && parseFloat(value) === 0;
};

/**
 * @description determine if an array contains one or more items from another array.
 * @param {array} arr the array providing items to check for in the haystack.
 * @param {array} haystack the array to search.
 * @return {boolean} true|false if haystack contains at least one item from arr.
 */
export function contains(arr: Array<string | number>, haystack: Array<string | number>): boolean {
  return arr.some((item: string | number) => haystack.includes(item));
};

/**
 *
 * @param num number of minutes
 * @returns Returns the number of minutes in hours and minutes
 */
export function timeConvert(num: number = 0): { hours: number, minutes: number } {
  const hours = (num / 60);
  const rhours = Math.floor(hours);
  const minutes = (hours - rhours) * 60;
  const rminutes = Math.round(minutes);
  return { hours: rhours, minutes: rminutes };
};

/**
 *
 * @param from date
 * @param to date
 * @returns Returns number of years, months, days, hours, minutes and seconds between two moment objects
 */
export function dateTimeDifference(from: moment.Moment, to: moment.Moment): {
  years: number,
  months: number,
  days: number,
  hours: number,
  minutes: number,
  seconds: number
} {
  const years = to.diff(from, 'year');
  from.add(years, 'years');

  const months = to.diff(from, 'months');
  from.add(months, 'months');

  const days = to.diff(from, 'days');
  from.add(days, 'days');

  const hours = to.diff(from, 'hours');
  from.add(hours, 'hours');

  const minutes = to.diff(from, 'minutes');
  from.add(minutes, 'minutes');

  const seconds = to.diff(from, 'seconds');

  return {
    years,
    months,
    days,
    hours,
    minutes,
    seconds
  };
};

export function formatCostToNumber(value: string, thousandSeparator: string, decimalSeparator: string): number {
  if (!value) return 0;

  // array of values separated by thousands separator
  const splitedValue: string[] = value.split(thousandSeparator);

  // right side value after thousands separator
  const rightSide: string = splitedValue.splice(splitedValue.length - 1, 1)?.[0];

  // right side value converted to number
  const parsedRightSide: number = parseFloat(rightSide.replaceAll(decimalSeparator, '.'));

  // if there is no left side of the value
  if (_.isEmpty(splitedValue)) {
    return parsedRightSide;
  }

  const thousands: string = (splitedValue.splice(splitedValue.length - 1, 1)[0]).concat('000');

  const millions: string = splitedValue.join('');

  return Number(millions.concat(thousands)) + parsedRightSide;
};

export function nestedSet(data: any = []) {
  return !_.isEmpty(data) ? data.map((entity: any, index: number) => {
    const appendChildrenKeys = (children: any) => {

      // Prevent nesting
      if (_.isEmpty(children)) return null;

      return children.map((childEntity: any, _index: number) => {
        return {
          ...childEntity,
          'key': childEntity?.key || childEntity?.id || _index,
          'id': childEntity.id,
          'value': childEntity.id,
          'title': childEntity.title,
          'children': appendChildrenKeys(childEntity.children),
        };
      });
    };

    return {
      ...entity,
      'key': entity?.key || entity?.id || index,
      'id': entity.id,
      'value': entity.id,
      'title': entity.title,
      'children': appendChildrenKeys(entity.children),
    };
  }) : [];
};

export function flattenSet(data: any = []) {

  const collector: any = [];

  data.forEach((value: any) => {
    const check = (_value: any) => {
      collector.push({ ..._value });

      if (!!_value.children && _.has(_value, 'children') && !_.isEmpty(_value.children)) {
        _value.children.forEach((__value: any) => {
          check(__value);
        });
      }
    };

    return check(value);
  });

  return collector;
};

export function modifyNestedSetItem(key: string | number, newValue: any, data: any[]) {
  return data.map((value: any) => {

    if (value?.id === key || value?.key === key) {
      value = newValue;
    }

    return {
      ...value,
      'children': !_.isEmpty(value.children) ? modifyNestedSetItem(key, newValue, value.children) : null,
    };
  });
};

export function removeNestedSetItem(key: string | number, data: any[]) {
  return data
    .filter((value: any) => value?.id !== key && value?.key !== key)
    .map((value: any) => {
      return {
        ...value,
        'children': !_.isEmpty(value.children) ? removeNestedSetItem(key, value.children) : null,
      };
    });
};

export function changeNestedOrder(info: any, data: any[]) {
  const dropKey = info.node.key;
  const dragKey = info.dragNode.key;
  const dropPos = info.node.pos;
  const dropPosition = info.dropPosition - dropPos;

  let dragObj: any;

  const loop = (data: any, key: any, callback: any) => {
    for (let i = 0; i < data.length; i++) {
      if (data[i].key === key) {
        return callback(data[i], i, data);
      }
      if (data[i].children) {
        loop(data[i].children, key, callback);
      }
    }
  };

  // Find dragObject
  loop(data, dragKey, (item: any, index: any, arr: any) => {
    arr.splice(index, 1);
    dragObj = item;
  });

  if (!info.dropToGap) {
    // Drop on the content
    loop(data, dropKey, (item: any) => {
      item.children = item.children || [];
      return item.children.unshift(dragObj);
    });
  } else if (
    (info.node?.children || []).length > 0 && // Has children
    dropPosition === 1 // On the bottom gap
  ) {
    loop(data, dropKey, (item: any) => {
      item.children = item.children || [];
      item.children.unshift(dragObj);
    });
  } else {
    let ar: any;
    let i: any;
    loop(data, dropKey, (_: any, index: any, arr: any) => {
      ar = arr;
      i = index;
    });
    if (dropPosition === -1) {
      ar.splice(i, 0, dragObj);
    } else {
      ar.splice(i + 1, 0, dragObj);
    }
  }

  return data;
};