// Libs
import React from 'react';

// Components
import { Form, Input, Modal, Select } from "antd";

// Services
import { Api } from 'services/api';
import Notification from 'services/notification';

// Interfaces
import { MetricSet } from 'components/kpi-library/KpiLibrary.interfaces';

const API: Api = new Api();

interface Props {
  client_id: number;
  entity_id?: number;
  entity_bundle?: string;
  entity_type?: string;
  kpiMetricSets?: any;
  onClose: (createdMetricSet?: MetricSet) => void;
};

interface State {
  isCreatingMetricSet: boolean;
  kpiSet: number | null;
};

class CreateMetricSetModal extends React.Component<Props> {

  mounted: boolean = false;
  createFormRef: any = React.createRef();

  state: State = {
    isCreatingMetricSet: false,
    kpiSet: null
  };

  componentDidMount = () => {
    this.mounted = true;
  };

  componentWillUnmount = () => {
    this.mounted = false;
  };

  render = () => {
    const { client_id, entity_id, entity_type, entity_bundle, kpiMetricSets, onClose } = this.props;
    const { kpiSet, isCreatingMetricSet } = this.state;
    return (
      <Modal
        visible
        centered
        title={ 'Create Metric Set' }
        maskClosable={ !isCreatingMetricSet }
        okText={ 'Create' }
        onOk={ () => {
          this.createFormRef
            .current
            .validateFields()
            .then( async (values: any) => {
              try {
                await new Promise((resolve) => this.setState({ isCreatingMetricSet: true }, () => resolve(null)));

                const createdMetricSet: MetricSet = await API.post(`client/${client_id}/kpi-library/metric-set`, {
                  data: {
                    title: values.title,
                    metric_set: values.metric_set,
                    entity_id: entity_id,
                    entity_type: entity_type,
                    entity_bundle: entity_bundle,
                  }
                });

                Notification('success', 'Kpi metric set have been created.', 'Kpi Metric Set Created');

                onClose(createdMetricSet);

              } catch (error) {
                Notification('error', '', 'Failed');
              } finally {
                this.mounted && this.setState({
                  isCreatingMetricSet: false,
                  showMetricSetCreateDialog: false
                });
              }
            })
            .catch((info: any) => {
              console.error('Invalid state');
            });
        } }
        onCancel={ () => onClose() }
        cancelButtonProps={ {
          disabled: isCreatingMetricSet
        } }
        okButtonProps={ {
          disabled: false,
          loading: isCreatingMetricSet,
        } }
      >
        <Form
          ref={ this.createFormRef }
          layout="vertical"
          initialValues={ {
            title: '',
          } }
        >
          <Form.Item
            label="Title"
            name="title"
            rules={ [{ required: true, message: 'Required' }] }
          >
            <Input />
          </Form.Item>
          { kpiMetricSets && <Form.Item label={ 'Kpi Set' } name="metric_set" required>
            <Select
              showSearch
              placeholder={ 'Select Kpi Set' }
              filterOption={ (input: any, option: any) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 }
              onChange={(_kpiSet: any) => {
                this.setState({
                  kpiSet: _kpiSet
                });
              }}
              value={ kpiSet }
            >
              {
                Object.entries(kpiMetricSets).map((kpiMetricSet: any) => (
                  kpiMetricSet[1].sets.length && <Select.OptGroup label={ kpiMetricSet[1].label } key={ kpiMetricSet[0] }>
                    { kpiMetricSet[1].sets.map((set: any) => (
                      <Select.Option key={ `metric-set-${set.id}` } value={ set.id }>
                        { set.title }
                      </Select.Option>
                    ) ) }
                  </Select.OptGroup>
                ))
              }
            </Select>
          </Form.Item> }
        </Form>
      </Modal>
    );
  };
};

export default CreateMetricSetModal;