// Libs
import * as React from 'react';
import { createPortal } from 'react-dom';
import classNames from 'classnames';

// Components
import BlockingSpinner from 'components/blocking-spinner';

// Styles
import './CoverModal.scss';

interface Props {
  cover?: boolean;
  transparent?: boolean;
  lightBackdrop?: boolean;
  closeOnTop?: boolean;
  showCloseIcon?: boolean;
  isLoading?: boolean;
  disableBodyScroll?: boolean;
  enableCloseOutside?: boolean;
  children: React.ReactNode;
  leftContent?: React.ReactNode;
  middleContent?: React.ReactNode;
  rightContent?: React.ReactNode;
  style?: React.CSSProperties;
  onClose(): void;
};

const portal: any = document.getElementById('portal');

export default class CoverModal extends React.Component<Props> {

  modal: any;

  constructor(props: Props) {
    super(props);
    this.modal = document.createElement('div');

    // Fixes problem with scrolling background content on touch devices.
    // Does not work on iOS 11. Only confirmed to work on Android 8.
    this.props.disableBodyScroll && document.body.classList.add('ovY-h');
  }

  componentDidMount() {
    portal.appendChild(this.modal);
  };

  componentWillUnmount = () => {
    portal.removeChild(this.modal);

    // Fixes problem with scrolling background content on touch devices.
    // Does not work on iOS 11. Only confirmed to work on Android 8.
    this.props.disableBodyScroll && document.body.classList.remove('ovY-h');
  };

  handleModalClose = () => {
    this.props.onClose && this.props.onClose();
  };

  handleModalClick = () => {
    this.props.enableCloseOutside && this.props.onClose && this.props.onClose();
  };

  handleContainerClick = (event: any) => {
    event.stopPropagation();
  };

  render = () => {
    const {
      cover,
      transparent,
      style,
      children,
      lightBackdrop,
      closeOnTop,
      leftContent,
      middleContent,
      rightContent,
      isLoading,
      showCloseIcon = true,
    } = this.props;

    const modalClasses = classNames('Modal', {
      'Modal--cover': cover,
      'Modal--dialog': !cover,
      'Modal--lightBackdrop': lightBackdrop,
    });

    const containerClasses = classNames('Modal-container', {
      'u-animationBounceIn': !cover,
      'u-animationSlideIn': cover && !transparent,
      'bg-grey-100': !transparent,
    });

    return createPortal((
      <div
        className={ modalClasses }
        onClick={ this.handleModalClick }
      >
        <div
          className={ containerClasses }
          onClick={ this.handleContainerClick }
          style={ style }
        >
          <div className="h-100p">
            <div className="d-f fxd-c h-100p">
              <div className="fx-n ov-h">
                <div className="Modal-navigation">
                  <div className="Modal-navigation-side">
                    { leftContent }
                  </div>
                  <div className="Modal-navigation-middle">
                    { middleContent }
                  </div>
                  <div className="Modal-navigation-side">
                    { showCloseIcon && !rightContent &&
                      <div className="fl-r">
                        <div
                          className={ classNames('Modal-navigation-close', {
                            'Modal-close-align-top': closeOnTop
                          }) }
                          onClick={ this.handleModalClose }
                        />
                      </div>
                    }
                    { !!rightContent && rightContent }
                  </div>
                </div>
              </div>
              <div className="fx-a ovY-a">
                <BlockingSpinner isLoading={ isLoading || false }>
                  { children }
                </BlockingSpinner>
              </div>
            </div>
          </div>
        </div>
      </div>
    ), portal);
  };
};