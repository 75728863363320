// Libs
import * as React from "react";
import _ from 'lodash';

// Components
import { Button, Form, Table, Select, Steps } from 'antd';
import CoverModal from 'components/cover-modal';
import FormWrapper from 'components/form/form-wrapper';

// Services
import { Api } from 'services/api';

// Interfaces
import { FormField } from 'components/form/form-wrapper';

interface IField extends FormField {
  entity_type: string;
  entity_bundle: string;
};

interface IEntity {
  id: number;
  type: string;
  bundle: string;
};

interface IVerifyResponse {
  entity_title: string;
  current_value: string | number | null;
  new_value: string | number | null;
};

interface Props {
  clientId?: number;
  runEndpoint: string;
  checkEndpoint: string;
  entities: IEntity[];
  onClose: () => void;
  onSuccess: (response: any) => void;
};

interface State {
  field: number | null;
  activeStep: number;
  availableFields: IField[];
  formTemplate: {
    form: any;
    form_config: any;
  } | null;
  fieldConfig: FormField | null;
  verifyResponse: IVerifyResponse[];
  placeholder: {
    fieldId: string | null;
    record: any | null;
  };
  isVerifying: boolean;
  isRunning: boolean;
  isFetchingAvailableFields: boolean;
  isFetchingFormTemplate: boolean;
};

const API: Api = new Api();
const { Step } = Steps;

class ChangeFieldValueModal extends React.Component<Props, State> {
  mounted: boolean = false;

  state: State = {
    field: null,
    activeStep: 1,
    availableFields: [],
    fieldConfig: null,
    verifyResponse: [],
    placeholder: {
      fieldId: null,
      record: null,
    },
    formTemplate: null,
    isFetchingFormTemplate: false,
    isFetchingAvailableFields: false,
    isVerifying: false,
    isRunning: false,
  };

  componentDidMount = async () => {
    const { clientId, entities } = this.props;

    this.mounted = true;

    try {
      if (!clientId) throw new Error('Failed');

      await new Promise((resolve) => this.setState({ isFetchingAvailableFields: true }, () => resolve(null)));

      const availableFields = await API.post(`client/${clientId}/bulk-operations/change-field-value/available-fields`, {
        entities: entities
      });

      this.mounted && this.setState({
        availableFields: availableFields
      });

    } catch (error) {
      console.error('Error: ', error);
    } finally {
      this.mounted && this.setState({
        isFetchingAvailableFields: false,
      });
    }
  };

  componentWillUnmount = () => {
    this.mounted = false;
  };

  renderStepOne = () => {
    const { clientId } = this.props;
    const { availableFields, placeholder, isFetchingAvailableFields, isFetchingFormTemplate } = this.state;
    const field = availableFields.find((field: IField) => field.id === placeholder.fieldId);

    return (
      <div>
        <div className="d-f fxd-c">
          <p className="mB-30">Please select the field you'd like to modify.</p>
          <Form layout="vertical">
            <Form.Item
              label="Field"
              className="fx-1"
              required
            >
              <Select
                showSearch
                placeholder={ '-' }
                disabled={ isFetchingAvailableFields || isFetchingFormTemplate }
                loading={ isFetchingAvailableFields || isFetchingFormTemplate }
                filterOption={ (input: any, option: any) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 }
                onChange={(fieldId: string) => {
                  this.setState({
                    placeholder: {
                      ...placeholder,
                      fieldId: fieldId,
                    }
                  });
                }}
                value={ placeholder.fieldId || undefined }
              >
                { availableFields.map((field: IField, index: number) => (
                  <Select.Option key={ `${field.id}-${index}` } value={ field.id }>
                    { field.label }
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Form>
        </div>
        <div className="d-f jc-fe mT-20 mB-20">
          { !placeholder?.fieldId ? (
            <Button disabled type="primary">Next</Button>
          ) : (
            <Button
              type="primary"
              loading={ isFetchingAvailableFields || isFetchingFormTemplate }
              onClick={ async () => {
                try {

                  await new Promise((resolve) => this.setState({ isFetchingFormTemplate: true }, () => resolve(null) ));

                  const formTemplate = await API.get(`client/${clientId}/bulk-operations/change-field-value/field-form`, {
                    type: field?.entity_type,
                    bundle: field?.entity_bundle,
                    field_id: placeholder.fieldId
                  });

                  this.mounted && this.setState({
                    activeStep: 2,
                    formTemplate: formTemplate
                  });
                } catch {
                  console.error('Failed to fetch data');
                } finally {
                  this.mounted && this.setState({
                    isFetchingFormTemplate: false,
                  });
                }
              } }
            >
              Next
            </Button>
          ) }
        </div>
      </div>
    );
  };

  renderStepTwo = () => {
    const { clientId, entities } = this.props;
    const { availableFields, placeholder, formTemplate, fieldConfig, isFetchingFormTemplate, isVerifying } = this.state;
    const field = availableFields.find((field: IField) => field.id === placeholder.fieldId);

    if (!formTemplate || !field || !clientId) return <></>;

    const record: any = {
      title: null,
      version: 1,
      form: formTemplate.form,
      form_config: formTemplate.form_config,
      type: field?.entity_type,
      bundle: field.entity_bundle
    };

    return (
      <div>
        <div className="d-f fxd-c">
          <p className="mB-30">Please select a new value for <b>{ field?.label }</b></p>
          <FormWrapper
            pure
            clientId={ clientId }
            canView
            canEdit
            canCreate={ false }
            isLockedTitle
            record={ record }
            onCreate={ () => {} }
            onChange={ modifiedRecord => {
              this.setState({
                placeholder: {
                  ...this.state.placeholder,
                  record: modifiedRecord,
                }
              });
            } }
            entity={ placeholder.fieldId || undefined }
          />
        </div>
        <div className="d-f jc-fe mT-20 mB-20">
          <span className="mR-10">
            <Button
              disabled={ isFetchingFormTemplate || isVerifying }
              type="default"
              onClick={ () => {
                this.setState({
                  activeStep: 1,
                  placeholder: {
                    ...placeholder,
                    record: null
                  }
                });
              } }
            >
              Previous
            </Button>
          </span>
          <span>
            { !placeholder?.record ? (
              <Button disabled type="primary">Preview</Button>
            ) : (
              <Button
                type="primary"
                loading={ isVerifying }
                onClick={ async () => {
                  try {

                    await new Promise((resolve) => this.setState({ isVerifying: true }, () => resolve(null) ));

                    const verifyResponse = await API.post(`client/${clientId}/bulk-operations/change-field-value/verify`, {
                      config: {
                        field_id: this.state.placeholder.fieldId,
                        record: this.state.placeholder.record,
                      },
                      entities: entities
                    });

                    this.mounted && this.setState({
                      verifyResponse: verifyResponse,
                      activeStep: 3,
                    });
                  } catch {
                    console.error('Failed to verify');
                  } finally {
                    this.mounted && this.setState({
                      isVerifying: false
                    });
                  }
                } }
              >
                Preview
              </Button>
            ) }
          </span>
        </div>
      </div>
    );
  };

  renderStepThree = () => {
    const { clientId, entities, onSuccess } = this.props;
    const { placeholder, availableFields, verifyResponse, isRunning } = this.state;
    const field = availableFields.find((field: IField) => field.id === placeholder.fieldId);

    return (
      <div>
        <div className="d-f fxd-c">
          <Table
            size={ 'small' }
            columns={ [
              {
                title: 'Entity',
                key: 'entity',
                render: (verifyResponse: IVerifyResponse) => {
                  return (
                    <span>{ verifyResponse?.entity_title || '-' }</span>
                  );
                }
              },
              {
                title: 'Field',
                key: 'field',
                render: () => {
                  return (
                    <span>{ field?.label }</span>
                  );
                }
              },
              {
                title: 'Before',
                key: 'before',
                render: (verifyResponse: IVerifyResponse) => {
                  return (
                    <span className="bg-inverse-danger pT-2 pB-2 pL-10 pR-10">{ verifyResponse?.current_value || '-' }</span>
                  );
                }
              },
              {
                title: 'After',
                key: 'after',
                render: (verifyResponse: IVerifyResponse) => {
                  return (
                    <span className="bg-inverse-success pT-2 pB-2 pL-10 pR-10">{ verifyResponse?.new_value || '-' }</span>
                  );
                }
              }
            ] }
            dataSource={ verifyResponse.map((verifyResponse: IVerifyResponse, index: number) => {
              return {
                ...verifyResponse,
                key: index
              };
            } ) }
            pagination={ false }
          />
        </div>
        <div className="d-f jc-fe mT-20 mB-20">
          <span className="mR-10">
            <Button
              type="default"
              onClick={ () => {
                this.setState({
                  activeStep: 2,
                });
              } }
            >
              Previous
            </Button>
          </span>
          <span>
            { !placeholder?.record ? (
              <Button disabled type="primary">Run</Button>
            ) : (
              <Button
                type="primary"
                loading={ isRunning }
                onClick={ async () => {
                  try {

                    await new Promise((resolve) => this.setState({ isRunning: true }, () => resolve(null) ));

                    const response = await API.post(`client/${clientId}/bulk-operations/change-field-value/run`, {
                      config: {
                        field_id: this.state.placeholder.fieldId,
                        record: this.state.placeholder.record,
                      },
                      entities: entities
                    });

                    onSuccess(response);
                  } catch {
                    console.error('Failed to verify');
                  } finally {
                    this.mounted && this.setState({
                      isRunning: false
                    });
                  }
                } }
              >
                Run
              </Button>
            ) }
          </span>
        </div>
      </div>
    );
  };

  render = () => {
    const { activeStep } = this.state;

    return (
      <CoverModal
        style={{ width: '80vw', height: '80vh' }}
        closeOnTop
        cover
        middleContent={
          <div className="mT-30 mB-30">
            <Steps current={ activeStep - 1 }>
              <Step title="Select Field" />
              <Step title="New Value" />
              <Step title="Preview Changes" />
            </Steps>
          </div>
        }
        onClose={ () => {
          this.props.onClose();
        }}
      >
        <div className="d-f ai-c jc-c mT-100">
          <div className="w-90p">
            { activeStep === 1 && this.renderStepOne() }
            { activeStep === 2 && this.renderStepTwo() }
            { activeStep === 3 && this.renderStepThree() }
          </div>
        </div>
      </CoverModal>
    );
  };
};

export default ChangeFieldValueModal;
