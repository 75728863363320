// Libs
import * as React from 'react';

// Views
import KpiLibrary from 'views/workplace-service/KpiLibrary';

// Services
import { Api } from 'services/api';

// Interfaces
import { RecordFormEntity } from 'types/entities';

const API: Api = new Api();

interface Props {
  client_id: number;
  record: RecordFormEntity;
};

interface State {
  isFetching: boolean;
  createDropdownKpiMetricSets: any[];
};

class ContractKpiLibrary extends React.Component<Props, State> {

  mounted: boolean = false;
  state: State = {
    isFetching: false,
    createDropdownKpiMetricSets: []
  };

  componentDidMount = async () => {
    const { client_id, record } = this.props;

    this.mounted = true;

    try {
      await new Promise((resolve) => this.setState({ isFetching: true }, () => resolve(null)));
      const response = await API.get(`client/${client_id}/kpi-library/kpi-metric-sets`, {
        entity_id: record.id,
        entity_bundle: record.bundle,
        entity_type: record.type
      });

      this.mounted && this.setState({
        createDropdownKpiMetricSets: response.kpi_metric_sets
      });

    } catch (error) {
      console.error('Error: ', error);
    } finally {
      this.mounted && this.setState({
        isFetching: false
      });
    }
  };

  render = () => {
    const { record } = this.props;
    const { createDropdownKpiMetricSets } = this.state;

    return (
      <>
        <KpiLibrary
          record={ record }
          breadcrumbs={ [{ title: 'Home', path: '/' }, { title: 'Suppliers', path: '/suppliers' }, { title: 'Supplier List', path: '/suppliers/suppliers' }] }
          createDropdownKpiMetricSets={ createDropdownKpiMetricSets }
        />
      </>
    );
  };
};

export default ContractKpiLibrary;
