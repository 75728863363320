// Libs
import * as React from 'react';

// Components
import RecordView from 'views/common/RecordView';

// Interfaces
import { RecordFormEntity } from 'types/entities';

interface Props {
  record: RecordFormEntity;
  setRecord: (record: RecordFormEntity) => void;
};

class LeaseDisposalRecord extends React.Component<Props> {
  render = () => {
    return <RecordView entity={ 'transaction' } type={ 'lease-disposal' } id={ this.props.record.id } record={ this.props.record } setRecord={ this.props.setRecord } />;
  };
};

export default LeaseDisposalRecord;
