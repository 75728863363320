export interface KpiMetric {
  id: number;
  title: string;
  config: any;
  status: MetricStatus;
  kpi_metric_set_id: number;
  kpi_metric_type_id: number;
  kpi_priority_set_id: number;
  version: number;
  version_status: string;
  version_history: any | null;
  kpis_count: number;
  kpi_metric_set: KpiMetricSet;
  kpi_priority_set: KpiPrioritySet;
  kpi_metric_type: KpiMetricType;
  kpi_metric_levels: KpiMetricLevel[];
  kpi_metric_type_title?: string;
  kpi_priority_set_title?: string;
  kpi_priority_set_description?: string;
  isNewMetric?: boolean;
};

export interface KpiMetricRow {
  id: number;
  key: string;
  metric_set_id: number;
  title: string;
  type: string;
  priority_set: string;
};

export interface KpiMetricSet {
  id: number;
  title: string;
  reference: string;
};

export interface MetricSet {
  id: number;
  active_metrics: number;
  title: string;
  creator: string;
  reference: string;
  updated_at: string;
};

export interface KpiMetricSetRow {
  id: number;
  key: string;
  title: string;
  creator: string;
  active_metrics: number;
  updated_at: string;
};

export interface KpiPrioritySet {
  id: number;
  title: string;
  description: string;
  kpi_priorities: KpiPriority[];
  kpi_metric_types: KpiMetricType[],
  reference: string;
};

export enum MetricType {
  HelpDesk = 'help_desk',
  Budget = 'budget'
};

export enum MetricStatus {
  Approved = 'APPROVED',
  Pending = 'PENDING',
  Draft = 'DRAFT'
};

export interface KpiMetricType {
  id: number;
  title: string;
  reference: string;
  type: MetricType;
};

export interface KpiMetricLevel {
  id: number;
  description: string | null;
  target: number;
  target_description: string | null;
  measure: { minutes?: number; from?: number; to?: number; } | null;
  config: any;
  kpi_metric_id: number;
  kpi_priority_id: number;
  kpi_priority: KpiPriority;
};

export interface KpiPriority {
  id: number;
  title: string;
  reference: string;
  description: string;
  kpi_priority_set_id: number;
  order: number;
  created_at: string;
  updated_at: string;
  deleted_at: string | null;
  can_edit?: boolean;
  can_delete?: boolean;
};

export interface Modified {
  title?: boolean;
  kpi_metric_type_id?: boolean;
  kpi_priority_set_id?: boolean;
  kpi_metric_levels?: { [key: number]: string[] };
};

export interface ModifiedState {
  [key: number]: Modified;
};

export interface ErrorState {
  [key: number]: Modified;
};

export interface MetricLevelRow {
  id: KpiMetricLevel['id'];
  priority: KpiPriority['title'];
  priority_description: KpiPriority['description'];
  description: KpiMetricLevel['description'];
  target: KpiMetricLevel['target'];
  target_description: KpiMetricLevel['target_description'];
  measure: KpiMetricLevel['measure'];
};

export interface KpiRecord {
  id: number;
  title: string;
  status: KpiRecordStatus;
  priority: string;
  description: string;
  measure: any;
};

export enum KpiRecordStatus {
  Pass = 'PASS',
  Fail = 'FAIL',
  Pending = 'PENDING'
};

export type MetricOnCreate = (payload: any, callback?: () => void) => Promise<void>;

export type MetricOnEdit = (metricSetId: number, metricId: KpiMetric['id'], payload: any, callback?: () => void) => Promise<void>;

export type MetricOnPublish = (metricSetId: number, metricId: number) => Promise<void>;

export type MetricOnCreateVersion = (metricSetId: number, metricId: number) => Promise<void>;
