// Libs
import React from 'react';
import classNames from 'classnames';
import NumberFormat from 'react-number-format';
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';
import _ from 'lodash';

// Components
import FieldWrapper from 'components/form/field/field-wrapper';
import { Select, Button, Popconfirm, Typography, Table, Input, Tooltip, TreeSelect } from 'antd';
import FteModal from './FteModal';
import BusinessHoursModal from './BusinessHoursModal';

// Icons
import { PlusOutlined, DeleteOutlined, QuestionCircleOutlined, SisternodeOutlined } from '@ant-design/icons';
import { ReactComponent as WarningIcon } from 'assets/svg/warning-triangle.svg';

// Services
import { getFormatedNumber } from 'services/settings';

// Utils
import { findFirst, findAndModifyFirst, isBlank } from 'utils/utils';

// Interfaces
import {
  FormField,
  FormFieldConfig,
  FormFieldInfoBoxModifiedMessage,
  FormFieldInfoBoxErrorMessage,
} from 'components/form/form-wrapper';

// Styles
import './SpaceDefinition.scss';

const { Option, OptGroup } = Select;
const { Link, Text } = Typography;
const { SHOW_PARENT } = TreeSelect;

export enum Alignment {
  Center = 'center',
  Left = 'left',
  Right = 'right'
};

const getBlankState: any = (entity: any, default_opening_hours: any) => {
  return {
    key: uuidv4(),
    id: null,
    title: '',
    type_id: _.has(entity, 'type_id') ? entity.type_id : null,
    status_id: _.has(entity, 'status_id') ? entity.status_id : null,
    occupier: null,
    area: null,
    opening_hours: _.has(entity, 'opening_hours') && !_.isEmpty(entity.opening_hours) ? _.cloneDeep(entity.opening_hours) : default_opening_hours,
    children: [],
  };
};

interface Props {
  originalValues: any;
  errors?: Record<string, string[]>;
  numberFormat: any;
  field: FormField;
  onChange(
    state: any
  ): void;
  config: FormFieldConfig;
  isDisabled?: boolean;
  setFieldModifiedMessage(id: string, message?: FormFieldInfoBoxModifiedMessage): void;
  setFieldErrorMessage(id: string, message?: FormFieldInfoBoxErrorMessage): void;
  border?: boolean;
};

interface State {
  deleteConfirmId: any;
  activeFteKey: any;
  errors: any[];
  modified: any[];
  exceededFields: any[];
  isModified: boolean;
  expandedRowKeys: any;
  activeBusinessHourKey: number | null;
  occupierFilter: any;
};

class SpaceDefinition extends React.Component<Props, State> {

  state: State = {
    deleteConfirmId: null,
    activeFteKey: null,
    errors: [],
    modified: [],
    exceededFields: [],
    isModified: false,
    expandedRowKeys: {},
    activeBusinessHourKey: null,
    occupierFilter: null,
  };

  componentDidUpdate(prevProps: Props) {
    if (!_.isEqual(this.props.originalValues, prevProps.originalValues)) {
      this.setState({
        errors: [],
        modified: [],
      });
    }
  };

  getExceededAreaFields = (values: any) => {

    let exceeders: any[] = [];

    values.forEach((value: any) => {
      const check = (_value: any) => {
        if (_.has(_value, 'children') && !_.isEmpty(_value.children)) {

          let total_children_area = parseFloat('0');

          _value.children.forEach((__value: any) => {

            total_children_area += parseFloat(__value.area || 0);

            if (parseFloat(_value.area) < total_children_area) {
              exceeders = _.union(exceeders, [_value.key]);
            }

            check(__value);
          });
        }
      };

      return check(value);
    });

    return exceeders;
  };

  getTotalBusinessHours = (spaceRecord: any): string => {
    let totalBusinessHours = 0;

    _.has(spaceRecord, 'opening_hours') && spaceRecord.opening_hours.forEach((openingHour: any) => {
      if (!!openingHour.disabled) return;

      // Add 1 minute to end time to force 00 --> 59 comparisons to round up to 1 hour
      let endHours = moment(openingHour.end, 'HH:mm').add(1, 'minute');
      let startHours = moment(openingHour.start, 'HH:mm');
      totalBusinessHours += endHours.diff(startHours, 'hours');
    });

    return totalBusinessHours + (totalBusinessHours === 1 ? ' Hour' : ' Hours');
  };

  getTotalFtes = (spaceRecord: any, nestedTotal: boolean = false): number => {
    if (nestedTotal) {
      let total = parseFloat((spaceRecord.fte_direct || 0) + (spaceRecord.fte_indirect || 0));

      spaceRecord.children.forEach((space: any) => {
        const sumFtes = (_space: any) => {

          total += parseFloat((_space.fte_direct || 0) + (_space.fte_indirect || 0));

          if (_.has(_space, 'children') && !_.isEmpty(_space.children)) {
            _space.children.forEach((__space: any) => {
              sumFtes(__space);
            });
          }

        };

        return sumFtes(space);
      });

      return total;
    }

    return parseFloat((spaceRecord.fte_direct || 0) + (spaceRecord.fte_indirect || 0));
  };

  getErrors = (values: any) => {
    const errors: any = {};

    // Check for empty fields
    values.forEach((value: any) => {
      const check = (_value: any) => {

        const schema = {
          title: (value: any) => !isBlank(value),
          type_id: (value: any) => !isBlank(value),
          status_id: (value: any) => !isBlank(value),
          occupier: (value: any) => !isBlank(value),
          area: (value: any) => !isBlank(value),
        };

        const _validate = (_value: any, schema: any) => Object
          .keys(schema)
          .filter(key => !schema[key](_value[key]))
          .map(key => key);

        if (!_.isEmpty(_validate(_value, schema))) {
          errors[_value.key] = _validate(_value, schema);
        }

        if (_.has(_value, 'children') && !_.isEmpty(_value.children)) {
          _value.children.forEach((__value: any) => {
            check(__value);
          });
        }
      };
      return check(value);
    });

    return errors;
  };

  getModified = (values: any, originalValues: any) => {

    const modified: any = {};

    values.forEach((value: any) => {
      const check = (_value: any, _oldValue: any) => {
        if (!_.isEqual(_value, _oldValue)) {
          Object.keys(_value)
            .filter((key: string) => ['title', 'area', 'type_id', 'status_id', 'occupier'].includes(key))
            .forEach((key: string) => {
              if (!_oldValue) {
                modified[_value.key] = ['title', 'area', 'type_id', 'status_id', 'occupier']; // New
              } else if (!_.isEqual(_value[key], _oldValue[key])) {
                if (!!modified[_value.key] && !modified[_value.key].includes(key)) {
                  modified[_value.key] = modified[_value.key].concat(key);
                } else {
                  modified[_value.key] = [key];
                }
              }
              if (_.has(_value, 'children') && _.has(_oldValue, 'children')) {
                _value.children.forEach((__value: any) => {
                  check(__value, _oldValue.children.find((_oldValue: any) => _oldValue.key === __value.key));
                });
              }
            });
        }
      };

      if (originalValues.some((_value: any) => _value.key === value.key)) {
        check(value, originalValues.find((_oldValue: any) => _oldValue.key === value.key));
      } else {
        modified[value.key] = ['title', 'area', 'type_id', 'status_id', 'occupier']; // New
      }
    });

    return modified;
  };

  validate = (values: any, originalValues: any) => {

    const errors = this.getErrors(values);
    const modified = this.getModified(values, originalValues);
    const exceededFields = this.getExceededAreaFields(values);

    this.setState({
      errors: errors,
      modified: modified,
      exceededFields: exceededFields,
    });

    this.props.setFieldModifiedMessage(`${this.props.field.id}`, _.isEqual(values, originalValues) ? undefined : {
      id: this.props.field.id,
      cardinality: this.props.config.fieldIndex || 0,
      group: this.props.config.groupID,
      tab: this.props.config.tabID,
      order: this.props.config.elementIndex,
      content: {
        label: this.props.field.label,
        content: []
      },
      modified: { '': true }
    });
  };

  hasError = (errors: any[], spaceKey: any, fieldKey: string) => {
    return !!errors[spaceKey] && errors[spaceKey].includes(fieldKey);
  };

  isModified = (modified: any[], spaceKey: any, fieldKey: string) => {
    return !!modified[spaceKey] && modified[spaceKey].includes(fieldKey);
  };

  handleChange = (field: any, values: any, originalValues: any) => {
    this.validate(values, originalValues);
    this.props.onChange(values);
  };

  setExpandedRowKeys = (definition: string, key: string, insert: boolean = false) => {
    const { expandedRowKeys } = this.state;

    let newRowKeys = _.cloneDeep(expandedRowKeys);

    if (insert) {
      newRowKeys[definition] = !!newRowKeys[definition] ? !newRowKeys[definition].includes(key) ? newRowKeys[definition].concat([key]) : newRowKeys[definition] : [key];
    } else {
      newRowKeys[definition].splice(newRowKeys[definition].findIndex((_key: any) => _key === key), 1);
    }

    this.setState({
      expandedRowKeys: newRowKeys
    });
  };

  getExpandedRowKeys = (definition: string) => {
    const { expandedRowKeys } = this.state;
    return !!expandedRowKeys[definition] ? expandedRowKeys[definition] : [];
  };

  getOccupier = (field: any, occupier_id: number, occupier_type: string) => {
    let occupier = null;
    Object.keys(field.occupiers).forEach((occupierGroup: any) => {
      field.occupiers[occupierGroup].forEach((_occupier: any) => {
        if (_occupier.id === occupier_id && _occupier.type === occupier_type) {
          occupier = _occupier;
        }
      });
    });
    return occupier;
  };

  get = (field: any, key: any) => {
    const record = field && _.has(field, 'values') && field.values
      .filter((value: any) => {
        return findFirst(value, 'children', { key: key });
      }).map((value: any) => {
        return findFirst(value, 'children', { key: key });
      });

    return !_.isEmpty(record) ? record[0] : [];
  };

  insertValues = (identifier: number | string, values: any, default_opening_hours: any) => {
    return values.map((value: any) => {

      const appendChildrenKeys = (children: any, _identifier: number | string) => {
        return children.map((childEntity: any) => {

          if (childEntity.key === _identifier) {
            childEntity.children.push(getBlankState(childEntity, default_opening_hours));
          }

          return {
            ...childEntity,
            'children': _.has(childEntity, 'children') && !_.isEmpty(childEntity.children) ? appendChildrenKeys(childEntity.children, _identifier) : [],
          };
        });
      };

      if (value.key === identifier) {
        value.children.push(getBlankState(value, default_opening_hours));
      }

      return {
        ...value,
        'children': !_.isEmpty(value.children) ? appendChildrenKeys(value.children, identifier) : [],
      };
    });
  };

  deleteDeepValues = (identifier: number, values: any) => {
    return values
      .filter((value: any) => value.key !== identifier)
      .map((value: any) => {

      const appendChildrenKeys = (children: any, _identifier: number) => {
        return children
          .filter((childEntity: any) => childEntity.key !== _identifier)
          .map((childEntity: any) => {
          return {
            ...childEntity,
            'children': !_.isEmpty(children) ? appendChildrenKeys(childEntity.children, _identifier) : [],
          };
        });
      };

      return {
        ...value,
        'children': !_.isEmpty(value.children) ? appendChildrenKeys(value.children, identifier) : [],
      };
    });
  };

  modifyValues = (identifier: number, values: any, newValue: any, key: any) => {
    return values.map((value: any) => {
      const appendChildrenKeys = (children: any, _identifier: number) => {
        return children && children.map((child: any) => {

          if (child.key === _identifier) {
            child = _.set(child, [key], newValue);
          }

          return {
            ...child,
            'children': !_.isEmpty(children) ? appendChildrenKeys(child.children, _identifier) : [],
          };
        });
      };

      if (value.key === identifier) {
        value = _.set(value, [key], newValue);
      }

      return {
        ...value,
        'children': !_.isEmpty(value.children) ? appendChildrenKeys(value.children, identifier) : [],
      };
    });
  };

  gotChildren = (space: any) => {
    return _.has(space, 'children') && !_.isEmpty(space.children);
  };

  generateTreeList = (value: any) => {
    return {
      key: value.id,
      value: value.id,
      title: value.title,
      children: _.has(value, 'children') && !_.isEmpty(value.children) && value.children
        .map((_value: any) => this.generateTreeList(_value))
    };
  };

  renderTotalArea = (field: any) => {

    const total = field.values.reduce((acc: any, value: any) => parseFloat(acc) + parseFloat(value.area || 0), 0);
    const maxArea = field && _.has(field, 'max_area') && !!field.max_area ? field.max_area : 0;
    const invalid = parseFloat(total).toFixed(2) !== parseFloat(maxArea).toFixed(2);

    if (!maxArea) {
      return (
        <Text>
          { getFormatedNumber(`${total}`) }
        </Text>
      );
    }

    return (
      <Tooltip
        overlayClassName={ invalid ? 'text-white' : '' }
        placement="topRight"
        title={ `Must Equal: ${getFormatedNumber(maxArea)}` }
      >
        <Text className={ invalid ? 'text-danger' : 'text-success' }>
          { getFormatedNumber(`${total}`) }
        </Text>
        { invalid &&
          <WarningIcon className="text-danger mL-5 va-s" height={ 20 } width={ 20 } />
        }
      </Tooltip>
    );
  };

  renderTable = (field: any, showFtes: boolean = false) => {
    const { originalValues, isDisabled, numberFormat } = this.props;
    const { activeFteKey, errors, modified, exceededFields, activeBusinessHourKey, occupierFilter } = this.state;
    const measurementUnit = _.has(field, 'config.measurement') ? field.config.measurement : 'SQM';

    // Convert children to null if empty
    const rows = _.has(field, 'values') && !_.isEmpty(field.values) && field.values
      .filter((value: any) => {
        if (!!occupierFilter && !findFirst(value, 'children', { 'occupier': _.omit(occupierFilter, ['gia']) })) {
          return false;
        }
        return true;
      })
      .map((value: any) => {

        const appendChildrenKeys = (children: any) => {
          return children
            .filter((child: any) => {
              if (!!occupierFilter && !findFirst(child, 'children', { 'occupier': _.omit(occupierFilter, ['gia']) })) {
                return false;
              }
              return true;
            })
            .map((child: any) => {
              const children = _.has(child, 'children') && !_.isEmpty(child.children) ? appendChildrenKeys(child.children) : [];
              return {
                ...child,
                'children': !_.isEmpty(children) ? children : null
              };
            });
        };

        const children = _.has(value, 'children') && !_.isEmpty(value.children) ? appendChildrenKeys(value.children) : [];

        return {
          ...value,
          'isParent': true,
          'children': !_.isEmpty(children) ? children : null
        };
      });

    let columns: any = [
      {
        key: 'name',
        title: (
          <>
            { _.has(field, 'labels.name.label') ? field.labels.name.label : 'Name' }
            { _.has(field, 'labels.name.tooltip') &&
              <Tooltip
                className="mL-5"
                placement="top"
                title={ field.labels.name.tooltip }
              >
                <QuestionCircleOutlined className="fsz-def text-ant-default" />
              </Tooltip>
            }
          </>
        ),
        render: (row: any) => {
          const hasErrors = this.hasError(errors, row.key, 'title');
          const isModified = this.isModified(modified, row.key, 'title');
          return (
            <Input
              className={ classNames('SpaceDefinitionField-Name', {
                'Field--has-error border-danger': hasErrors,
                'Field--has-warning border-warning': isModified && !hasErrors,
              }) }
              disabled={ isDisabled }
              onBlur={ (e: React.ChangeEvent<HTMLInputElement>) => this.handleChange(field, this.modifyValues(row.key, _.cloneDeep(field.values), e.target.value, 'title'), originalValues) }
              placeholder={ _.has(field, 'labels.name.label') ? field.labels.name.label : 'Name' }
              defaultValue={ row.title }
            />
          );
        }
      },
      {
        key: 'type',
        title: (
          <>
            { _.has(field, 'labels.type.label') ? field.labels.type.label : 'Type' }
            { _.has(field, 'labels.type.tooltip') &&
              <Tooltip
                className="mL-5"
                placement="top"
                title={ field.labels.type.tooltip }
              >
                <QuestionCircleOutlined className="fsz-def text-ant-default" />
              </Tooltip>
            }
          </>
        ),
        render: (row: any) => {

          const hasErrors = this.hasError(errors, row.key, 'type_id');
          const isModified = this.isModified(modified, row.key, 'type_id');

          const types = _.has(field, 'space_types') && field.space_types
            .map((type: any) => this.generateTreeList(type));

          return (
            <TreeSelect
              style={{ minWidth: 180 }}
              className={ classNames('Select-Field', {
                'Select-Field--has-error border-danger': hasErrors,
                'Select-Field--has-warning border-warning': isModified && !hasErrors,
              }) }
              dropdownMatchSelectWidth={ false }
              treeData={ types }
              placeholder={ _.has(field, 'labels.type.label') ? field.labels.type.label : 'Type' }
              value={ row.type_id }
              showCheckedStrategy={ SHOW_PARENT }
              disabled={ isDisabled }
              onChange={ (type_id: number) => {
                this.handleChange(field, this.modifyValues(row.key, _.cloneDeep(field.values), type_id, 'type_id'), originalValues);
              } }
            />
          );
        }
      },
      {
        key: 'status',
        title: (
          <>
            { _.has(field, 'labels.status.label') ? field.labels.status.label : 'Status' }
            { _.has(field, 'labels.status.tooltip') &&
              <Tooltip
                className="mL-5"
                placement="top"
                title={ field.labels.status.tooltip }
              >
                <QuestionCircleOutlined className="fsz-def text-ant-default" />
              </Tooltip>
            }
          </>
        ),
        render: (row: any) => {
          const hasErrors = this.hasError(errors, row.key, 'status_id');
          const isModified = this.isModified(modified, row.key, 'status_id');

          const statuses = _.has(field, 'space_statuses') && field.space_statuses
            .map((type: any) => this.generateTreeList(type));

          return (
            <TreeSelect
              style={{ minWidth: 180 }}
              className={ classNames('Select-Field', {
                'Select-Field--has-error border-danger': hasErrors,
                'Select-Field--has-warning border-warning': isModified && !hasErrors,
              }) }
              dropdownMatchSelectWidth={ false }
              treeData={ statuses }
              placeholder={ _.has(field, 'labels.status.label') ? field.labels.status.label : 'Status' }
              value={ row.status_id }
              showCheckedStrategy={ SHOW_PARENT }
              disabled={ isDisabled }
              onChange={ (status_id: number) => {
                this.handleChange(field, this.modifyValues(row.key, _.cloneDeep(field.values), status_id, 'status_id'), originalValues);
              } }
            />
          );
        }
      },
      {
        key: 'occupier',
        title: (
          <>
            { _.has(field, 'labels.occupier.label') ? field.labels.occupier.label : 'Occupier' }
            { _.has(field, 'labels.occupier.tooltip') &&
              <Tooltip
                className="mL-5"
                placement="top"
                title={ field.labels.occupier.tooltip }
              >
                <QuestionCircleOutlined className="fsz-def text-ant-default" />
              </Tooltip>
            }
          </>
        ),
        width: 180,
        render: (row: any) => {

          const gotChildren = this.gotChildren(this.get(field, row.key));
          if (gotChildren) return <></>;

          const hasErrors = this.hasError(errors, row.key, 'occupier');
          const isModified = this.isModified(modified, row.key, 'occupier');

          let exceededArea = false;
          let maxOccupierArea = null;

          if (!!row.occupier) {
            const fieldOccupier = this.get(field, row.key);
            const occupier: any = this.getOccupier(field, row.occupier.id, row.occupier.type);
            maxOccupierArea = !!occupier && occupier.total_area ? parseFloat(occupier.total_area) : null;

            if (maxOccupierArea && maxOccupierArea !== parseFloat(fieldOccupier.area)) {
              exceededArea = true;
            }
          }

          return (
            <div className="d-f">
              <Select
                className={ classNames('Select-Field', {
                  'Select-Field--has-error border-danger': hasErrors,
                  'Select-Field--has-warning border-warning': isModified && !hasErrors,
                }) }
                allowClear
                disabled={ isDisabled }
                dropdownMatchSelectWidth={ false }
                placeholder={ _.has(field, 'labels.occupier.label') ? field.labels.occupier.label : 'Occupier' }
                onClear={ () => this.handleChange(field, this.modifyValues(row.key, _.cloneDeep(field.values), null, 'occupier'), originalValues) }
                onSelect={ (occupier_key: string) => {
                  const occupier_id = parseInt(occupier_key.split('-')[0]);
                  const occupier_type = occupier_key.split('-')[1];
                  const occupier = this.getOccupier(field, occupier_id, occupier_type);

                  if (occupier) {
                    this.handleChange(field, this.modifyValues(row.key, _.cloneDeep(field.values), occupier, 'occupier'), originalValues);
                  }
                } }
                value={ _.has(row, 'occupier.id') ? `${row.occupier.id}-${row.occupier.type}` : undefined }
              >
                { _.has(field, 'occupiers') && Object.keys(field.occupiers).map((occupierGroup: string, index: number) => (
                  <OptGroup label={ _.upperFirst(occupierGroup) } key={ index }>
                    { field.occupiers[occupierGroup].map((occupier: any) => (
                      <Option key={ `${occupier.id}-${occupier.type}` } value={ `${occupier.id}-${occupier.type}` }>
                        { occupier.title }
                      </Option>
                    ) ) }
                  </OptGroup>
                )) }
              </Select>
              { !!exceededArea &&
                <Tooltip
                  overlayClassName="text-white"
                  placement="topRight"
                  title={ `The total space area assigned to this occupier does not match its Available Area (${getFormatedNumber(`${maxOccupierArea}`)}).` }
                >
                  <WarningIcon className="text-danger mL-5 mT-5" height={ 20 } width={ 20 } />
                </Tooltip>
              }
            </div>
          );
        }
      },
      {
        key: 'area',
        title: (
          <>
            { _.has(field, 'labels.area.label') ? field.labels.area.label : `Area (${measurementUnit})` }
            { _.has(field, 'labels.area.tooltip') &&
              <Tooltip
                className="mL-5"
                placement="top"
                title={ field.labels.area.tooltip }
              >
                <QuestionCircleOutlined className="fsz-def text-ant-default" />
              </Tooltip>
            }
          </>
        ),
        width: 150,
        render: (row: any) => {
          const hasErrors = this.hasError(errors, row.key, 'area');
          const isModified = this.isModified(modified, row.key, 'area');
          return (
            <div className="d-f">
              <NumberFormat
                { ...numberFormat }
                className={ classNames('ta-r', {
                  'Field--has-error border-danger': hasErrors,
                  'Field--has-warning border-warning': isModified && !hasErrors,
                  'fw-600': !!row.isParent,
                }) }
                disabled={ isDisabled }
                customInput={ Input }
                fixedDecimalScale
                decimalScale={ 2 }
                defaultValue={ row.area }
                placeholder={ 0 }
                onValueChange={ _.debounce((value: any) => {
                  this.handleChange(field, this.modifyValues(row.key, _.cloneDeep(field.values), value.value, 'area'), originalValues);
                }, 500) }
              />
              { exceededFields.includes(row.key) &&
                <Tooltip
                  overlayClassName="text-white"
                  placement="topRight"
                  title={ "Available Area Exceeded by Children" }
                >
                  <WarningIcon className="text-danger mL-5 mT-5" height={ 20 } width={ 20 } />
                </Tooltip>
              }
            </div>
          );
        }
      },
      {
        title: (
          <>
            { _.has(field, 'labels.business_hours.label') ? field.labels.business_hours.label : 'Business Hours' }
            { _.has(field, 'labels.business_hours.tooltip') &&
              <Tooltip
                className="mL-5"
                placement="top"
                title={ field.labels.business_hours.tooltip }
              >
                <QuestionCircleOutlined className="fsz-def text-ant-default" />
              </Tooltip>
            }
          </>
        ),
        render: (row: any) => {
          return (
            <Link onClick={ () => this.setState({ activeBusinessHourKey: row.key }) }>
              { this.getTotalBusinessHours(this.get(field, row.key)) }
            </Link>
          );
        }
      },
      {
        key: 'actions',
        title: !isDisabled ? (
          <Button
            style={{
              marginLeft: 5,
              padding: '4px 7px',
              width: '32px',
            }}
            onClick={ () => this.handleChange(field, [].concat(_.cloneDeep(field.values), getBlankState(field, field.default_opening_hours)), originalValues) }
          >
            <PlusOutlined />
          </Button>
        ): (
          <></>
        ),
        dataIndex: '',
        align: Alignment.Right,
        width: 100,
        render: (row: any) => {

          if (isDisabled) return <></>;

          const space = this.get(field, row.key);

          let nestNode = (
            <Button
              style={{
                marginLeft: '5px',
                padding: '4px 7px',
                width: '32px',
              }}
              onClick={ () => {
                this.setExpandedRowKeys(field.id, row.key, true);
                this.handleChange(field, this.insertValues(row.key, _.cloneDeep(field.values), field.default_opening_hours), originalValues);
              } }
            >
              <SisternodeOutlined />
            </Button>
          );

          if (!!space && _.has(space, 'occupier') && !!space.occupier) {
            nestNode = (
              <Tooltip
                placement="topRight"
                title={ 'Cannot add a row to an occupied space' }
              >
                <Button
                  disabled
                  style={{
                    marginLeft: '5px',
                    padding: '4px 7px',
                    width: '32px',
                  }}
                >
                  <SisternodeOutlined />
                </Button>
              </Tooltip>
            );
          }

          return (
            <>
              { nestNode }
              <Popconfirm
                title={ 'Are you sure?' }
                icon={ <QuestionCircleOutlined style={{ color: 'red' }} /> }
                visible={ this.state.deleteConfirmId === row.key }
                okButtonProps={{
                  danger: true
                }}
                onConfirm={ () => {
                  this.setState({ deleteConfirmId: null }, () => {
                    this.handleChange(field, this.deleteDeepValues(row.key, _.cloneDeep(field.values)), originalValues);
                  });
                }}
                onCancel={ () => this.setState({ deleteConfirmId: null }) }
              >
                <Button
                  style={{
                    marginLeft: '5px',
                    padding: '4px 7px',
                    width: '32px',
                  }}
                  onClick={ () => this.setState({ deleteConfirmId: row.key }) }
                >
                  <DeleteOutlined />
                </Button>
              </Popconfirm>
            </>
          );
        },
      }
    ];

    // Append FTEs to table
    if (showFtes) {
      columns.splice(columns.findIndex((column: any) => column.key === 'area') + 1, 0,
        {
          title: (
            <>
              { _.has(field, 'labels.ftes.label') ? field.labels.ftes.label : 'FTEs' }
              { _.has(field, 'labels.ftes.tooltip') &&
                <Tooltip
                  className="mL-5"
                  placement="top"
                  title={ field.labels.ftes.tooltip }
                >
                  <QuestionCircleOutlined className="fsz-def text-ant-default" />
                </Tooltip>
              }
            </>
          ),
          width: 100,
          render: (row: any) => {
            return (
              <Link onClick={ () => this.setState({ activeFteKey: row.key }) }>
                { `${this.getTotalFtes(this.get(field, row.key), true)} FTEs` }
              </Link>
            );
          }
        }
      );
    }

    return (
      <div className="d-f fxd-c w-100p" onChange={() => null}>
        <div className="d-f jc-sb mB-10">
          <Select
            className="Select-Field"
            style={{ maxWidth: 300 }}
            disabled={ isDisabled }
            placeholder={ 'Occupier filter' }
            allowClear
            onClear={ () => this.setState({ occupierFilter: null }) }
            onSelect={ (occupier_key: string) => {
              const occupier_id = parseInt(occupier_key.split('-')[0]);
              const occupier_type = occupier_key.split('-')[1];
              const occupier = this.getOccupier(field, occupier_id, occupier_type);

              if (occupier) {
                this.setState({
                  occupierFilter: occupier
                });
              }
            } }
            value={ !!occupierFilter ? `${occupierFilter.id}-${occupierFilter.type}` : undefined }
          >
            { _.has(field, 'occupiers') && Object.keys(field.occupiers).map((occupierGroup: string, index: number) => (
              <OptGroup label={ _.upperFirst(occupierGroup) } key={ index }>
                { field.occupiers[occupierGroup].map((occupier: any) => (
                  <Option key={ `${occupier.id}-${occupier.type}` } value={ `${occupier.id}-${occupier.type}` }>
                    { occupier.title }
                  </Option>
                ) ) }
              </OptGroup>
            )) }
          </Select>
        </div>
        <Table
          size={ 'small' }
          className="SpaceDefinitionField"
          columns={ columns }
          dataSource={ rows || [] }
          expandedRowKeys={ this.getExpandedRowKeys(field.id) }
          onExpand={ (expanded: any, record: any) => {
            this.setExpandedRowKeys(field.id, record.key, expanded);
          } }
          pagination={ false }
          summary={ () => {
            return (
              <>
                <Table.Summary.Row>
                  <Table.Summary.Cell index={ 0 } colSpan={ 2 }>
                    <Text>{ 'Total' }</Text>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={ 1 } colSpan={ 3 } className="ta-r fw-600">
                    { this.renderTotalArea(field) }
                  </Table.Summary.Cell>
                </Table.Summary.Row>
              </>
            );
          }}
        />
        { activeFteKey &&
          <FteModal
            field={ field }
            spaceRecord={ this.get(field, activeFteKey) }
            numberFormat={ numberFormat }
            isDisabled={ isDisabled }
            measurementUnit={ measurementUnit }
            onSave={ (spaceRecord: any) => {
              const newFieldValues = _.cloneDeep(field).values.map((value: any) => {
                if (findFirst(value, 'children', { key: spaceRecord.key }) ) {
                  return findAndModifyFirst(value, 'children', { key: spaceRecord.key }, spaceRecord);
                }
                return value;
              });
              this.handleChange(field, newFieldValues, originalValues);
            }}
            onClose={ () => this.setState({ activeFteKey: null }) }
          />
        }
        { activeBusinessHourKey && (
          <BusinessHoursModal
            spaceRecord={ this.get(field, activeBusinessHourKey) }
            presets={ _.has(field, 'preset_opening_hours') ? field.preset_opening_hours : null }
            onClose={ () => this.setState({ activeBusinessHourKey: null }) }
            onSave={ (spaceRecord: any) => {
              const newFieldValues = _.cloneDeep(field).values.map((value: any) => {
                if (findFirst(value, 'children', { key: spaceRecord.key })) {
                  return findAndModifyFirst(value, 'children', { key: spaceRecord.key }, spaceRecord);
                }
                return value;
              });
              this.handleChange(field, newFieldValues, originalValues);
            } }
          />
        ) }
      </div>
    );
  };

  render = () => {
    const { field, config } = this.props;
    return (
      <FieldWrapper
        id={ `${config.tabID}|${config.groupID}|${field.id}` }
        col={ config.fieldColSpan }
        required={ field.config.required }
        description={ !!field.description && field.description }
      >
        { this.renderTable(field, !!field.config.enable_ftes) }
      </FieldWrapper>
    );
  };
};

export default SpaceDefinition;
