// Libs
import * as React from 'react';

// Components
import RecordView from 'views/common/RecordView';

// Interfaces
import { RecordFormEntity } from 'types/entities';

interface Props {
  record: RecordFormEntity;
  setRecord?: (record: RecordFormEntity) => void;
  getRecord(silent?: boolean): void;
};

class BudgetRecord extends React.Component<Props> {
  render = () => {
    return <RecordView type={ 'budget' } entity={ 'record' } id={ this.props.record.id } record={ this.props.record } getRecord={ this.props.getRecord } setRecord={ this.props.setRecord } />;
  };
};

export default BudgetRecord;
