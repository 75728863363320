// Libs
import * as React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';

// Components
import { hasPermission } from 'components/restriction';

// Interfaces
import AppState from 'store/AppState.interface';
import { UserEntity } from 'types/entities';
import ClientState from 'store/Client/State.interface';

// Services
import history from 'utils/history';

interface Props {
  user: UserEntity;
  client: ClientState;
};

class Home extends React.Component<Props> {

  componentDidMount = () => {
    const { user, client } = this.props;
    const redirect: string[] = [];

    if (!!user && !!client) {

      // First login, redirect to user settings area
      if (!user.last_login) {
        redirect.push('user/settings');
      } else if (hasPermission(user.permissions, 'access_dashboards')) {

        if (!!user.default_dashboard_id) {
          redirect.push(`dashboards/${user.default_dashboard_id}`);
        } else {
          redirect.push('dashboards');
        }

      } else {
        const firstModule = this.getFirstModule(client, user);
        if (typeof firstModule === 'string') {
          redirect.push(firstModule);
        }
      }
    }

    if (!_.isEmpty(redirect)) {
      history.push(redirect.join('/'));
    } else {
      // Fallback
      history.push('user/settings');
    }
  };

  getFirstModule = (client: ClientState, user: UserEntity): string | boolean => {

    const routePermissionMapping = [
      { route: 'customers', permission: 'company_customer_view' },
      { route: 'tenants', permission: 'company_tenant_view' },
      { route: 'landlords', permission: 'company_landlord_view' },
      { route: 'locations', permission: 'record_location_view' },
      { route: 'owned', permission: 'record_owned_view' },
      { route: 'leases', permission: 'record_lease_view' },
      { route: 'subleases', permission: 'record_sublease_view' },
      { route: 'spaces', permission: 'record_space_view' },
      { route: 'service-specifications', permission: 'record_service_specification_view' },
      { route: 'assets', permission: 'record_asset_view' },
      { route: 'help-desk', permission: 'ticket_help_desk_view' },
      { route: 'budgets', permission: 'record_budget_view' },
      { route: 'cost-centres', permission: 'record_cost_centres_view' },
      { route: 'master-coas', permission: 'record_master_coa_view' },
      { route: 'invoices', permission: 'record_invoice_view' },
      { route: 'quotes', permission: 'record_quotes_view' },
      { route: 'purchase-orders', permission: 'record_purchase_order_view' },
      { route: 'suppliers', permission: 'company_supplier_view' },
      { route: 'contracts', permission: 'record_contract_view' },
    ];

    client.routes.forEach((route: any) => {

      // Fetch the first module the user got access to
      const module = route.modules.find((module: any) => {
        return routePermissionMapping.find((permissionRoute: { route: string, permission: string }) => {
          return module.route === permissionRoute.route && hasPermission(user.permissions, permissionRoute.permission);
        });
      });

      if (!!module) {
        return `${route.route}/${module.route}`;
      }
    });

    return false;
  };

  render = () => {
    return (
      <div>Home</div>
    );
  };
};

// Make data available on props
const mapStateToProps = (store: AppState) => {
  return {
    user: store.UserState.user,
    client: store.ClientState,
  };
};

export default connect(mapStateToProps, null)(Home);