// Libs
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Store } from 'redux';
import { Switch, Route, Router } from "react-router-dom";

// Interface
import AppState from './store/AppState.interface';

// Configs
import store from 'store/Store';

// Layouts
import AppLayout from 'layouts/AppLayout';

// Actions
import { initialiseApp } from 'store/UI/Actions';

// history for routing
import history from 'utils/history';

// Styles
import 'antd/dist/antd.min.css';
import 'assets/styles/index.scss';

interface Props {
  store: Store<AppState>;
}

const Root: React.FC<Props> = props => {

  // Start initialising the app
  store.dispatch(initialiseApp());

  return (
    <Provider store={ props.store }>
      <Router history={ history }>
        <Switch>
          <Route path='/'>
            <AppLayout />
          </Route>
        </Switch>
      </Router>
    </Provider>
  );
};

ReactDOM.render(
  <Root store={ store } />, document.getElementById('root')
);
