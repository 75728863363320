// Libs
import React from 'react';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import moment from 'moment';
import _ from 'lodash';

// Components
import Jumbotron from "components/jumbotron";
import BlockingSpinner from 'components/blocking-spinner';
import { Form, DatePicker, Select, TreeSelect, Button } from 'antd';
import { RestrictionHoC } from 'components/restriction';
import Dropdown from 'components/dropdown';
import Import from 'components/import';

// Interfaces
import AppState from 'store/AppState.interface';
import { Breadcrumb } from 'store/UI/State.interface';
import { UserPermissions } from 'types/permissions';

// Services
import { Api } from 'services/api';
import Notification from 'services/notification';
import { getUserSetting } from 'services/settings';

// Actions
import { setBreadcrumbs, setBreadcrumbsLoading } from 'store/UI/ActionCreators';

interface Props {
  client_id: number;
  user_id: number;
  permissions: UserPermissions;
  match: {
    isExact: boolean;
    params: Record<string, any>;
    path: string;
    url: string;
  };
  setBreadcrumbs(breadcrumbs: Breadcrumb[], concat: boolean): void;
  setBreadcrumbsLoading(isLoading: boolean): void;
};

interface State {
  tool: string | null;
  error: any;
  exportData: { [key: string]: number[] };
  recordItem: RecordItem | null;
  startDate: string | null;
  endDate: string | null;
  isLoading: boolean;
  isExporting: boolean;
  datePickerPreset: string | null;
  datePickerOpen: boolean;
  prevDatePickerPreset: string | null;
  prevStartDate: string | null;
  prevEndDate: string | null;
};

interface RecordItem {
  reference: string;
  title: string;
  filters: RecordFilter[];
  config?: string[]
};

interface RecordFilter {
  reference: string;
  label: string;
  type: string;
  options: { id: string, title: string, parent_id?: number }[];
};

const API: Api = new Api();
const { RangePicker } = DatePicker;
const { Option } = Select;
const DATE_FORMAT = getUserSetting('date_format');
const { SHOW_PARENT } = TreeSelect;

const getDefaultPickerDates = () => {
  const startDate = moment();
  const endDate = moment().add(31, 'days');

  return {
    startDate: startDate ? startDate.format('YYYY-MM-DD') : null,
    endDate: endDate ? endDate.format('YYYY-MM-DD') : null
  };
};

const calendarTypes = [
  {
    key: 'custom_date',
    value: 'custom_date',
    label: 'Custom Date',
    is_range: true
  },
  {
    key: 'current_date',
    value: 'current_date',
    label: 'Current Date',
    is_range: false
  },
  {
    key: 'next_7_days',
    value: 'next_7_days',
    label: 'Next 7 Days',
    is_range: true
  },
  {
    key: 'next_28_days',
    value: 'next_28_days',
    label: 'Next 28 Days',
    is_range: true
  },
  {
    key: 'next_1_year',
    value: 'next_1_year',
    label: 'Next 1 year',
    is_range: true
  },
  {
    key: 'next_3_years',
    value: 'next_3_years',
    label: 'Next 3 years',
    is_range: true
  },
];

class ExportImportItem extends React.Component<RouteComponentProps<{}> & Props, State> {

  mounted: boolean = false;

  state: State = {
    tool: null,
    error: null,
    recordItem: null,
    exportData: {},
    startDate: getDefaultPickerDates().startDate,
    endDate: getDefaultPickerDates().endDate,
    isLoading: false,
    isExporting: false,
    datePickerPreset: 'custom_date',
    prevDatePickerPreset: 'custom_date',
    datePickerOpen: false,
    prevStartDate: null,
    prevEndDate: null,
  };

  componentDidMount = async () => {
    const { client_id, setBreadcrumbs } = this.props;
    const record_id = this.props.match.params.id;

    this.mounted = true;

    try {
      this.props.setBreadcrumbsLoading(true);

      await new Promise((resolve) => this.setState({ isLoading: true }, () => resolve(null)));

      const recordItem = await API.get(`client/${client_id}/admin/export-import/${record_id}`);

      if (this.mounted) {
        setBreadcrumbs([
          { title: 'Home', path: '/' },
          { title: 'Admin', path: '/admin' },
          { title: 'Export - Import', path: '/admin/export-import' },
          { title: recordItem?.title || '-', path: null },
        ], false);

        this.setState({
          recordItem: recordItem,
        });
      }
    } catch (error) {
      console.error('Error: ', error);
    } finally {
      this.props.setBreadcrumbsLoading(false);
      this.mounted && this.setState({
        isLoading: false
      });
    }
  };

  componentWillUnmount = () => {
    this.props.setBreadcrumbs([], false);
    this.mounted = false;
  };

  handleExport = async () => {
    const { client_id } = this.props;
    const { exportData, startDate, endDate } = this.state;
    const record_id = this.props.match.params.id;

    try {

      await new Promise((resolve) => this.setState({ isExporting: true }, () => resolve(null) ));

      let filename = `pacs_export_${moment().format('YYYY-MM-DD')}.xlsx`;
      switch (record_id) {
        case 'help-desk-tickets':
          filename = `pacs_help_desk_tickets_export_${moment().format('YYYY-MM-DD')}.xlsx`;
          break;
        case 'assets':
          filename = `pacs_assets_export_${moment().format('YYYY-MM-DD')}.xlsx`;
          break;
      }

      await API.download(`client/${client_id}/admin/export-import/${record_id}/export`, filename, {
        ids: _.has(exportData, 'property') ? exportData.property : [],
        regions: _.has(exportData, 'region') ? exportData.region : [],
        from: startDate,
        to: endDate
      });

      this.setState({
        exportData: {}
      }, () => {
        Notification('success', `File exported`);
      });
    } catch (error) {
      console.error(error);
    } finally {
      this.setState({
        isExporting: false
      });
    }
  };

  renderExportTab = (recordItem: RecordItem | null) => {
    const { exportData, isExporting, startDate, endDate } = this.state;

    if (!recordItem) return <></>;

    return (
      <div className='Layout-box'>
        <div className="d-f jc-sb ai-c mB-20 mT-5">
          <div>
            <Form
              layout="inline"
            >
              { this.renderFilter(recordItem?.filters) }
            </Form>
          </div>
          <div>
            <Dropdown
              actions={ [
                {
                  node: 'Export',
                  onClick: () => this.handleExport(),
                  disabled: _.isEmpty(exportData) && !startDate && !endDate,
                  isLoading: isExporting,
                }
              ] }
            />
          </div>
        </div>
      </div>
    );
  };

  getDefaultModeDates = (value: string | null) => {
    let startDate = null;
    let endDate = null;

    switch (value) {
      case 'current_date':
        startDate = moment().startOf('day');
        endDate = moment().endOf('day');
        break;
      case 'next_7_days':
        startDate = moment();
        endDate = moment().add(7, 'days');
        break;
      case 'next_28_days':
        startDate = moment();
        endDate = moment().add(28, 'days');
        break;
      case 'next_1_year':
        startDate = moment().startOf('year');
        endDate = moment().startOf('year').add(11, 'months');
        break;
      case 'next_3_years':
        startDate = moment();
        endDate = moment().endOf('year').add(2, 'years');
        break;
    }

    return { startDate: startDate ? startDate.format('YYYY-MM-DD HH:mm:ss') : null, endDate: endDate ? endDate.format('YYYY-MM-DD HH:mm:ss') : null };
  };

  renderDatePickerFooter = (filter: any, mode: string, isRangePicker: boolean = false) => {
    const { datePickerPreset, prevDatePickerPreset, prevStartDate, prevEndDate } = this.state;

    return (
      <>
        <Select
          allowClear
          style={ { width: isRangePicker ? '200px' : '100%' } }
          placeholder={ 'Calendar Type' }
          onClear={ () => {
            this.setState({ datePickerPreset: null });
          } }
          onChange={ (value: string) => {
            const { startDate, endDate } = this.getDefaultModeDates(value);

            this.setState({
              startDate: startDate,
              endDate: endDate,
              datePickerPreset: value
            });
          } }
          value={ !!datePickerPreset ? datePickerPreset : 'custom_date' }
        >
          { calendarTypes.map(type => (
            <Select.Option key={ type.key } value={ type.value }>{ type.label }</Select.Option>
          ) ) }
        </Select>
        <Button
          style={ { width: isRangePicker ? 'auto' : '48%', marginRight: isRangePicker ? '0' : '4%', marginLeft: isRangePicker ? '10px' : '0' } }
          type='primary'
          onClick={ () => this.setState({ datePickerOpen: false }) }
        >
          OK
        </Button>
        <Button
          style={ { width: isRangePicker ? 'auto' : '48%', marginLeft: isRangePicker ? '10px' : '0' } }
          onClick={ () => {
            this.setState({
              datePickerPreset: prevDatePickerPreset,
              prevDatePickerPreset: null,
              datePickerOpen: false,
              startDate: _.cloneDeep(prevStartDate),
              endDate: _.cloneDeep(prevEndDate)
            });
          } }
        >
          Close
        </Button>
      </>
    );
  };

  isPickerMode = (): boolean => {
    const { datePickerPreset } = this.state;

    const pickerPreset = calendarTypes.find((type: any) => type.key === datePickerPreset);

    return !!pickerPreset && pickerPreset.is_range;
  };

  getPickerMode = (): any => {
    const { datePickerPreset } = this.state;

    // Format
    switch (datePickerPreset) {
      case 'next_7':
      case 'next_28':
        return 'date';
      case 'next_1_year':
      case 'next_3_years':
        return 'year';
    }

    return 'date';
  };

  renderDatePicker = (filter: RecordFilter) => {
    const { datePickerOpen, startDate, endDate } = this.state;
    const isPickerMode = this.isPickerMode();
    const pickerMode = this.getPickerMode();
    let format: any = undefined;

    // Format
    switch (pickerMode) {
      case 'date':
        format = getUserSetting('date_format');
        break;
      case 'week':
        format = '[w/c] WW - DD/MM/YY';
        break;
      case 'month':
        format = getUserSetting('date_format').replaceAll('/DD', '').replaceAll('/DD/', '').replaceAll('DD/', '');
        break;
      case 'year':
        format = 'YYYY';
        break;
    }

    if (isPickerMode) {
      const rangeValue = {
        from: startDate ? moment(startDate) : null,
        to: endDate ? moment(endDate) : null
      };

      return (
        <Form.Item
          label={ filter.label }
          key={ filter.reference }
        >
          <RangePicker
            open={ datePickerOpen }
            onOpenChange={ (open: boolean) => this.setState({
              datePickerOpen: true,
              prevDatePickerPreset: _.cloneDeep(this.state.datePickerPreset),
              prevStartDate: _.cloneDeep(startDate),
              prevEndDate: _.cloneDeep(endDate)
            }) }
            allowEmpty={ [true, true] }
            style={{ width: pickerMode === 'week' ? 300 : 250 }}
            placeholder={ ['All', 'All'] }
            picker={ pickerMode }
            format={ format }
            value={ startDate && endDate ? [rangeValue.from, rangeValue.to] : undefined }
            renderExtraFooter={ (mode: string) => this.renderDatePickerFooter(filter, mode, true) }
            onCalendarChange={ (dates: [moment.Moment | null, moment.Moment | null] | null) => {
              let startDate: string | null = null;
              let endDate: string | null = null;

              if (!!dates) {
                switch (pickerMode) {
                  case 'date':
                    startDate = moment(dates[0]).isValid() ? moment(dates[0]).startOf('day').format('YYYY-MM-DD HH:mm:ss') : null;
                    endDate = moment(dates[1]).isValid() ? moment(dates[1]).endOf('day').format('YYYY-MM-DD HH:mm:ss') : null;
                    break;
                  case 'week':
                    startDate = moment(dates[0]).isValid() ? moment(dates[0]).startOf('week').format('YYYY-MM-DD HH:mm:ss') : null;
                    endDate = moment(dates[1]).isValid() ? moment(dates[1]).endOf('week').format('YYYY-MM-DD HH:mm:ss') : null;
                    break;
                  case 'month':
                    startDate = moment(dates[0]).isValid() ? moment(dates[0]).startOf('month').format('YYYY-MM-DD HH:mm:ss') : null;
                    endDate = moment(dates[1]).isValid() ? moment(dates[1]).endOf('month').format('YYYY-MM-DD HH:mm:ss') : null;
                    break;
                  case 'quarter':
                    startDate = moment(dates[0]).isValid() ? moment(dates[0]).startOf('quarter').format('YYYY-MM-DD HH:mm:ss') : null;
                    endDate = moment(dates[1]).isValid() ? moment(dates[1]).endOf('quarter').format('YYYY-MM-DD HH:mm:ss') : null;
                    break;
                  case 'year':
                    startDate = moment(dates[0]).isValid() ? moment(dates[0]).startOf('year').format('YYYY-MM-DD HH:mm:ss') : null;
                    endDate = moment(dates[1]).isValid() ? moment(dates[1]).endOf('year').format('YYYY-MM-DD HH:mm:ss') : null;
                    break;
                }
              }

              this.setState({
                startDate: startDate,
                endDate: endDate
              });
            } }
          />
        </Form.Item>
      );
    }

    return (
      <Form.Item
        label={ filter.label }
        key={ filter.reference }
      >
        <DatePicker
          open={ datePickerOpen }
          onOpenChange={ (open: boolean) => this.setState({
            datePickerOpen: true,
            prevDatePickerPreset: _.cloneDeep(this.state.datePickerPreset),
            prevStartDate: _.cloneDeep(startDate),
            prevEndDate: _.cloneDeep(endDate)
          }) }
          style={{ width: 250 }}
          placeholder={ 'All' }
          picker={ pickerMode }
          format={ format }
          value={ !!startDate ? moment(startDate) : undefined }
          renderExtraFooter={ (mode: string) => this.renderDatePickerFooter(filter, mode, false) }
          onChange={ (date: moment.Moment | null) => {
            let startDate: string | null = null;
            let endDate: string | null = null;

            if (!!date) {
              switch (pickerMode) {
                case 'date':
                  startDate = moment(date).startOf('day').format('YYYY-MM-DD HH:mm:ss');
                  endDate = moment(date).endOf('day').format('YYYY-MM-DD HH:mm:ss');
                  break;
                case 'week':
                  startDate = moment(date).startOf('isoWeek').format('YYYY-MM-DD HH:mm:ss');
                  endDate = moment(date).endOf('isoWeek').format('YYYY-MM-DD HH:mm:ss');
                  break;
                case 'month':
                  startDate = moment(date).startOf('month').format('YYYY-MM-DD HH:mm:ss');
                  endDate = moment(date).endOf('month').format('YYYY-MM-DD HH:mm:ss');
                  break;
                case 'quarter':
                  startDate = moment(date).startOf('quarter').format('YYYY-MM-DD HH:mm:ss');
                  endDate = moment(date).endOf('quarter').format('YYYY-MM-DD HH:mm:ss');
                  break;
                case 'year':
                  startDate = moment(date).startOf('year').format('YYYY-MM-DD HH:mm:ss');
                  endDate = moment(date).endOf('year').format('YYYY-MM-DD HH:mm:ss');
                  break;
              }
            }

            this.setState({
              startDate: startDate,
              endDate: endDate,
            });
          } }
        />
      </Form.Item>
    );
  };

  renderFilter = (filters: RecordFilter[]) => {
    return filters.map((filter: RecordFilter) => {
      const { exportData } = this.state;

      switch (filter.type) {
        case 'select':
          let filterOptions = filter.options;

          // Filter properties by parent location
          if (filter.reference === 'property') {
            const selectedRegions = _.has(exportData, 'region') ? exportData.region : [];
            if (!_.isEmpty(selectedRegions)) {
              filterOptions = filterOptions.filter(option => !!option?.parent_id && selectedRegions.includes(option?.parent_id));
            }
          }

          return (
            <Form.Item
              label={ filter.label }
              key={ filter.reference }
            >
              <Select
                mode='multiple'
                maxTagCount={'responsive'}
                style={{ width: 200 }}
                placeholder={'All'}
                showSearch
                autoFocus
                allowClear
                dropdownMatchSelectWidth={false}
                filterOption={(input: string, option: any) => {
                  return option.children.toLowerCase().indexOf(input.toLowerCase()) !== -1;
                }}
                onChange={(recordIds: Array<number>) => {
                  this.setState({
                    exportData: { ...exportData, [filter.reference]: _.isEmpty(recordIds) ? [] : recordIds }
                  });
                }}
                value={ _.has(exportData, filter.reference) ? exportData[filter.reference] : undefined }
              >
                {filterOptions.map((option: any) => (
                  <Option key={`${option.title}_${option.id}`} value={option.id}>
                    {option.title}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          );

        case 'nested-select':
          const nestedSet = (data: any = []) => {
            return !_.isEmpty(data) && data.map((entity: any) => {
              const appendChildrenKeys = (children: any) => {

                // Prevent nesting
                if (_.isEmpty(children)) return null;

                return children.map((childEntity: any) => {
                  return {
                    'key': childEntity.id,
                    'id': childEntity.id,
                    'value': childEntity.id,
                    'title': childEntity.title,
                    'children': appendChildrenKeys(childEntity.children),
                  };
                });
              };

              return {
                'key': entity.id,
                'id': entity.id,
                'value': entity.id,
                'title': entity.title,
                'children': appendChildrenKeys(entity.children),
              };
            });
          };

          const nestedOptions = filter.options;

          return (
            <Form.Item
              label={ filter.label }
              key={ filter.reference }
            >
              <TreeSelect
                key={ filter.reference }
                style={{ width: 200 }}
                dropdownMatchSelectWidth={ false }
                placeholder={ filter.label }
                showCheckedStrategy={ SHOW_PARENT }
                value={ _.has(exportData, filter.reference) ? exportData[filter.reference] : [] }
                maxTagCount={ 5 }
                treeDefaultExpandedKeys={ nestedOptions.length > 1 ? [] : [nestedOptions[0].id] }
                treeCheckable
                multiple
                treeData={ nestedSet(nestedOptions) }
                filterTreeNode={ (input: string, option: any) => {
                  if (option) {
                    const filteredInput = input.toLocaleLowerCase();
                    const title = option.title && option.title.toLowerCase();

                    if (title.includes(filteredInput)) {
                      return true;
                    }
                  }

                  return false;
                } }
                onChange={(value: any) => {
                  this.setState({
                    exportData: { ...exportData, [filter.reference]: _.isEmpty(value) ? [] : value }
                  });
                }}
              />
            </Form.Item>
          );

        case 'datepicker':
          return this.renderDatePicker(filter);
      }
    });
  };

  render = () => {
    const { client_id, match } = this.props;
    const { isLoading, recordItem } = this.state;
    return (
      <BlockingSpinner isLoading={ isLoading }>
        <Jumbotron
          content={ recordItem?.title || '-' }
          tabs={ [
            {
              label: 'Export',
              node: this.renderExportTab(recordItem),
            },
            {
              label: 'Import',
              node: <Import
                uploadEndpoint={ `client/${client_id}/admin/export-import/${match.params.id}/import/upload` }
                downloadEndpoint={ `client/${client_id}/admin/export-import/${match.params.id}/import/download` }
                importEndpoint={ `client/${client_id}/admin/export-import/${match.params.id}/import/run` }
              />,
            }
          ] }
        />
      </BlockingSpinner>
    );
  };
};

const mapStateToProps = (store: AppState) => {
  return {
    user_id: store.UserState.user.id,
    client_id: store.ClientState.client_id,
    permissions: store.UserState.user.permissions,
  };
};

// Make functions available on props
const mapDispatchToProps = (dispatch: any) => {
  return {
    setBreadcrumbs: (value: Breadcrumb[], concat: boolean) => dispatch(setBreadcrumbs(value, concat)),
    setBreadcrumbsLoading: (value: boolean) => dispatch(setBreadcrumbsLoading(value)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RestrictionHoC(withRouter(ExportImportItem), 'access_admin_import'));