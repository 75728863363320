export enum ValidationResponseStatus {
  GENERIC_ERROR = 'GENERIC_ERROR',
  CORRUPTION_ERROR = 'CORRUPTION_ERROR',
  VERIFIED = 'VERIFIED',
  IMPORTED = 'IMPORTED',
};

export interface IValidationResponse {
  title: string;
  reference: string;
  status: ValidationResponseStatus;
  status_message:string[] | null;
  list: {
    columns: any[];
    data: any[];
  };
  export_id: string;
  file_hash: string;
  error_file: string;
};