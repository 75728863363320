// Libs
import React from 'react';
import classNames from 'classnames';
import moment from 'moment';
import _ from 'lodash';

// Components
import { Button, Checkbox, DatePicker, Select, TreeSelect } from "antd";

// Services
import { getUserSetting } from 'services/settings';

// Utils
import { nestedSet } from 'utils/utils';

// Interfaces
import { IFilter, IFilterOption } from 'components/insight/Insight.interfaces';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';

interface Props {
  filter: IFilter;
  filters: { [key: string]: any } | null;
  onSetFilters: (filters: { [key: string]: any } | null, isRange?: boolean) => void;
};

interface State {
  datePickerOpen: boolean;
  prevFilters: { [key: string]: any } | null;
};

interface CalendarTypeInterface {
  key: string;
  value: string;
  label: string;
  is_range: boolean;
};

const { SHOW_ALL } = TreeSelect;
const { RangePicker } = DatePicker;

const calendarTypes = [
  {
    key: 'custom_value',
    value: 'custom_value',
    label: 'Custom Date',
    is_range: false
  },
  {
    key: 'next_7',
    value: 'next_7',
    label: 'Next 7 Days',
    is_range: true
  },
  {
    key: 'next_28',
    value: 'next_28',
    label: 'Next 28 Days',
    is_range: true
  },
  {
    key: 'current_value_7',
    value: 'current_value_7',
    label: 'Current Date + 7',
    is_range: false
  },
  {
    key: 'current_value_28',
    value: 'current_value_28',
    label: 'Current Date + 28',
    is_range: false
  },
  {
    key: 'current_value',
    value: 'current_value',
    label: 'Current Date',
    is_range: false
  }
];

const rangeTypes = [
  {
    key: 'custom_value',
    value: 'custom_value',
    label: 'Custom Date',
    is_range: true
  },
  {
    key: 'next_7',
    value: 'next_7',
    label: 'Next 7 Days',
    is_range: true
  },
  {
    key: 'next_28',
    value: 'next_28',
    label: 'Next 28 Days',
    is_range: true
  },
  {
    key: 'current_value_7',
    value: 'current_value_7',
    label: 'Current Date + 7',
    is_range: true
  },
  {
    key: 'current_value_28',
    value: 'current_value_28',
    label: 'Current Date + 28',
    is_range: true
  },
];

class InsightFilter extends React.Component<Props, State> {

  state: State = {
    datePickerOpen: false,
    prevFilters: null
  };

  renderDatePickerFooter = (valueTypes: CalendarTypeInterface[], pickerMode: string, isRangePicker: boolean) => {
    const { filter, onSetFilters } = this.props;
    const filterValue = !!this.props.filters && _.has(this.props.filters, filter.id) ? this.props.filters[filter.id] : undefined;

    return (
      <>
        <Select
          allowClear
          style={ { width: isRangePicker ? '200px' : '100%' } }
          placeholder={ 'Calendar Type' }
          onClear={ () => {
            onSetFilters(null);
          } }
          onChange={ (value: string) => {

            const newFilterValue = { [filter.id]: { value_mode: value } };

            switch (value) {
              case 'next_7':
                _.set(newFilterValue, [filter.id, 'from'], moment());
                _.set(newFilterValue, [filter.id, 'to'], moment().add(5, 'days'));
                break;
              case 'next_28':
                _.set(newFilterValue, [filter.id, 'from'], moment());
                _.set(newFilterValue, [filter.id, 'to'], moment().add(28, 'days'));
                break;
            }

            const newFilters = Object.assign({}, this.props.filters, newFilterValue);

            const type = valueTypes.find(valueType => valueType.value === value);

            onSetFilters(newFilters, type?.is_range);
          } }
          value={ !!filterValue && _.has(filterValue, 'value_mode') ? filterValue.value_mode : 'custom_value' }
        >
          { valueTypes.map(type => (
              <Select.Option key={ type.key } value={ type.value }>{ type.label }</Select.Option>
          ) ) }
        </Select>
        <Button
          style={ { width: isRangePicker ? 'auto' : '48%', marginRight: isRangePicker ? '0' : '4%', marginLeft: isRangePicker ? '10px' : '0' } }
          type='primary'
          onClick={ () => this.setState({ datePickerOpen: false }) }
        >
          OK
        </Button>
        <Button
          style={ { width: isRangePicker ? 'auto' : '48%', marginLeft: isRangePicker ? '10px' : '0' } }
          onClick={ () => {
            onSetFilters(_.cloneDeep(this.state.prevFilters));
            this.setState({
              prevFilters: null,
              datePickerOpen: false
            });
          } }
        >
          Close
        </Button>
      </>
    );
  };

  render = () => {
    const { filter, onSetFilters } = this.props;
    const isRequired = filter?.settings?.required || false;
    const value = !!this.props.filters && !!this.props.filters[filter.id] ? this.props.filters[filter.id] : undefined;
    const { datePickerOpen } = this.state;

    switch (filter.type) {
      case 'select':
      case 'workflow_stage':
      case 'slider':
        const groups = !_.isEmpty(filter?.options) ? filter?.options?.reduce((acc: string[], curr: IFilterOption) => {
          if (!!curr?.group && !acc.includes(curr?.group)) {
            acc.push(curr.group);
          }
          return acc;
        }, []) : [];

        const getGroupedOptions = (groups: string[] | undefined = [], options: IFilterOption[] | undefined = []) => {
          return groups.map((group: string) => {
            return {
              'label': group,
              'options': options
                .filter((option: IFilterOption) => {
                  return option.group === group;
                })
                .map((option: IFilterOption) => {
                  return option;
                })
            };
          });
        };

        return (
          <Select
            className={ classNames({
              'Select-Field--has-error': isRequired && !value
            }) }
            allowClear
            showSearch
            mode={ !!filter?.config?.multiple ? 'multiple' : undefined }
            style={{ width: 250 }}
            placeholder={ 'All' }
            dropdownMatchSelectWidth={ false }
            filterOption={ (input: any, option: any) => {
              if (option.children) {
                return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
              } else if (option.label) {
                return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
              }
              return false;
            } }
            onChange={ (value: any) => {
              let newFilters = _.cloneDeep(this.props.filters);

              if (!value || (_.isArray(value) && _.isEmpty(value))) {
                newFilters && newFilters[filter.id] && delete newFilters[filter.id];
              } else {
                newFilters = {
                  ...this.props.filters,
                  [filter.id]: _.isArray(value) ? value : [value]
                };
              }

              onSetFilters(newFilters);
            } }
            value={ value }
            // @ts-ignore
            autoComplete="none"
          >
            { !_.isEmpty(groups) ? (
              getGroupedOptions(groups, filter.options).map((group: { label: string, options: IFilterOption[] }, index: number) => (
                <Select.OptGroup label={ _.upperFirst(group.label) } key={ index }>
                  { group.options.map((option: IFilterOption, _index: number) => (
                    <Select.Option key={ `${option.id}_${index}` } value={ option.id }>
                      { `${option.title}` }
                    </Select.Option>
                  ) ) }
                </Select.OptGroup>
              ) )
            ) : (
              filter.options?.map((option: IFilterOption, index: number) => (
                <Select.Option key={ index } value={ option.id }>
                  { option.title }
                </Select.Option>
              ))
            ) }
          </Select>
        );
      case 'category':
      case 'relationship':
      case 'dynamic_field_template':
      case 'dynamic_field_template_type':
      case 'resource':
        return (
          <TreeSelect
            className={ classNames({
              'Select-Field--has-error': isRequired && !value
            }) }
            style={ { width: 250 } }
            dropdownMatchSelectWidth={ false }
            placeholder={ 'All' }
            showCheckedStrategy={ SHOW_ALL }
            maxTagCount={ 5 }
            treeCheckable
            multiple
            value={ !!value ? value : [] }
            treeData={ nestedSet(filter.options) }
            filterTreeNode={ (input: string, option: any) => {
              if (option) {
                const filteredInput = input.toLocaleLowerCase();
                const title = option.title && option.title.toLowerCase();

                if (title.includes(filteredInput)) {
                  return true;
                }
              }

              return false;
            } }
            onChange={(value: any[]) => {

              let newFilters = _.cloneDeep(this.props.filters);

              if (!value.length) {
                newFilters && newFilters[filter.id] && delete newFilters[filter.id];
              } else {
                newFilters = Object.assign({}, this.props.filters, { [filter.id]: value });
              }

              onSetFilters(newFilters);
            }}
          />
        );
      case 'exclude_empty':
        return (
          <Checkbox
            checked={ value || false }
            onChange={ (event: CheckboxChangeEvent) => {
              const checked = !!event.target.checked;

              let newFilters = _.cloneDeep(this.props.filters);

              if (!checked) {
                newFilters && newFilters[filter.id] && delete newFilters[filter.id];
              } else {
                newFilters = Object.assign({}, this.props.filters, { [filter.id]: true });
              }

              onSetFilters(newFilters);
            } }
          >
            { filter.title }
          </Checkbox>
        );
      case 'datetime':
        const pickerMode: any = filter?.settings?.date_mode ? _.toLower(filter?.settings?.date_mode) : undefined;
        let format: any = undefined;

        // Format
        switch (pickerMode) {
          case 'date':
            format = getUserSetting('date_format');
            break;
          case 'week':
            format = '[w/c] WW - DD/MM/YY';
            break;
          case 'month':
            format = getUserSetting('date_format').replaceAll('/DD', '').replaceAll('/DD/', '').replaceAll('DD/', '');
            break;
        }

        if (filter?.settings?.range) {
          const rangeValue = {
            from: value?.from ? moment(value.from) : null,
            to: value?.to ? moment(value.to) : null
          };

          if (_.has(value, 'value_mode')) {
            switch (value.value_mode) {
              case 'current_value_7':
                _.set(rangeValue, 'from', moment());
                _.set(rangeValue, 'to', moment().add(5, 'days'));
                break;
              case 'current_value_28':
                _.set(rangeValue, 'from', moment());
                _.set(rangeValue, 'to', moment().add(28, 'days'));
                break;
            }
          }

          return (
            <RangePicker
              open={ datePickerOpen }
              onOpenChange={ open => this.setState({ datePickerOpen: true, prevFilters: _.cloneDeep(this.props.filters) }) }
              allowEmpty={ [true, true] }
              style={ { width: pickerMode === 'week' ? 300 : 250 } }
              placeholder={ ['All', 'All'] }
              picker={ pickerMode }
              format={ format }
              value={ !!value ? [rangeValue.from, rangeValue.to] : undefined }
              renderExtraFooter={ (mode: string) => this.renderDatePickerFooter(rangeTypes, mode, true) }
              onCalendarChange={ (dates: [moment.Moment | null, moment.Moment | null] | null) => {
                let newFilters = _.cloneDeep(this.props.filters);
                let newValue: any = null;

                if (!!dates) {
                  switch (pickerMode) {
                    case 'date':
                      const startOfDay = moment(dates[0]).isValid() ? moment(dates[0]).startOf('day').format('YYYY-MM-DD HH:mm:ss') : null;
                      const endOfDay = moment(dates[1]).isValid() ? moment(dates[1]).endOf('day').format('YYYY-MM-DD HH:mm:ss') : null;
                      newValue = { from: startOfDay, to: endOfDay };
                      break;
                    case 'week':
                      const startOfWeek = moment(dates[0]).isValid() ? moment(dates[0]).startOf('week').format('YYYY-MM-DD HH:mm:ss') : null;
                      const endOfWeek = moment(dates[1]).isValid() ? moment(dates[1]).endOf('week').format('YYYY-MM-DD HH:mm:ss') : null;
                      newValue = { from: startOfWeek, to: endOfWeek };
                      break;
                    case 'quarter':
                      const startOfQuarter = moment(dates[0]).isValid() ? moment(dates[0]).startOf('quarter').format('YYYY-MM-DD HH:mm:ss') : null;
                      const endOfQuarter = moment(dates[1]).isValid() ? moment(dates[1]).endOf('quarter').format('YYYY-MM-DD HH:mm:ss') : null;
                      newValue = { from: startOfQuarter, to: endOfQuarter };
                      break;
                    case 'month':
                      const startOfMonth = moment(dates[0]).isValid() ? moment(dates[0]).startOf('month').format('YYYY-MM-DD HH:mm:ss') : null;
                      const endOfMonth = moment(dates[1]).isValid() ? moment(dates[1]).endOf('month').format('YYYY-MM-DD HH:mm:ss') : null;
                      newValue = { from: startOfMonth, to: endOfMonth };
                      break;
                    case 'year':
                      const startOfYear = moment(dates[0]).isValid() ? moment(dates[0]).startOf('year').format('YYYY-MM-DD HH:mm:ss') : null;
                      const endOfYear = moment(dates[1]).isValid() ? moment(dates[1]).endOf('year').format('YYYY-MM-DD HH:mm:ss') : null;
                      newValue = { from: startOfYear, to: endOfYear };
                      break;
                  }
                }

                if(!!newValue) {
                  newFilters = Object.assign({}, this.props.filters, {[filter.id]: { ...newValue, value_mode: 'custom_value' }});
                }
                else {
                  newFilters && newFilters[filter.id] && delete newFilters[filter.id];
                }

                onSetFilters(newFilters);
              } }
            />
          );
        }

        return (
          <DatePicker
            open={ datePickerOpen }
            onOpenChange={ open => this.setState({ datePickerOpen: true, prevFilters: _.cloneDeep(this.props.filters) }) }
            style={ { width: 250 } }
            placeholder={ 'All' }
            picker={ pickerMode }
            format={ format }
            value={ !!value ? moment(value.from) : undefined }
            renderExtraFooter={ (mode: string) => this.renderDatePickerFooter(calendarTypes, mode, false) }
            onChange={ (date: moment.Moment | null) => {
              let newFilters = _.cloneDeep(this.props.filters);
              let newValue = null;

              if (!!date) {
                switch (pickerMode) {
                  case 'date':
                    const startOfDay = moment(date).startOf('day').format('YYYY-MM-DD HH:mm:ss');
                    const endOfDay = moment(date).endOf('day').format('YYYY-MM-DD HH:mm:ss');
                    newValue = { from: startOfDay, to: endOfDay };
                    break;
                  case 'week':
                    const startOfWeek = moment(date).startOf('isoWeek').format('YYYY-MM-DD HH:mm:ss');
                    const endOfWeek = moment(date).endOf('isoWeek').format('YYYY-MM-DD HH:mm:ss');
                    newValue = { from: startOfWeek, to: endOfWeek };
                    break;
                  case 'month':
                    const startOfMonth = moment(date).startOf('month').format('YYYY-MM-DD HH:mm:ss');
                    const endOfMonth = moment(date).endOf('month').format('YYYY-MM-DD HH:mm:ss');
                    newValue = { from: startOfMonth, to: endOfMonth };
                    break;
                  case 'quarter':
                    const startOfQuarter = moment(date).startOf('quarter').format('YYYY-MM-DD HH:mm:ss');
                    const endOfQuarter = moment(date).endOf('quarter').format('YYYY-MM-DD HH:mm:ss');
                    newValue = { from: startOfQuarter, to: endOfQuarter };
                    break;
                  case 'year':
                    const startOfYear = moment(date).startOf('year').format('YYYY-MM-DD HH:mm:ss');
                    const endOfYear = moment(date).endOf('year').format('YYYY-MM-DD HH:mm:ss');
                    newValue = { from: startOfYear, to: endOfYear };
                    break;
                }
              }

              if (!!newValue) {
                newFilters = Object.assign({}, this.props.filters, {[filter.id]: {...newValue, value_mode: 'custom_value' }});
              } else {
                newFilters && newFilters[filter.id] && delete newFilters[filter.id];
              }

              onSetFilters(newFilters);
            } }
          />
        );
    }

    return <></>;
  };
}

export default InsightFilter;
