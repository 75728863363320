// Libs
import * as React from 'react';
import _ from 'lodash';

// Components
import { APIProvider, Map, Marker, MapMouseEvent } from '@vis.gl/react-google-maps';

interface Props {
  latitude?: string;
  longitude?: string;
  onClick: (event:MapMouseEvent) => void;
};

export default function MapComponent({ latitude = undefined, longitude = undefined, onClick = () => {} }: Props) {

  const coordinates = latitude && longitude ? {
    lat: parseFloat(latitude),
    lng: parseFloat(longitude)
  } : {
    lat: parseFloat('53.430759'),
    lng: parseFloat('-2.961425')
  };

  return (
      <APIProvider apiKey={ 'AIzaSyCp2gUVWE_JWfESJWRPd9JsCn1y-qEQE90' }>
        <Map
          scrollwheel={ false }
          defaultZoom={ 10 }
          defaultCenter={ coordinates }
          disableDefaultUI
          zoomControl
          gestureHandling={ 'greedy' }
          onClick={ (event: MapMouseEvent) => {
            onClick(event);
          } }
        >
          <Marker position={ coordinates } />
        </Map>
      </APIProvider>
  );
};