// Libs
import * as React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { injectIntl, IntlShape } from 'react-intl';
import { connect } from 'react-redux';
import _ from 'lodash';

// Components
import { Button, Form, Input, Modal, Select } from 'antd';
import BlockingSpinner from 'components/blocking-spinner';
import BasicList from 'components/basic-list';
import Jumbotron from 'components/jumbotron';
import { RestrictionWrapper, hasPermission } from 'components/restriction';
import Dropdown, { Action as DropdownAction } from 'components/dropdown';
import PrioritySetCopyModal from 'views/workplace-service/kpi-library/PrioritySetCopyModal';
import CreateMetricSetModal from 'components/kpi-library/CreateMetricSetModal';

// Views
import MetricSetCopyModal from 'views/workplace-service/kpi-library/MetricSetCopyModal';

// Services
import { Api } from 'services/api';
import { getFormatedDate } from 'services/settings';
import Notification from 'services/notification';

// Actions
import { setSecondarySidebarRoutes, setBreadcrumbsLoading, setBreadcrumbs } from 'store/UI/ActionCreators';

// Interfaces
import { RecordFormEntity, UserEntity } from 'types/entities';
import AppState from 'store/AppState.interface';
import { Breadcrumb } from 'store/UI/State.interface';
import { UserPermissions } from 'types/permissions';
import { KpiMetricSetRow, KpiPrioritySet, KpiMetricType, MetricSet } from 'components/kpi-library/KpiLibrary.interfaces';

// Styles
import 'assets/styles/_layout.scss';

const { TextArea } = Input;

const API: Api = new Api();

interface Props {
  breadcrumbs?: any[];
  client_id: number;
  user: UserEntity;
  permissions: UserPermissions;
  intl: IntlShape;
  record?: RecordFormEntity;
  createDropdownKpiMetricSets?: any[];
  setBreadcrumbsLoading(value: boolean): void;
  setBreadcrumbs(breadcrumbs: Breadcrumb[], concat: boolean): void;
  setSecondarySidebarRoutes(routes: any[]): void;
};

interface State {
  activeKpiMetricSet: MetricSet | null;
  kpiMetricSets: MetricSet[];
  kpiPrioritySets: KpiPrioritySet[];
  kpiMetricTypes: KpiMetricType[];
  isFetching: boolean;
  isLoading: boolean;
  kpiPrioritySetId: number | null;
  isSavingKpiPrioritySet: boolean;
  copyMetricSet: KpiMetricSetRow | null;
  copyPrioritySet: KpiPrioritySet | null;
  showPriorityCreateDialog: boolean;
  isCreatingPriority: boolean;
  isCreatingMetricSet: boolean;
  showMetricSetCreateDialog: boolean;
  showDeleteDialog: boolean;
};

class KpiLibrary extends React.Component<Props, State> {

  mounted: boolean = false;
  editFormRef: any = React.createRef();
  createFormRef: any = React.createRef();

  state: State = {
    activeKpiMetricSet: null,
    kpiMetricSets: [],
    kpiPrioritySets: [],
    kpiMetricTypes: [],
    isFetching: false,
    isLoading: false,
    kpiPrioritySetId: null,
    isSavingKpiPrioritySet: false,
    copyMetricSet: null,
    copyPrioritySet: null,
    showPriorityCreateDialog: false,
    showMetricSetCreateDialog: false,
    showDeleteDialog: false,
    isCreatingPriority: false,
    isCreatingMetricSet: false,
  };

  componentDidMount = async () => {
    const { breadcrumbs, client_id, record } = this.props;

    this.mounted = true;

    this.props.setBreadcrumbs(breadcrumbs ? breadcrumbs : [
      { title: 'Home', path: '/' },
      { title: 'Workplace Services', path: '/workplace-services' },
      { title: 'KPI Library', path: '/workplace-services/kpi-library' }
    ], false);

    if (record && _.has(record, 'breadcrumbs')) {
      this.props.setBreadcrumbs(record.breadcrumbs, false);
    } else {
      this.props.setSecondarySidebarRoutes([]);
    }

    try {
      await new Promise((resolve) => this.setState({ isFetching: true }, () => resolve(null)));
      const response = await API.get(`client/${client_id}/kpi-library`, {
        entity_id: record?.id,
        entity_bundle: record?.bundle,
        entity_type: record?.type,
      });

      this.mounted && this.setState({
        kpiMetricSets: response.kpi_metric_sets,
        kpiPrioritySets: response.kpi_priority_sets,
        kpiMetricTypes: response.kpi_metric_types
      });

    } catch (error) {
      console.error('Error: ', error);
    } finally {
      this.mounted && this.setState({
        isFetching: false
      });
    }
  };

  componentWillUnmount = () => {
    this.props.setBreadcrumbs([], false);
    this.mounted = false;
  };

  onCopy = async (metric_set_id: number, title: string, callback: () => void) => {
    try {
      const { client_id } = this.props;
      const newKpiMetrics = await API.post(`client/${client_id}/kpi-library/metric-set/${metric_set_id}/copy`, {
        title: title
      });

      Notification('success', 'The Metric Set has been copied', 'Copied');

      this.setState({
        copyMetricSet: null,
        kpiMetricSets: !!newKpiMetrics ? newKpiMetrics : this.state.kpiMetricSets
      });
    } catch (error: any) {
      console.error(error);
      Notification('error', _.has(error, 'data') && !!error.data.message ? error.data.message : 'Failed to copy Metric Set', 'Failed');
    } finally {
      callback();
    }
  };

  onCopyPrioritySet = async (priority_set_id: number, title: string, callback: () => void) => {
    try {
      const { client_id } = this.props;
      const newKpiPrioritySets = await API.post(`client/${client_id}/kpi-library/priority-set/${priority_set_id}/copy`, {
        title: title
      });

      Notification('success', 'The Priority Set has been copied', 'Copied');

      this.setState({
        copyPrioritySet: null,
        kpiPrioritySets: !!newKpiPrioritySets ? newKpiPrioritySets : this.state.kpiPrioritySets
      });
    } catch (error: any) {
      console.error(error);
      Notification('error', _.has(error, 'data') && !!error.data.message ? error.data.message : 'Failed to copy Priority Set', 'Failed');
    } finally {
      callback();
    }
  };

  renderKpiMetricSets = (kpiMetricSets: MetricSet[]): JSX.Element => {
    const { permissions } = this.props;
    const { isCreatingMetricSet } = this.state;

    const columns = [
      {
        key: 'title',
        dataIndex: 'title',
        title: 'KPI Set',
        render: (title: string, row: MetricSet) => {
          return (
            <RouterLink className='primaryColor' to={ `/workplace-services/kpi-library/metric-set/${row.id}` }>
              { title }
            </RouterLink>
          );
        },
        sorter: true,
        filterable: true,
        ellipsis: true,
        width: 120,
      },
      {
        key: 'active_metrics',
        dataIndex: 'active_metrics',
        title: 'Active Metrics',
        render: (activeMetrics: number) => {
          if (!activeMetrics) return <span>-</span>;
          return <span className="primaryColor">{ activeMetrics }</span>;
        },
        sorter: true,
        filterable: false,
        ellipsis: true,
        width: 120,
      },
      {
        key: 'creator',
        dataIndex: 'creator',
        title: 'Creator',
        render: (creator: string) => {
          return <span className="primaryColor">{ creator }</span>;
        },
        sorter: true,
        filterable: true,
        ellipsis: true,
        width: 120,
      },
      {
        key: 'updated_at',
        dataIndex: 'updated_at',
        title: 'Last Modified',
        render: (updated_at: string) => getFormatedDate(updated_at, undefined, true),
        sorter: true,
        filterable: false,
        ellipsis: true,
        width: 120,
      },
    ];

    columns.push(
      {
        key: 'actions',
        dataIndex: 'actions',
        title: 'Actions',
        render: (__: any, row: any) => {

          const _actions: DropdownAction[] = [
            {
              node: '',
              onClick: () => {}
            }
          ];

          _actions.push({
            node: 'Duplicate',
            disabled: !hasPermission(permissions, 'kpi_metric_set_copy'),
            onClick: () => this.setState({ copyMetricSet: row })
          });

          _actions.push({
              node: <span key="remove">Remove</span>,
              disabled: !hasPermission(permissions, 'kpi_metric_set_delete') || row.active_metrics ? ["You don't have permission to delete this metric set"] : false,
              onClick: () => this.setState({ showDeleteDialog: true, activeKpiMetricSet: row }),
          });

          return <Dropdown actions={ _actions } />;
        },
        sorter: false,
        filterable: false,
        ellipsis: true,
        width: 30,
      }
    );

    const mapData = (metricSets: MetricSet[]) => {
      return metricSets.map((metricSet: MetricSet, index: number) => {
        return {
          'key': index,
          'id':  metricSet.id,
          'active_metrics': metricSet.active_metrics,
          'title': metricSet.title,
          'creator': metricSet.creator,
          'updated_at': metricSet.updated_at,
        };
      });
    };

    const rightActions = [
      {
        node: (
          <Button
            onClick={ () => this.setState({ showMetricSetCreateDialog: true }) }
            loading={ isCreatingMetricSet }
          >
            Create Set
          </Button>
        ),
      }
    ];

    return <BasicList columns={ columns } items={ mapData(kpiMetricSets) } rightActions={ rightActions } rawData />;
  };

  renderKpiPrioritySets = (kpiPrioritySets: KpiPrioritySet[]): JSX.Element => {
    const { isCreatingPriority } = this.state;

    const columns = [
      {
        key: 'title',
        dataIndex: 'title',
        title: 'Priority Set',
        render: (title: string, row: KpiPrioritySet) => {
          return (
            <RouterLink className='primaryColor' to={ `/workplace-services/kpi-library/priority-set/${row.id}` }>
              { title }
            </RouterLink>
          );
        },
        sorter: true,
        filterable: true,
        ellipsis: true,
      },
      {
        key: 'description',
        dataIndex: 'description',
        title: 'Description',
        sorter: false,
        filterable: true,
        ellipsis: false,
      },
      {
        key: 'actions',
        dataIndex: 'actions',
        title: '',
        render: (__: any, kpiPrioritySet: KpiPrioritySet) => {
          const { permissions } = this.props;

          const _actions: DropdownAction[] = [
            {
              node: '',
              onClick: () => {}
            }
          ];

          if (hasPermission(permissions, 'kpi_priority_set_copy')) {
            _actions.push({
              node: 'Duplicate',
              onClick: () => this.setState({ copyPrioritySet: kpiPrioritySet })
            });
          }

          if (hasPermission(permissions, 'kpi_priority_set_edit')) {
            _actions.push({
              node: 'Edit',
              onClick: () => this.setState({ kpiPrioritySetId: kpiPrioritySet.id })
            });
          }

          return <Dropdown actions={ _actions } />;
        },
        width: 100,
        sorter: false,
        ellipsis: true,
        filterable: false,
        align: 'center'
      }
    ];

    const mapData = (prioritySets: KpiPrioritySet[]) => {
      return prioritySets.map((prioritySet: KpiPrioritySet, index: number) => {
        return {
          'key': index,
          'id':  prioritySet.id,
          'title': prioritySet.title,
          'description': prioritySet.description,
        };
      });
    };

    const rightActions = [
      {
        node: (
          <Button
            onClick={ () => this.setState({ showPriorityCreateDialog: true }) }
            loading={ isCreatingPriority }
          >
            Create Priority
          </Button>
        ),
      }
    ];

    return <BasicList columns={ columns } items={ mapData(kpiPrioritySets) } rightActions={ rightActions } rawData />;
  };

  renderPrioritySetEditDialog = () => {
    const { user: { active_client } } = this.props;
    const { kpiPrioritySets, kpiMetricTypes, kpiPrioritySetId, isSavingKpiPrioritySet } = this.state;
    const kpiPrioritySet = kpiPrioritySets.find((_kpiPrioritySet: KpiPrioritySet) => _kpiPrioritySet.id === kpiPrioritySetId);
    const kpiPrioritySetMetricType = _.flatMap(kpiPrioritySet?.kpi_metric_types, (type: KpiMetricType) => type.id);
    return (
      <Modal
        visible
        centered
        title={ 'Edit Priority Set' }
        maskClosable={ !isSavingKpiPrioritySet }
        okText={ 'Save' }
        onOk={ () => {
          this.editFormRef
            .current
            .validateFields()
            .then( async (values: any) => {
              try {

                await new Promise((resolve) => this.setState({ isSavingKpiPrioritySet: true }, () => resolve(null)));

                const kpiPrioritySets = await API.put(`client/${active_client}/kpi-library/priority-set/${kpiPrioritySetId}`, {
                  data: {
                    title: values.title,
                    description: values.description || null,
                    types: values.types,
                  }
                });

                this.mounted && this.setState({
                  kpiPrioritySets: kpiPrioritySets,
                  kpiPrioritySetId: null,
                }, () => {
                  Notification('success', 'Your changes have been saved.', 'Kpi Priority Set Updated');
                });

              } catch (error) {
                Notification('error', '', 'Failed');
              } finally {
                this.mounted && this.setState({
                  isSavingKpiPrioritySet: false
                });
              }
            })
            .catch((info: any) => {
              console.error('Invalid state');
            });
        } }
        onCancel={ () => this.setState({
          kpiPrioritySetId: null,
        }) }
        cancelButtonProps={ {
          disabled: isSavingKpiPrioritySet
        } }
        okButtonProps={ {
          disabled: false,
          loading: isSavingKpiPrioritySet,
        } }
      >
        <Form
          ref={ this.editFormRef }
          layout="vertical"
          initialValues={ {
            title: kpiPrioritySet?.title,
            types: kpiPrioritySetMetricType,
            description: kpiPrioritySet?.description,
          } }
        >
          <Form.Item
            label="Title"
            name="title"
            rules={ [{ required: true, message: 'Required' }] }
          >
            <Input />
          </Form.Item>
          <Form.Item name="types" label={ 'Compatible Metric Types' }>
            <Select
              showSearch
              mode="multiple"
              placeholder={ 'All' }
              filterOption={ (input: any, option: any) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 }
            >
              { kpiMetricTypes.map((kpiMetricType: any) => (
                <Select.Option key={ `kpi-metric-type-${kpiMetricType.id}` } value={ kpiMetricType.id }>
                  { kpiMetricType.title }
                </Select.Option>
              )) }
            </Select>
          </Form.Item>
          <Form.Item
            label="Description"
            name="description"
            rules={ [{max: 250, message: "Value should be less than 250 character",}] }
          >
            <TextArea />
          </Form.Item>
        </Form>
      </Modal>
    );
  };

  renderPrioritySetCreateDialog = () => {
    const { client_id } = this.props;
    const { isCreatingPriority, kpiPrioritySets, kpiMetricTypes } = this.state;
    return (
      <Modal
        visible
        centered
        title={ 'Create Priority Set' }
        maskClosable={ !isCreatingPriority }
        okText={ 'Create' }
        onOk={ () => {
          this.createFormRef
            .current
            .validateFields()
            .then( async (values: any) => {
              try {
                await new Promise((resolve) => this.setState({ isCreatingPriority: true }, () => resolve(null)));

                const createdPriority: KpiPrioritySet = await API.post(`client/${client_id}/kpi-library/priority-set`, {
                  data: {
                    title: values.title,
                    reference: values.reference,
                    types: values.types,
                    description: values.description || null,
                  }
                });

                this.mounted && this.setState({
                  kpiPrioritySets: [...kpiPrioritySets, createdPriority]
                }, () => {
                  Notification('success', 'Kpi priority set have been created.', 'Kpi Priority Created');
                });

              } catch (error) {
                Notification('error', '', 'Failed');
              } finally {
                this.mounted && this.setState({
                  isCreatingPriority: false,
                  showPriorityCreateDialog: false
                });
              }
            })
            .catch((info: any) => {
              console.error('Invalid state');
            });
        } }
        onCancel={ () => this.setState({
          showPriorityCreateDialog: false
        }) }
        cancelButtonProps={ {
          disabled: isCreatingPriority
        } }
        okButtonProps={ {
          disabled: false,
          loading: isCreatingPriority,
        } }
      >
        <Form
          ref={ this.createFormRef }
          layout="vertical"
          initialValues={ {
            title: '',
            reference: '',
            types: [],
            description: '',
          } }
        >
          <Form.Item
            label="Title"
            name="title"
            rules={ [{ required: true, message: 'Required' }] }
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Reference"
            name="reference"
            rules={ [{ required: true, message: 'Required' }] }
          >
            <Input />
          </Form.Item>
          <Form.Item name="types" label={ 'Compatible Metric Types' }>
            <Select
              showSearch
              mode="multiple"
              placeholder={ 'All' }
              filterOption={ (input: any, option: any) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 }
            >
              { kpiMetricTypes.map((kpiMetricType: any) => (
                <Select.Option key={ `kpi-metric-type-${kpiMetricType.id}` } value={ kpiMetricType.id }>
                  { kpiMetricType.title }
                </Select.Option>
              )) }
            </Select>
          </Form.Item>
          <Form.Item
            label="Description"
            name="description"
            rules={ [{ max: 250, message: "Value should be less than 250 character" }] }
          >
            <TextArea />
          </Form.Item>
        </Form>
      </Modal>
    );
  };

  renderMetricSetCreateDialog = () => {
    const { client_id } = this.props;
    const { isCreatingMetricSet, kpiMetricSets } = this.state;
    return (
      <Modal
        visible
        centered
        title={ 'Create Metric Set' }
        maskClosable={ !isCreatingMetricSet }
        okText={ 'Create' }
        onOk={ () => {
          this.createFormRef
            .current
            .validateFields()
            .then( async (values: any) => {
              try {
                await new Promise((resolve) => this.setState({ isCreatingMetricSet: true }, () => resolve(null)));

                const createdMetricSet: MetricSet = await API.post(`client/${client_id}/kpi-library/metric-set`, {
                  data: {
                    title: values.title,
                  }
                });

                this.mounted && this.setState({
                  kpiMetricSets: [...kpiMetricSets, createdMetricSet]
                }, () => {
                  Notification('success', 'Kpi metric set have been created.', 'Kpi Metric Set Created');
                });

              } catch (error) {
                Notification('error', '', 'Failed');
              } finally {
                this.mounted && this.setState({
                  isCreatingMetricSet: false,
                  showMetricSetCreateDialog: false
                });
              }
            })
            .catch((info: any) => {
              console.error('Invalid state');
            });
        } }
        onCancel={ () => this.setState({
          showMetricSetCreateDialog: false
        }) }
        cancelButtonProps={ {
          disabled: isCreatingMetricSet
        } }
        okButtonProps={ {
          disabled: false,
          loading: isCreatingMetricSet,
        } }
      >
        <Form
          ref={ this.createFormRef }
          layout="vertical"
          initialValues={ {
            title: '',
          } }
        >
          <Form.Item
            label="Title"
            name="title"
            rules={ [{ required: true, message: 'Required' }] }
          >
            <Input />
          </Form.Item>
        </Form>
      </Modal>
    );
  };

  renderDeleteDialog = (metricSet: MetricSet) => {
    const { client_id, record } = this.props;
    const { isLoading } = this.state;
    return (
      <Modal
        visible
        centered
        title={ 'Remove Notification' }
        maskClosable={ !isLoading }
        closable={ !isLoading }
        okButtonProps={{
          danger: true,
          disabled: isLoading,
          loading: isLoading,
        }}
        cancelButtonProps={{
          disabled: isLoading,
        }}
        onOk={ () => this.mounted && this.setState({
          isLoading: true,
        }, async () => {

          try {

            const metricSets = await API.delete(`client/${client_id}/kpi-library/metric-set/${metricSet.id}`, {
              entity_id: record?.id,
              entity_bundle: record?.bundle,
              entity_type: record?.type
            });

            this.mounted && this.setState({
              kpiMetricSets: metricSets,
            }, () => {
              Notification('success', `Removed kpi metric set`);
            });

          } catch (error) {
            Notification('error', `Failed to remove kpi metric set`);
            console.error('Error: ', error);
          } finally {
            this.mounted && this.setState({
              showDeleteDialog: false,
              activeKpiMetricSet: null,
              isLoading: false,
            });
          }

        } )}
        onCancel={ () => this.setState({ showDeleteDialog: false, activeKpiMetricSet: null }) }
      >
        <p>Are you sure you want to remove this metric set?</p>
      </Modal>
    );
  };

  render = (): JSX.Element => {
    const { client_id, record, createDropdownKpiMetricSets, permissions } = this.props;
    const { activeKpiMetricSet, kpiMetricSets, kpiPrioritySets, kpiPrioritySetId, isFetching, copyMetricSet, copyPrioritySet, showDeleteDialog, showPriorityCreateDialog, showMetricSetCreateDialog } = this.state;

    const tabs = [
      {
        label: 'Metric Sets',
        node: (
          <RestrictionWrapper restricted={ !hasPermission(permissions, 'can_manage_kpi_metrics') }>
            { this.renderKpiMetricSets(kpiMetricSets) }
          </RestrictionWrapper>
        )
      }
    ];

    // Show priority set tab only when in core library
    if (!record) {
      tabs.push({
        label: 'Priority Sets',
        node: (
          <RestrictionWrapper restricted={ !hasPermission(permissions, 'can_manage_kpi_priorities') }>
            { this.renderKpiPrioritySets(kpiPrioritySets) }
          </RestrictionWrapper>
        )
      });
    }

    return (
      <>
        <BlockingSpinner isLoading={ isFetching }>
          <Jumbotron
            title={ record ? record.title : 'KPI Library' }
            content={ record ? 'Kpi Library' : null }
            tabs={ tabs }
          />
          { kpiPrioritySetId && this.renderPrioritySetEditDialog() }
        </BlockingSpinner>
        { copyMetricSet &&
          <MetricSetCopyModal
            metricSet={ copyMetricSet }
            onClose={ () => this.setState({ copyMetricSet: null }) }
            onCopy={ this.onCopy }
          />
        }
        { showPriorityCreateDialog && this.renderPrioritySetCreateDialog() }
        { showMetricSetCreateDialog &&
          <CreateMetricSetModal
            client_id={ client_id }
            entity_id={ record?.id }
            entity_bundle={ record?.bundle}
            entity_type={ record?.type }
            kpiMetricSets={ createDropdownKpiMetricSets }
            onClose={ (createdMetricSet?: MetricSet) => {
              this.setState({
                showMetricSetCreateDialog: false,
                kpiMetricSets: createdMetricSet ? [...kpiMetricSets, createdMetricSet] : kpiMetricSets
              });
            } }
          />
        }
        { copyPrioritySet &&
          <PrioritySetCopyModal
            prioritySet={ copyPrioritySet }
            onClose={ () => this.setState({ copyPrioritySet: null }) }
            onCopy={ this.onCopyPrioritySet }
          />
        }
        { showDeleteDialog && activeKpiMetricSet && this.renderDeleteDialog(activeKpiMetricSet) }
      </>
    );
  };
}

// Make data available on props
const mapStateToProps = (store: AppState) => {
  return {
    permissions: store.UserState.user.permissions,
    user: store.UserState.user,
    client_id: store.ClientState.client_id,
  };
};

// Make functions available on props
const mapDispatchToProps = (dispatch: any) => {
  return {
    setSecondarySidebarRoutes: (value: any[]) => dispatch(setSecondarySidebarRoutes(value)),
    setBreadcrumbsLoading: (value: boolean) => dispatch(setBreadcrumbsLoading(value)),
    setBreadcrumbs: (value: Breadcrumb[]) => dispatch(setBreadcrumbs(value)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(KpiLibrary));
