// Interfaces
import { FieldConfig, FormField } from 'components/form/form-wrapper/FormWrapper.interface';
import { Competence, DynamicFieldTemplate, Milestone, Role } from 'views/admin/templates/Templates.interfaces';
import { WorkflowStage } from 'components/workflow/Workflow.interface';

export interface ActivityRecord {
  id: number;
  parent_id: number | null;
  key: string;
  comment_count: number;
  evidence_count: number;
  document_count: number;
  activity_order: number;
  activity_id: string | null;
  activity_title: string;
  activity_tooltip: string;
  activity_workflow_context: string | null;
  entity_bundle?: string;
  entity_type?: string;

  activity_planned_start_date: string | null;
  activity_planned_duration: number | null;
  activity_planned_completion_date: string | null;
  activity_planned_completion_date_status: Status;

  activity_forecast_start_date: string | null;
  activity_forecast_duration: number | null;
  activity_forecast_completion_date: string | null;

  activity_actual_start_date: string | null;
  activity_actual_duration: number | null;
  activity_actual_completion_date: string | null;

  activity_description: string;
  activity_url_label: string | null;
  activity_url: string | null;
  activity_resource: number[];
  activity_milestone: number[];
  activity_competency: number[];
  activity_required: boolean;
  activity_completion: boolean;
  activity_not_applicable: boolean;
  activity_comment: boolean;
  activity_evidence: boolean;
  activity_document: boolean;
  children?: ActivityRecord[] | null;

  activity_workflow_stage: WorkflowStage | null;
};

export interface FieldValues {
  activities: ActivityRecord[];
  start_date?: string;
};

export interface ActivityManagementConfig extends FieldConfig {
  can_view_activity?: boolean;
  locked_dates?: {
    planned: string | boolean;
    forecast: string | boolean;
    actual: string | boolean;
  };
};

export interface Field extends FormField {
  values: any; // property is of type FieldValues
  links?: DynamicFieldTemplate[];
  resource?: Role[];
  milestones?: Milestone[];
  competencies?: Competence[];
  options?: any[];
  entity_label?: string;
  entity_bundle?: string;
  entity_type?: string;
  can_create?: boolean;
  hasFilters?: boolean;
  config: ActivityManagementConfig;
  activity_document_folders?: number[];
  activity_evidence_folders?: number[];
  target_stages?: WorkflowStage[];
  default_workflow_stage?: WorkflowStage | null;
};

export enum Alignment {
  center = 'center',
  left = 'left',
  right = 'right',
};

export interface Modified {
  [key: string]: string[];
  removed?: any;
  projectStartDate?: any;
};

export type OptionId = number | string;

export enum OPTION_REFERENCES {
  YES = 'yes',
  NO = 'no',
};

export interface Status {
  color: string | undefined;
  text: string | undefined;
};