// Interfaces
import { Comment } from 'components/comment/Comment.interface';

export interface Workflow {
  id: number;
  reference: string;
  title: string;
  description: string;
  config: WorkflowConfig;
  stages: WorkflowStage[];
};

export interface WorkflowConfig {
  show_in_form: boolean;
};

export interface WorkflowStagePosition {
  x: string;
  y: string;
};

export interface WorkflowStage {
  id: number;
  title: string;
  reference: string;
  description: string;
  position: WorkflowStagePosition;
  current: boolean;
  start: number;
  transitions: WorkflowTransition[];
  context: WorkflowStageContext;
  can_version: boolean;
  locked: boolean;
};

export enum WorkflowStageContext {
  Todo = 'TODO',
  Resolved = 'RESOLVED',
  Problem = 'PROBLEM',
  Progress = 'PROGRESS',
  Closed = 'CLOSED',
};

export interface WorkflowTransition {
  id: number;
  title: string;
  reference: string;
  description: string | false;
  source_stage_id: number;
  source_stage_title: string;
  source_handle: WorkflowTransitionHandle;
  target_stage_id: number;
  target_stage_title: string;
  target_handle: WorkflowTransitionHandle;
  trigger: WorkflowTransitionTrigger;
  conditions: WorkflowCondition[];
  requires_comment: boolean;
  requires_comment_description: string;
};

export enum WorkflowTransitionTrigger {
  Manual = 'manual',
  Automatic = 'auto',
};

export enum WorkflowTransitionHandle {
  Top = 1,
  Right,
  Bottom,
  Left,
};

export interface WorkflowCondition {
  id: number;
  type: WorkflowConditionType;
  config: WorkflowConditionConfig;
  description: string;
  pass: boolean;
};

export enum WorkflowConditionType {
  Role_required = 'ROLE_REQUIRED',
  Mandatory_roles_filled = 'MANDATORY_ROLES_FILLED',
  Field_required = 'FIELD_REQUIRED',
  Space_validated = 'SPACE_VALIDATED',
  Parent_Maximum_Annual_Contract_Value = 'PARENT_MAXIMUM_ANNUAL_CONTRACT_VALUE',
  Date_passed = 'DATE_PASSED',
  Dynamic_Field_Complete = 'DYNAMIC_FIELD_COMPLETE',
};

export interface WorkflowConditionConfig {
  audit: boolean;
  comment: boolean;
  form: boolean;
  role: boolean;
  root_entity: boolean;
  version: boolean;
  workflow: boolean;
  hide_on_fail?: boolean;
};

export interface WorkflowPreview {
  id: number;
  title: string;
  start: number;
  description: string;
  current: boolean;
  context: string;
};

export interface WorkflowHistory {
  before: string;
  after: string;
  context: WorkflowHistoryContext;
  transitioned_by: string;
  created_at: string;
  comment?: Comment;
};

export enum WorkflowHistoryContext {
  Todo = 'TODO',
  Resolved = 'RESOLVED',
  Problem = 'PROBLEM',
};

export enum CustomFlowElementType {
  Edge = 'customEdge',
  Node = 'customNode',
};
