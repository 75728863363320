// Types
import ClientActionTypes from './ActionTypes.enum';
import ClientRecord from 'store/Client/Record.interface';
import AppState from 'store/AppState.interface';
import store from 'store/Store';
import { Api } from 'services/api';
import Console from 'utils/console';
import { authenticationFailure, authenticationSuccess, receiveMe } from 'store/User/ActionCreators';
import { getNotifications } from 'store/Notification/Actions';

// Services
import history from 'utils/history';

export const getClientSuccess = (client: ClientRecord) => ({
  type: ClientActionTypes.GET_CLIENT_SUCCESS,
  payload: client
});

export const getClientFailure = () => ({
  type: ClientActionTypes.GET_CLIENT_FAILURE,
});

export const setClientSuccess = (client: ClientRecord) => ({
  type: ClientActionTypes.SET_CLIENT_SUCCESS,
  payload: client,
});

export const setClientFailure = () => ({
  type: ClientActionTypes.SET_CLIENT_FAILURE,
});

export function setActiveClient(client_id: number, callback?: () => void) {
  return async (dispatch: (action: Record<string, any>) => void) => {
    try {
      const api = new Api();
      const appState: AppState = store.getState();
      const user_id = appState.UserState.user.id;

      if (!!client_id) {
        const user = await api.changeClient(user_id, client_id);
        const client = await api.getClient(client_id);
        dispatch(receiveMe(user));
        dispatch(setClientSuccess(client));
        dispatch(getNotifications());
        dispatch(authenticationSuccess());
        history.push('/');
        callback && callback();
      } else {
        throw new Error('active_client does not exist');
      }
    } catch (error) {
      Console.error(error);

      switch (error.callee) {
        case 'getClient':
          dispatch(getClientFailure());
          dispatch(authenticationFailure('Unable to fetch client'));
          break;

        case 'changeClient':
          dispatch(setClientFailure());
          dispatch(authenticationFailure('Unable to set client'));
          break;

        default:
          dispatch(authenticationFailure('Unknown error'));
          break;
      }
    }
  };
};

export const setClientSetting = (client_id: number, key: string, value: any) => {
  return (dispatch: (action: Record<string, any>) => void) => {
    return new Promise((resolve, reject) => {
      try {
        const api = new Api();

        if (!client_id) throw new Error("Client doesn't exist");

        api.setClientSetting(client_id, key, value)
          .then((client) => {
            dispatch(setClientSuccess(client));
            resolve(client);
          })
          .catch((error) => {
            reject(error);
          });

      } catch (error) {
        Console.error(error);
      }
    });
  };
};

export const setClientLogotype = (client_id: number, logotype: any) => {
  return (dispatch: (action: Record<string, any>) => void) => {
    return new Promise((resolve, reject) => {
      try {
        const api = new Api();

        if (!client_id) throw new Error("Client doesn't exist");

        api.setClientLogotype(client_id, logotype)
          .then((client) => {
            dispatch(setClientSuccess(client));
            resolve(client);
          })
          .catch((error) => {
            reject(error);
          });

      } catch (error) {
        Console.error(error);
      }
    });
  };
};
