// Libs
import React from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { v4 as uuidv4 } from 'uuid';
import { Link } from 'react-router-dom';
import _ from 'lodash';

// Components
import BlockingSpinner from 'components/blocking-spinner';
import Jumbotron from 'components/jumbotron';
import { RestrictionHoC } from 'components/restriction';
import { Button, Collapse, Popconfirm, Form, Input, Modal, Tooltip, Select } from 'antd';
import Dropdown, { Action as DropdownAction } from 'components/dropdown';

import DragSortingList from 'components/drag-sorting-list';
import PreviewDynamicField from 'components/form/field/dynamic-field-template/PreviewDynamicField';
import DynamicFieldComponentList from 'views/admin/templates/dynamic-field/DynamicFieldComponentList';
import Badge, { BadgeType } from 'components/badge';
import Timeline from 'components/timeline';

// Services
import { Api } from 'services/api';
import Notification from 'services/notification';

// Actions
import { setBreadcrumbsLoading, setBreadcrumbs } from 'store/UI/ActionCreators';

// Icons
import Icon, { EyeOutlined, PlusOutlined, MenuOutlined, DeleteOutlined, QuestionCircleOutlined, CopyOutlined } from '@ant-design/icons';
import { ReactComponent as InfoIcon } from 'assets/svg/info.svg';

// Interfaces
import AppState from 'store/AppState.interface';
import { Breadcrumb } from 'store/UI/State.interface';
import { TableType } from 'components/drag-sorting-list/DragSortingList.interfaces';
import { IDynamicFieldTemplate, IDynamicFieldTemplateComponent, IDynamicFieldTemplateType, IDynamicFieldTemplateSet, ComponentType } from 'views/admin/templates/Templates.interfaces';
import { SelectListItem, SliderRange } from 'components/form/field/dynamic-field-template/DynamicFieldTemplate.interface';
import { TimelineItem } from 'components/timeline/Timeline.interface';

// Utils
import { arrayMoveImmutable } from 'utils/formSetup';
import { getFormatedDate } from 'services/settings';
import history from 'utils/history';

// Styles
import 'views/admin/templates/dynamic-field/DynamicFieldTemplate.scss';

const API: Api = new Api();
const { Panel } = Collapse;

interface Props {
  client_id: number;
  template_type_id: any;
  template_id: any;
  setBreadcrumbsLoading(value: boolean): void;
  setBreadcrumbs(breadcrumbs: Breadcrumb[], concat: boolean): void;
  preventSetBreadcrumbs?: boolean;
};

interface State {
  originalTemplate: IDynamicFieldTemplate | null;
  modifiedTemplate: IDynamicFieldTemplate | null;
  templateType: IDynamicFieldTemplateType | null;
  selectLists: SelectListItem[] | null;
  sliderRanges: SliderRange[] | null;
  selectedSetId: number | string | undefined;
  deleteSetKey: number | string | null | undefined;
  componentPlaceholder: Partial<IDynamicFieldTemplateComponent> | null;
  showCreateSetModal: boolean;
  showCreateComponentModal: boolean;
  showDeleteSetPopover: boolean;
  showPreviewModal: boolean;
  showPublishModal: boolean;
  isLoadingPreview: boolean;
  isLoading: boolean;
  isSaving: boolean;
  isPublishing: boolean;
  isVersioning: boolean;
};

class DynamicFieldTemplate extends React.Component<Props, State> {

  mounted: boolean = false;
  formRef: any = React.createRef();
  createSetFormRef: any = React.createRef();
  editFormRef: any = React.createRef();

  state: State = {
    originalTemplate: null,
    modifiedTemplate: null,
    templateType: null,
    selectLists: null,
    sliderRanges: null,
    selectedSetId: undefined,
    deleteSetKey: null,
    componentPlaceholder: null,
    showCreateSetModal: false,
    showCreateComponentModal: false,
    showDeleteSetPopover: false,
    showPreviewModal: false,
    showPublishModal: false,
    isLoadingPreview: false,
    isLoading: false,
    isSaving: false,
    isPublishing: false,
    isVersioning: false,
  };

  componentDidMount = () => {
    this.mounted = true;
    this.fetchDependencies();
  };

  componentDidUpdate = (prevProps: Props, prevState: State) => {
    if (prevState.selectedSetId !== this.state.selectedSetId) {
      this.formRef && this.formRef.current && this.formRef.current.resetFields();
    }

    if (prevProps.template_id !== this.props.template_id) {
      this.fetchDependencies();
    }
  };

  componentWillUnmount = () => {
    if (!this.props.preventSetBreadcrumbs) {
      this.props.setBreadcrumbs([], false);
    }
    this.mounted = false;
  };

  fetchDependencies = async () => {
    const { client_id, template_type_id, template_id, setBreadcrumbs, preventSetBreadcrumbs } = this.props;

    try {

      !preventSetBreadcrumbs && this.props.setBreadcrumbsLoading(true);

      await new Promise((resolve) => this.setState({ isLoading: true }, () => resolve(null)));

      const selectLists = await API.get(`client/${client_id}/admin/content-manager/select-lists?include_options=true`);
      const sliderRanges = await API.get(`client/${client_id}/admin/content-manager/slider-ranges?include_options=true`);
      const templateType = await API.get(`client/${client_id}/admin/templates/dynamic-field/types/${template_type_id}`);
      const template = await API.get(`client/${client_id}/admin/templates/dynamic-field/types/${template_type_id}/templates/${template_id}/custom`);

      !preventSetBreadcrumbs && setBreadcrumbs([
        { title: 'Home', path: '/' },
        { title: 'Admin', path: '/admin' },
        { title: 'Template Types', path: '/admin/templates/dynamic-field' },
        { title: templateType.title, path: `/admin/templates/dynamic-field/type/${templateType.id}` },
        { title: template.title, path: null },
      ], false);

      this.mounted && this.setState({
        originalTemplate: _.cloneDeep(template),
        modifiedTemplate: _.cloneDeep(template),
        templateType: templateType,
        selectLists: selectLists,
        sliderRanges: sliderRanges,
        selectedSetId: _.has(template, 'sets') && !_.isEmpty(template.sets) ? template.sets[0].id : null,
      });

    } catch (error) {
      console.error('Error: ', error);
    } finally {

      !preventSetBreadcrumbs && this.props.setBreadcrumbsLoading(false);

      this.mounted && this.setState({
        isLoading: false
      });
    }
  };

  modifySet = (dynamicFieldTemplateSets: IDynamicFieldTemplateSet[], setId: number | string, key: string, value: any) => {
    return dynamicFieldTemplateSets.map((dynamicFieldTemplateSet: IDynamicFieldTemplateSet) => {
      if (dynamicFieldTemplateSet.id === setId) {
        dynamicFieldTemplateSet = _.set(dynamicFieldTemplateSet, [key], value);
      }

      return {
        ...dynamicFieldTemplateSet,
      };
    });
  };

  modifyComponent = (components: IDynamicFieldTemplateComponent[], componentId: number, key: string, value: any) => {
    return components.map((component: IDynamicFieldTemplateComponent) => {
      if (component.id === componentId) {
        component = _.set(component, [key], value);
      }

      return {
        ...component,
      };
    });
  };

  getComponentTypeLabel = (componentType: string, componentTypes: ComponentType[] | undefined) => {
    const type = componentTypes && componentTypes.find((_type: ComponentType) => _type.id === componentType);
    if (type) {
      return type.title;
    }

    return componentType;
  };

  shouldLockAssessmentType = (template: IDynamicFieldTemplate): boolean => {
    return template?.sets?.some((set: IDynamicFieldTemplateSet) => {
      return set?.components.some((component: IDynamicFieldTemplateComponent) => {
        if (component.config?.allow_scoring) {
          return true;
        }
        return false;
      });
    });
  };

  handleSave = async (template: IDynamicFieldTemplate) => {
    const { client_id, template_type_id } = this.props;

    try {
      await new Promise((resolve) => this.setState({ isSaving: true }, () => resolve(null)));

      const newTemplate = await API.put(`client/${client_id}/admin/templates/dynamic-field/types/${template_type_id}/templates/${template.id}/custom`, {
        template: template
      });

      this.mounted && this.setState({
        originalTemplate: _.cloneDeep(newTemplate),
        modifiedTemplate: _.cloneDeep(newTemplate),
        selectedSetId: _.has(newTemplate, 'sets') && !_.isEmpty(newTemplate.sets) ? newTemplate.sets[0].id : null,
      });

      Notification('success', 'Saved template', 'Saved');

    } catch (error) {
      Notification('error', 'Failed to save template', 'Failed');
    } finally {
      this.setState({ isSaving: false });
    }
  };

  handleCreateVersion = async (template: IDynamicFieldTemplate) => {
    const { client_id, template_type_id } = this.props;

    try {

      await new Promise((resolve) => this.setState({ isVersioning: true }, () => resolve(null)));

      const newTemplateVerion: number = await API.put(`client/${client_id}/admin/templates/dynamic-field/types/${template_type_id}/templates/${template.id}/version`);

      history.push(`/admin/templates/dynamic-field/type/${template_type_id}/template/${newTemplateVerion}`);

    } catch (error) {
      Notification('error', 'Failed to duplicate the template', 'Failed');
    } finally {
      this.setState({
        isVersioning: false,
      });
    }
  };

  onReorderSet = async (template: IDynamicFieldTemplate, dragIndex: number, hoverIndex: number): Promise<void> => {
    if (dragIndex !== hoverIndex) {
      const sets = arrayMoveImmutable<IDynamicFieldTemplateSet>(template.sets, dragIndex, hoverIndex).filter((element: any) => !!element);
      this.setState({
        modifiedTemplate: _.set(_.cloneDeep(template), 'sets', sets.map((set: IDynamicFieldTemplateSet, idx: number) => ({ ...set, order: idx + 1})))
      });
    }
  };

  onDeleteSet = async (template: IDynamicFieldTemplate, setId: number | string | undefined) => {
    this.setState({
      selectedSetId: undefined,
      showDeleteSetPopover: false,
      deleteSetKey: null,
      modifiedTemplate: _.set(_.cloneDeep(template), 'sets', template.sets.filter((set: IDynamicFieldTemplateSet) => {
        if (set.id === setId || set.key === setId) {
          return false;
        }
        return true;
      }))
    });
  };

  duplicateSet = (template: IDynamicFieldTemplate, set: IDynamicFieldTemplateSet) => {
    const newSetKey = uuidv4();
    const newComponents: IDynamicFieldTemplateComponent[] = [...set.components];

    newComponents.forEach((component: IDynamicFieldTemplateComponent) => {
      const check = (_value: any, parent: any) => {
        component.id = null;
        component.key = uuidv4();
        component.template_set_id = newSetKey;
        component.parent_component_id = parent;

        // loop dependencies and set to null
        if (_.has(_value, 'dependencies') && !_.isEmpty(_value.dependencies)) {
          _value.dependencies.forEach((dependency: any) => {
            dependency.component_id = null;
          });
        }

        if (_.has(_value, 'children') && !_.isEmpty(_value.children)) {
          _value.children.forEach((_value: any) => {
            check(_value, _value.parent_component_id);
          });
        }
      };

      return check(component, component.parent_component_id);
    });

    const newSet = {
      id: null,
      key: newSetKey,
      template_id: template.id,
      title: set.title + ' Copy',
      order: template.sets.length + 1,
      components: newComponents
    };

    // Add new set
    template.sets.push(newSet);

    this.setState({
      modifiedTemplate: _.cloneDeep(template),
      selectedSetId: newSet.key,
      showCreateSetModal: false
    });
  };

  renderPublishModal = (client_id: number, template_id: number, template_type_id: number) => {
    return (
      <Modal
        title={ 'Publish Template' }
        closable={ false }
        maskClosable={ false }
        centered
        visible
        onCancel={ () => this.setState({ showPublishModal: false }) }
        okText={ 'Publish' }
        onOk={ async () => {

          try {
            await new Promise((resolve) => this.setState({ isPublishing: true }, () => resolve(null)));

            const newTemplate = await API.put(`client/${client_id}/admin/templates/dynamic-field/types/${template_type_id}/templates/${template_id}/publish`);

            this.mounted && this.setState({
              originalTemplate: _.cloneDeep(newTemplate),
              modifiedTemplate: _.cloneDeep(newTemplate),
              selectedSetId: _.has(newTemplate, 'sets') && !_.isEmpty(newTemplate.sets) ? newTemplate.sets[0].id : null,
            });

            Notification('success', 'Published template', 'Published');

          } catch (error) {
            Notification('error', 'Failed to publish', 'Failed');
          } finally {
            this.setState({
              showPublishModal: false,
              isPublishing: false,
            });
          }
        } }
        confirmLoading={ this.state.isPublishing }
      >
        <p>You are about to publish this version. It will become available to the wider system and will no longer be editable. Only publish if you are certain the template is ready for use.</p>
      </Modal>
    );
  };

  renderPreviewModal = (client_id: number, template_id: number, template_type_id: number) => {
    return (
      <PreviewDynamicField
        clientId={ client_id }
        templateId={ template_id }
        templateTypeId={ template_type_id }
        onReady={ () => this.setState({ isLoadingPreview: false }) }
        onCancel={() => this.setState({ showPreviewModal: false, isLoadingPreview: false }) }
      />
    );
  };

  renderCreateSetModal = (template: IDynamicFieldTemplate) => {
    return (
      <Modal
        centered
        visible
        closable={ false }
        maskClosable={ false }
        title={ 'Create Category' }
        onCancel={ () => this.setState({ showCreateSetModal: false }) }
        okText={ 'Create' }
        onOk={ async () => {
          this.createSetFormRef
            .current
            .validateFields()
            .then((values: any) => {
              if (template) {

                const newSet = {
                  id: null,
                  key: uuidv4(),
                  template_id: template.id,
                  title: values.title,
                  order: template.sets.length + 1,
                  components: []
                };

                // Add new set
                template.sets.push(newSet);

                this.setState({
                  modifiedTemplate: template,
                  selectedSetId: newSet.key,
                  showCreateSetModal: false
                });
              }
            });
          }
        }
        style={{ minWidth: 500 }}
      >
        <Form
          ref={ this.createSetFormRef }
          layout="vertical"
        >
          <Form.Item
            label="Title"
            name="title"
            rules={[{ required: true, message: 'Required' }]}
          >
            <Input
              autoComplete="off"
              autoFocus
            />
          </Form.Item>
        </Form>
      </Modal>
    );
  };

  renderTemplate = (
    selectedSetId: number | string | undefined,
    originalTemplate: IDynamicFieldTemplate,
    modifiedTemplate: IDynamicFieldTemplate,
    activeOriginalTemplateSet: IDynamicFieldTemplateSet | null | undefined | false,
    activeModifiedTemplateSet: IDynamicFieldTemplateSet | null | undefined | false,
    selectLists: SelectListItem[],
    sliderRanges: SliderRange[],
  ) => {
    const isDisabled = _.has(modifiedTemplate, 'can_edit') && !modifiedTemplate.can_edit;
    const frequencySelectOptions = originalTemplate.frequencySelectOptions;
    const frequencyOptions = _.has(frequencySelectOptions, 'frequency_options') && !!frequencySelectOptions ? frequencySelectOptions.frequency_options : [];
    const frequencyIntervals = _.has(frequencySelectOptions, 'options') && !!frequencySelectOptions ? frequencySelectOptions.options : [];
    const currentFrequency = modifiedTemplate.config.recurring_frequency.frequency;

    return (
      <Collapse
        className='DynamicFieldTemplate'
        expandIconPosition="left"
        defaultActiveKey={ [1, 2, 3] }
      >
        <Panel
          key={ 1 }
          header={
            <span
              className={ classNames({
                'text-warning': !_.isEqual(originalTemplate, modifiedTemplate)
              }) }
            >
              Info
            </span>
          }
          extra={
            <span onClick={ (event: React.MouseEvent) => event.stopPropagation() }>
              { !_.isEqual(originalTemplate, modifiedTemplate) ? (
                <Tooltip
                  placement="top"
                  title={ 'Save to Preview' }
                >
                  <Button disabled>
                    <EyeOutlined />
                    Preview
                  </Button>
                </Tooltip>
              ) : (
                <Button
                  loading={ this.state.isLoadingPreview }
                  onClick={ () => this.setState({ showPreviewModal: true, isLoadingPreview: true }) }
                >
                  <EyeOutlined />
                  Preview
                </Button>
              ) }
            </span>
          }
        >
          <Form
            layout="inline"
            className='pT-10 pB-10'
            preserve={ false }
            initialValues={{
              'title': modifiedTemplate.title,
              'scoring_type': modifiedTemplate.scoring_mode,
              'slider_range_id': modifiedTemplate.slider_range_id,
              'select_list_id': modifiedTemplate.select_list_id,
            }}
          >
            <Form.Item
              label="Title"
              name="title"
              className="mB-10"
              rules={[{ required: true, message: 'Required' }]}
            >
              <Input
                autoComplete="off"
                className={ classNames('Field', {
                  'Field--has-warning border-warning': !_.isEqual(originalTemplate?.title, modifiedTemplate?.title)
                }) }
                disabled={ isDisabled }
                style={{ maxWidth: 300 }}
                onBlur={ (event: React.ChangeEvent<HTMLInputElement>) => {
                  if (!!event.target.value) {
                    this.setState({
                      modifiedTemplate: _.set(_.cloneDeep(modifiedTemplate), 'title', event.target.value)
                    });
                  }
                } }
              />
            </Form.Item>
            <Form.Item
              label="Assessment Type"
              name="scoring_type"
              className="mB-10"
            >
              { this.shouldLockAssessmentType(modifiedTemplate) ? (
                <Tooltip
                  title={ 'Assessment type cannot be changed once individual question assessment has been set.' }
                >
                  <Select
                    style={{ minWidth: 180 }}
                    value={ modifiedTemplate.scoring_mode }
                    placeholder={ 'No Assessment' }
                    disabled
                  >
                    <Select.Option key={ 'select' } value={ 'SELECT' }>
                      { 'Dropdown' }
                    </Select.Option>
                    <Select.Option key={ 'slider' } value={ 'SLIDER' }>
                      { 'Slider' }
                    </Select.Option>
                  </Select>
                </Tooltip>
              ) : (
                <Select
                  allowClear
                  style={{ minWidth: 180 }}
                  placeholder={ 'No Assessment' }
                  disabled={ this.shouldLockAssessmentType(modifiedTemplate) || isDisabled }
                  onChange={ (type: string) => {
                    const _modifiedTemplate = _.cloneDeep(modifiedTemplate);

                    _modifiedTemplate.scoring_mode = type ? type : null;
                    _modifiedTemplate.slider_range_id = null;
                    _modifiedTemplate.select_list_id = null;

                    this.setState({
                      modifiedTemplate: _modifiedTemplate
                    });
                  } }
                >
                  <Select.Option key={ 'select' } value={ 'SELECT' }>
                    { 'Dropdown' }
                  </Select.Option>
                  <Select.Option key={ 'slider' } value={ 'SLIDER' }>
                    { 'Slider' }
                  </Select.Option>
                </Select>
              ) }
            </Form.Item>
            { modifiedTemplate.scoring_mode === 'SLIDER' &&
              <Form.Item
                label="Slider List"
                name="slider_range_id"
                className="mB-10"
              >
                <Select
                  style={ { minWidth: 180 } }
                  placeholder={ 'Please select' }
                  dropdownMatchSelectWidth={ false }
                  disabled={ isDisabled }
                  value={ modifiedTemplate?.slider_range_id || undefined }
                  onChange={ (type: string) => {
                    this.setState({
                      modifiedTemplate: _.set(_.cloneDeep(modifiedTemplate), 'slider_range_id', type)
                    });
                  } }
                >
                { sliderRanges?.map((sliderRanges: SliderRange) => (
                  <Select.Option key={ sliderRanges.id } value={ sliderRanges.id }>
                    { sliderRanges.title }
                  </Select.Option>
                )) }
                </Select>
              </Form.Item>
            }
            { modifiedTemplate.scoring_mode === 'SELECT' &&
              <Form.Item
                label="Select List"
                name="select_list_id"
                className="mB-10"
              >
                <Select
                  style={ { minWidth: 180 } }
                  dropdownMatchSelectWidth={ false }
                  placeholder={ 'Please select' }
                  disabled={ isDisabled }
                  value={ modifiedTemplate?.select_list_id || undefined }
                  onChange={ (type: string) => {
                    this.setState({
                      modifiedTemplate: _.set(_.cloneDeep(modifiedTemplate), 'select_list_id', type)
                    });
                  } }
                >
                { selectLists?.map((selectList: SelectListItem) => (
                  <Select.Option key={ selectList.id } value={ selectList.id }>
                    { selectList.title }
                  </Select.Option>
                )) }
                </Select>
              </Form.Item>
            }
          </Form>
        </Panel>
        <Panel
          key={ 2 }
          header={
            <span
              className={ classNames({
                'text-warning': !_.isEqual(originalTemplate, modifiedTemplate)
              }) }
            >
              Config
            </span>
          }
        >
          <Form
            layout="inline"
            className='pT-10 pB-10'
            preserve={ false }
          >
            <div className="w-100p d-if" >
              <Form.Item
                label="Recurring Frequency"
                name="scoring_type"
                className="mB-10"
              >
                <Select
                  style={{ minWidth: 180 }}
                  value={ modifiedTemplate.config.recurring_frequency.frequency }
                  placeholder={ 'Never' }
                  disabled={ isDisabled }
                  onChange={ (option: string) => {
                    const _modifiedTemplate = _.cloneDeep(modifiedTemplate);

                    _modifiedTemplate.config.recurring_frequency = {
                      ..._modifiedTemplate.config.recurring_frequency,
                      frequency: option
                    };

                    this.setState({
                      modifiedTemplate: _modifiedTemplate
                    });
                  } }
                >
                { frequencyOptions.map((option: any) => {
                  return (
                    <Select.Option
                      key={ `list-frequency-option-${option.id}` }
                      value={ option.title }
                    >
                      { option.title }
                    </Select.Option>
                  );
                }) }
                </Select>
              </Form.Item>
              <Form.Item>
                <Select
                  style={{ minWidth: 180 }}
                  value={ modifiedTemplate.config.recurring_frequency.value }
                  placeholder={ 'Interval' }
                  disabled={ isDisabled }
                  onChange={ (option: string) => {
                    const _modifiedTemplate = _.cloneDeep(modifiedTemplate);

                    _modifiedTemplate.config.recurring_frequency = {
                      ..._modifiedTemplate.config.recurring_frequency,
                      value: option
                    };

                    this.setState({
                      modifiedTemplate: _modifiedTemplate
                    });
                  } }
                >
                { frequencyIntervals
                  .filter((option: any) => !!option.frequencyType && option.frequencyType.includes(currentFrequency))
                  .map((option: any) => {
                    return (
                      <Select.Option
                        key={ `list-frequency-option-${option.title}` }
                        value={ option.title }
                      >
                        { option.title }
                      </Select.Option>
                    );
                  })
                }
                </Select>
              </Form.Item>
            </div>
          </Form>
        </Panel>
        <Panel
          key={ 3 }
          header={ (
            <span
              className={ classNames({
                'text-warning': !_.isEqual(originalTemplate, modifiedTemplate)
              }) }
            >
              Categories
            </span>
          ) }
          extra={ !isDisabled &&
            <span onClick={ (event: React.MouseEvent) => event.stopPropagation() }>
              <Button
                size='small'
                style={{ padding: '0px 5px' }}
                onClick={ () => this.setState({ showCreateSetModal: true }) }
              >
                <PlusOutlined />
              </Button>
            </span>
          }
        >
          <div style={{ minHeight: 800 }}>
            <div className="d-f fxd-r">
              <div className="d-if mR-20 bd" style={{ width: '30%', maxWidth: 300 }}>
                <DragSortingList
                  sort={ !isDisabled }
                  className='w-100p'
                  columns={ [
                    {
                      title: '',
                      dataIndex: 'sort',
                      render: (__: any, set: IDynamicFieldTemplateSet) => {
                        return (
                          <div
                            className="d-f jc-sb"
                            onClick={ () => this.setState({ selectedSetId: set.id || set.key }) }
                          >
                            <span className="d-f jc-c ai-c">
                              <span>
                                <MenuOutlined className="EntityRolesTab-Table-DragMenu" />
                              </span>
                              <span className='mL-20'>
                                { set.title }
                              </span>
                            </span>
                            { !isDisabled &&
                              <span className='d-f ai-c ta-r' onClick={(event: React.MouseEvent) => event.stopPropagation() }>
                                <Button
                                  className="mR-5"
                                  title='Duplicate'
                                  onClick={ () => modifiedTemplate && this.duplicateSet(modifiedTemplate, set)}
                                  style={{
                                    padding: '4px 7px',
                                    width: '32px',
                                  }}
                                >
                                  <CopyOutlined />
                                </Button>
                                <Popconfirm
                                  title={ 'Are you sure?' }
                                  icon={ <QuestionCircleOutlined style={{ color: 'red' }} /> }
                                  visible={ this.state.showDeleteSetPopover && (this.state.deleteSetKey === set?.key || this.state.deleteSetKey === set?.id) }
                                  okButtonProps={{
                                    danger: true
                                  }}
                                  onConfirm={ () => {
                                    this.onDeleteSet(modifiedTemplate, set.id || set.key);
                                  } }
                                  onCancel={ () => this.setState({
                                    deleteSetKey: null,
                                    showDeleteSetPopover: false
                                  }) }
                                >
                                  <Button
                                    onClick={ () => {
                                      this.setState({
                                        deleteSetKey: set.id || set?.key,
                                        showDeleteSetPopover: true
                                      });
                                    }}
                                    style={{
                                      padding: '4px 7px',
                                      width: '32px',
                                    }}
                                  >
                                    <DeleteOutlined />
                                  </Button>
                                </Popconfirm>
                              </span>
                            }
                          </div>
                        );
                      },
                    },
                  ] }
                  items={ _.has(modifiedTemplate, 'sets') ? modifiedTemplate.sets
                    .sort((aSet: IDynamicFieldTemplateSet, bSet: IDynamicFieldTemplateSet) => aSet.order - bSet.order)
                    .map((set: IDynamicFieldTemplateSet) => {
                      return {
                        'key': set.key || set.id,
                        ...set
                      };
                    }) : []
                  }
                  isParent
                  showHeader={ false }
                  pagination={ false }
                  emptyText={ <>Categories</> }
                  config={{
                    type: TableType.Tab,
                    references: [],
                  }}
                  selectedRow={ selectedSetId }
                  moveRow={ (dragIndex: number, hoverIndex: number) => this.onReorderSet(modifiedTemplate, dragIndex, hoverIndex) }
                />
              </div>
              <div className="d-if fx-1 bdB">
                <Collapse
                  style={{ minHeight: 500 }}
                  className="w-100p bg-white"
                  expandIconPosition="left"
                  defaultActiveKey={ [1, 2] }
                >
                  <Panel
                    { ...!selectedSetId ? {
                      collapsible: 'disabled'
                    } : {} }
                    header={ 'Info' }
                    key={ 1 }
                  >
                    <Form
                      ref={ this.formRef }
                      layout="inline"
                      className='pT-10 pB-10'
                      initialValues={{
                        'category-title': activeModifiedTemplateSet ? activeModifiedTemplateSet.title : ''
                      }}
                    >
                      <Form.Item
                        style={{ marginBottom: 0 }}
                        label="Category Title"
                        name="category-title"
                        rules={[{ required: true, message: 'Required' }]}
                      >
                        <Input
                          autoComplete="off"
                          className={ classNames('Field', {
                            'Field--has-warning border-warning': activeOriginalTemplateSet && activeModifiedTemplateSet && !_.isEqual(activeOriginalTemplateSet.title, activeModifiedTemplateSet.title)
                          }) }
                          style={{ maxWidth: 300 }}
                          disabled={ !activeModifiedTemplateSet || isDisabled }
                          onBlur={ (event) => {
                            if (!!selectedSetId && !!event.target.value) {
                              this.setState({
                                modifiedTemplate: _.set(_.cloneDeep(modifiedTemplate), 'sets', this.modifySet(modifiedTemplate.sets, selectedSetId, 'title', event.target.value))
                              });
                            }
                          } }
                        />
                      </Form.Item>
                    </Form>
                  </Panel>
                  <Panel
                    collapsible={ 'disabled' }
                    style={{ padding: 0 }}
                    key={ 2 }
                    header={ (
                      <div className='d-f jc-sb w-100p'>
                        <span>Questions ({`${activeModifiedTemplateSet ? activeModifiedTemplateSet.components.length : 0 }`})</span>
                        <span>
                          {
                            !isDisabled && <Button
                              size='small'
                              disabled={ !selectedSetId }
                              onClick={ (event: React.MouseEvent) => {
                                event.stopPropagation();
                                this.setState({
                                  showCreateComponentModal: true,
                                  componentPlaceholder: Object.assign({}, this.state.componentPlaceholder, { config: Object.assign({}, this.state.componentPlaceholder?.config, {
                                    scoring: {
                                      list_id: modifiedTemplate?.scoring_mode === 'SLIDER' ? modifiedTemplate?.slider_range_id : modifiedTemplate?.select_list_id
                                    }
                                  }) })
                                });
                              }}
                              style={{
                                padding: '0px 5px'
                              }}
                            >
                              <PlusOutlined />
                            </Button>
                          }
                        </span>
                      </div>
                    ) }
                  >
                    <DynamicFieldComponentList
                      clientId={ this.props.client_id }
                      selectedSetId={ selectedSetId }
                      template={ modifiedTemplate }
                      setState={ (state: State, callback?: () => void) => this.setState(state, callback && callback) }
                      state={ this.state }
                    />
                  </Panel>
                </Collapse>
              </div>
            </div>
          </div>
        </Panel>
      </Collapse>
    );
  };

  renderVersions = (template: IDynamicFieldTemplate, versions: any[]) => {
    const items: TimelineItem[] = versions.map((item: any) => {
      const isCurrent = item.id === template.id;
      return {
        colorClass: isCurrent ? 'Timeline--primary' : 'Timeline--default',
        node: (
          <>
            <div className="bdB pB-10">
              <span className="fw-500">{ getFormatedDate(item.created_at, undefined, true) }</span>
              <span className="mL-10"><Badge type={ item.version_status === 'PUBLISHED' ? BadgeType.Success : BadgeType.Default } text={ `Version ${item.version || 1}` } /></span>
            </div>
            <div className="mT-10">
              <span className="p-2"><Link to={ `/admin/templates/dynamic-field/type/${item.template_type_id}/template/${item.id}` }>{item.title}</Link></span>
            </div>
          </>
        ),
      };
    });

    return (
      <Timeline timeline={ items } />
    );
  };

  render = () => {
    const { client_id, template_type_id } = this.props;
    const {
      isLoading,
      originalTemplate,
      modifiedTemplate,
      selectedSetId,
      showCreateSetModal,
      showPreviewModal,
      showPublishModal,
      selectLists,
      sliderRanges,
      isSaving,
      isPublishing,
      isVersioning,
    } = this.state;

    if (isLoading) {
      return (
        <div className="d-f jc-c ai-c mH-450" style={{ height: 'calc(100vh - 81px)' }}>
          <BlockingSpinner isLoading />
        </div>
      );
    };

    if (!originalTemplate || !modifiedTemplate) return <></>;

    const activeOriginalTemplateSet = originalTemplate && _.has(originalTemplate, 'sets') && originalTemplate.sets.find((set: IDynamicFieldTemplateSet) => set.id === selectedSetId || set.key == selectedSetId);
    const activeModifiedTemplateSet: IDynamicFieldTemplateSet | undefined | false = _.has(modifiedTemplate, 'sets') && modifiedTemplate.sets.find((set: IDynamicFieldTemplateSet) => set.id == selectedSetId || set.key == selectedSetId);
    const isModified = !_.isEqual(originalTemplate, modifiedTemplate);

    const actions: DropdownAction[] = [
      {
        node: 'Save',
        onClick: () => this.handleSave(modifiedTemplate),
        disabled: !isModified || isSaving || isVersioning || isPublishing,
      },
    ];

    if (originalTemplate.version_status === 'PUBLISHED') {
      actions.push({
        node: 'New Version',
        disabled: isModified ? ['Unsaved changes'] :  false,
        onClick: () => this.handleCreateVersion(originalTemplate),
        isLoading: isSaving || isVersioning || isPublishing
      });
    }

    if (originalTemplate.version_status !== 'PUBLISHED') {
      actions.push({
        node: 'Publish',
        disabled: isModified ? ['Unsaved changes'] : false,
        onClick: () => this.setState({ showPublishModal: true }),
        isLoading: isSaving || isVersioning || isPublishing
      });
    }

    return (
      <>
        <Jumbotron
          title={ (
            <div>
              <div>
                <span className="fw-500">{ originalTemplate.title }</span>
              </div>
              <div className='mT-5'>
                <span><Badge type={ originalTemplate.version_status === 'PUBLISHED' ? BadgeType.Success : BadgeType.Default } text={ _.startCase(_.toLower(originalTemplate.version_status)) } /></span>
                <span className="mL-10"><Badge type={ BadgeType.Disabled } text={ `Version ${originalTemplate.version}` } /></span>
              </div>
            </div>
          ) }
          rightActions={ [
            {
              node: (
                <div className="d-if">
                  { !_.isEqual(this.state.originalTemplate, this.state.modifiedTemplate) && (
                    <Tooltip
                      placement="top"
                      title={ 'Unsaved changes' }
                    >
                      <Button
                        type={ 'primary' }
                        className="ExternalControls__button ExternalControls__button--info ExternalControls__button--warning ExternalControls__button--with-spacing"
                      >
                        <Icon component={ InfoIcon } />
                      </Button>
                    </Tooltip>
                  ) }
                  <Dropdown actions={ actions } />
                </div>
              )
            }
          ] }
          tabs={[
            {
              label: 'Overview',
              node: this.renderTemplate(
                selectedSetId,
                originalTemplate,
                modifiedTemplate,
                activeOriginalTemplateSet,
                activeModifiedTemplateSet,
                selectLists || [],
                sliderRanges || []
              ),
            },
            {
              label: 'Versions',
              node: this.renderVersions(originalTemplate, originalTemplate.version_history || [])
            },
          ]}
        />
        { showCreateSetModal && this.renderCreateSetModal(modifiedTemplate) }
        { showPreviewModal && this.renderPreviewModal(client_id, modifiedTemplate.id, template_type_id) }
        { showPublishModal && this.renderPublishModal(client_id, modifiedTemplate.id, template_type_id) }
      </>
    );
  };
}

// Make data available on props
const mapStateToProps = (store: AppState) => {
  return {
    client_id: store.ClientState.client_id,
    permissions: store.UserState.user.permissions,
  };
};

// Make functions available on props
const mapDispatchToProps = (dispatch: any) => {
  return {
    setBreadcrumbsLoading: (value: boolean) => dispatch(setBreadcrumbsLoading(value)),
    setBreadcrumbs: (value: Breadcrumb[], concat: boolean) => dispatch(setBreadcrumbs(value, concat)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RestrictionHoC(DynamicFieldTemplate, 'access_admin_content_manager'));
