// Libs
import * as React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

// Containers
import AuditActionContainer from 'containers/ehs/action/AuditActionContainer';

// Views
import Actions from 'views/ehs/Actions';
import NotFound from 'views/NotFound';

class ActionContainer extends React.Component {
  render = () => {
    return (
      <Switch>

        <Route exact path="/ehs/actions" component={ Actions } />

        <Redirect exact from="/ehs/actions/audit-action" to="/ehs/actions" />

        <Route path="/ehs/actions/audit-action/:record_id" component={ AuditActionContainer } />

        <Route render={ () => <NotFound /> } />

      </Switch>
    );
  };
};

export default ActionContainer;
